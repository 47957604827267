import {useState} from 'react';

import {Swiper, SwiperSlide} from 'swiper/react';
import { FreeMode } from 'swiper';

import 'views/mobile/main/style/m-main.sass'
import 'swiper/css';
import 'swiper/css/free-mode';

import MMainEssentialLecture from 'views/mobile/main/components/MMainEssentialLecture';
import MMainNewestLecture from 'views/mobile/main/components/MMainNewestLecture';
import MMainWatchLecture from 'views/mobile/main/components/MMainWatchLecture';
import MMainPublicPlaylist from 'views/mobile/main/components/MMainPublicPlaylist';
import MMainMyPlaylist from 'views/mobile/main/components/MMainMyPlaylist';
import MMainSubscribePlaylist from 'views/mobile/main/components/MMainSubscribePlaylist';
import MMainTagList from 'views/mobile/main/components/MMainTagList';
import MMainLikeLecture from 'views/mobile/main/components/MMainLikeLecture';
import MMainNotice from '../components/MMainNotice';
import MPlayListDetailPopup from 'views/mobile/common/popup/MPlayListDetailPopup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import MPlayListPopup from 'views/mobile/common/popup/MPlayListPopup';
import { showPopupF } from 'module/lecture-add-popup';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { useEffect } from 'react';

const MMainPage = () => {
  const showPopup:boolean = useAppSelector((state: RootState) => state.lectureAddPopup.showPopup);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
  },[])

  const [view, setView] = useState<string>('home');

  const showView = () => {
   switch(view) {
     case 'home' : return (
       <>
         <MMainEssentialLecture />
         <MMainNewestLecture />
         <MMainWatchLecture />
         <MMainPublicPlaylist />
       </>
     )
     case 'my' : return <MMainMyPlaylist />
     case 'subscribe' : return <MMainSubscribePlaylist />
     case 'tag' : return <MMainTagList />
     case 'wish' : return <MMainLikeLecture />
   }
  };

  return (
    <div className="m-main">
      <section className="main-navigation-wrap">
        <Swiper slidesPerView={"auto"}
                freeMode={true}
                modules={[FreeMode]}>
          <SwiperSlide>
            <button className={view === 'home' ? 'active': ''} onClick={() => setView('home')}>홈</button>
          </SwiperSlide>
          <SwiperSlide>
            <button className={view === 'my' ? 'active': ''} onClick={() => setView('my')}>내 플레이리스트</button>
          </SwiperSlide>
          <SwiperSlide>
            <button className={view === 'subscribe' ? 'active': ''} onClick={() => setView('subscribe')}>구독 플레이리스트</button>
          </SwiperSlide>
          <SwiperSlide>
            <button className={view === 'tag' ? 'active': ''} onClick={() => setView('tag')}>관심#태그 리스트</button>
          </SwiperSlide>
          <SwiperSlide>
            <button className={view === 'wish' ? 'active': ''} onClick={() => setView('wish')}>내가 찜 한 강의</button>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className="main-content">
        {showView()}
      </section>
      <article className='simple-notice'>
        <MMainNotice />
      </article>
      {showPopup && <MPlayListPopup type={'add'}/>}
      {showDetailPopup && <MPlayListDetailPopup/>}
    </div>
  )
}

export default MMainPage;
