import React from 'react';
import {Outlet} from 'react-router-dom';
import Header from 'views/desktop/common/layout/Header';
import Footer from 'views/desktop/common/layout/Footer';

const AppLayout = () => (
  <>
    <Header/>
    <main id="app_main">
      <Outlet/>
    </main>
    <Footer/>
  </>
)

export default AppLayout
