import 'views/mobile/user/style/m-edit.scoped.sass'
import {useForm} from "react-hook-form";
import {useNavigate} from 'react-router-dom';
import {confirmUser} from 'api/user/edit';
import {User} from 'utils/type';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {useEffect, useState} from 'react';
import {setTitle} from "module/mobile/title";
import {setCheck} from "module/auth";

type FormData = {
  password: string,
};

const MEditConfirmPage = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors}
  } = useForm<FormData>();

  const navigate = useNavigate();
  const userData:User = useAppSelector((state: RootState) => state.authentication.user);

  const [notMatch, setNotMatch] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('회원정보'));
  });

  const postConfirm = () => {
    let data = {
      password: watch().password
    }
    confirmUser(data).then((res: any) => {
      dispatch(setCheck(true));
      switch(userData.role){
        case 'ROLE_PROFESSOR' : return navigate('/user/edit/professor');
        case 'ROLE_STUDENT' : return navigate('/user/edit/student');
        case 'ROLE_INSIDER' : return navigate('/user/edit/insider');
        default : return navigate('/user/edit/outsider');
      }
    }).catch((err:any) =>{
      setNotMatch(true);
      dispatch(setCheck(false));
    })
  }

  return (
    <div className="m-edit-user">
      <section className="content-wrap">
        <form onSubmit={handleSubmit(postConfirm)}>
          <h2>비밀번호 재입력</h2>
          <p>본인확인을 위해<br /> 로그인 비밀번호를 한 번 더 입력해 주세요.</p>
          <input type="password" placeholder="비밀번호를 입력해주세요."
                 aria-invalid={errors.password ? "true" : "false"}
                 {...register("password", {required: "비밀번호를 입력해주세요."})}/>
          {(Object.keys(errors).length > 0 || notMatch) &&
            <section className="error-wrap">
              {errors.password && <p className="m-error-message" role="alert">{errors.password.message}</p>}
              {(!errors.password && notMatch) && <p className="m-error-message" role="alert">비밀번호가 맞지 않습니다. 확인 후 입력해 주세요.</p>}
            </section>
          }
          <button type="submit" className="btn-confirm">확인</button>
        </form>
      </section>
    </div>
  )
}

export default MEditConfirmPage;
