// @ts-ignore
import Send from 'utils/send.ts'

export const noticeCreate = (data: any) => {
  return Send({
    method: 'post',
    url: '/api/admin/notices',
    data: data,
    headers: {
      enctype: 'multipart/form-data',
      processData: false,
      'Content-Type': 'multipart/form-data',
    }
  })
}

export const noticeList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/notices',
    params: params
  })
}

export const noticeDetail = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/admin/notices/${id}`,
  })
}

export const noticeEdit = (id: number,data: any) => {
  return Send({
    method: 'put',
    url: `/api/admin/notices/${id}`,
    data: data,
  })
}

export const noticeDelete = (id:number) => {
  return Send({
    method: 'delete',
    url: `/api/admin/notices/${id}`,
  })
}

export const noticeIsPublic = (id:number) => {
  return Send({
    method: 'patch',
    url: `/api/admin/notices/${id}`,
  })
}
