import LectureManagementCard from 'views/desktop/lecture-management/components/LectureManagementCard';
import {useAppSelector} from 'module/Module';
import {LectureManagementProps} from 'utils/type';

const LectureManagementList = () => {
  const managementList:Array<LectureManagementProps> = useAppSelector(state => state.lectureManagement.list)

  return (
    <ul className="lecture-management-list">
      {managementList && managementList.map((data) => (
        <li key={data?.id}>
          <LectureManagementCard data={data}/>
        </li>
      ))}
    </ul>
  );
};

export default LectureManagementList;
