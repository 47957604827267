import { useEffect } from 'react';
import {delSubscribe, getSubscribePlayListData} from 'api/playlist/playlist'
import 'views/desktop/main/style/main-myplaylist.scoped.sass'
import {CommonPlaylistProps} from 'utils/type';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { ChangeSub, getSubPlaylist } from 'module/sub-playlist';
import {setWatchMode} from 'module/watch-mode';
import { Link } from 'react-router-dom';

const MainSubscribePlaylist = () => {
  const dispatch = useAppDispatch();

  const subPlaylist:Array<CommonPlaylistProps> = useAppSelector((state: RootState) => state.subPlaylist.subPlaylist);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);

  useEffect(() => {
      let params = {
        page: 1,
        size: 5
      }
      getSubscribePlayListData(params).then((res: any) => {
        dispatch(getSubPlaylist(res.data.content));
      })
  }, [subChange]);

  const delSubscribeF = (Id:number) =>{
    if(window.confirm('해당 플레이리스트를 구독 취소하시겠습니까?')) {
      delSubscribe(Id).then((res: any) => {
        dispatch(ChangeSub(!subChange));
      })
    }
  }

  const noneComponent = () => {
    let playListNone = [];

    for(let i =  0 ; i < 5 - subPlaylist.length; i++) {
      playListNone.push(
        <li className={`list-none ${subPlaylist.length === 0 ? 'list-empty' : ''}`} key={`none_${i}`}>
          <p>구독한 플레이리스트가 없습니다.</p>
          <Link to='/playlist/public'>전체 공유 플레이리스트로 이동하기&gt;</Link>
        </li>
      )
    }

    return playListNone;
  }

  return (
    <div className="sub-content-wrap">
        <ul className="playlist-card-list">
          {subPlaylist.map((data,index) => index<5 && (
            <li className="playlist-card" key={index}>
              <div className="card-title-wrap">
                <p className="playlist-name">
                  {data?.courses[0] ? 
                    (
                      <Link
                        to={`/lecture/watch/${data?.courses[0].id}`}
                        onClick={() => dispatch(setWatchMode({playlistMode: true, playlistId: data?.id}))}>{data.title}&ensp;
                      </Link>
                    ) : (
                      <span>{data.title}&ensp;</span>
                    )
                  }
                  <span className='playlist-desc'>'{data.name}'님의 플레이리스트입니다.</span>
                </p>
                <button className="btn-del" onClick={()=> delSubscribeF(data.id)}>구독취소</button>
              </div>
              <div className="card-content-wrap">
              <ul className="lecture-list">
                {data.courses.length > 0 ?
                  data.courses.map((element, index) => (
                  <li key={index}>
                    <Link to={`/lecture/watch/${element.id}`}>{index+1}. {element.title} / {element.name}</Link>
                  </li>)): (<li>등록한 강의가 없습니다.</li>)}
              </ul>
            </div>
            </li>
            ))
          }
          {noneComponent()}
        </ul>
      </div>
  );
};

export default MainSubscribePlaylist;
