import 'views/desktop/main/style/main-like-lecture.scoped.sass'
import {getWishList} from 'api/main/lecture';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { delWish} from "api/lecture/lecture";

type wishListType = {
  id: number,
  major: string,
  name: string,
  title: string
}

const MainLikeLecture = () => {
  const [wishList, setWishList] = useState<Array<wishListType>>([])

  const getList = () => {
    getWishList().then((res: any) => {
      setWishList(res.data);
    })
  }

  useEffect(() => {
      getList();
  }, [])

  // 좋아요
  const DelWish = (e:any,courseId: number) => {
    e.preventDefault();
    delWish(courseId).then((res: any) => {
      getList();
    });
  }

  return (
    <article className="like-lecture">
      <div className="title-wrap">
        <h2>내가 찜한 강의</h2>
      </div>
      <div className="content-wrap">
        {wishList.length > 0 &&
        <ul className="like-lecture-card-list">
          {wishList.map(list => (
              <li key={list.id}>
                <span className="lecture-like-icon ir_pm" onClick={(e) => DelWish(e,list.id)}>좋아요</span>
                <Link to={`/lecture/watch/${list.id}`}>
                  <p className="lecture-text">
                    <span className="lecture-category">{list.major}</span>&nbsp;/&nbsp;
                    <b className="lecture-name">{list.title}</b>&nbsp;/&nbsp;
                    <span className="lecture-writer">{list.name}</span>
                  </p>
                </Link>
              </li>
            ))}
        </ul>
        }
        {wishList.length === 0 && <p className="none-list">아직 찜한 강의가 없습니다.</p>}
      </div>
    </article>
  );
};

export default MainLikeLecture;
