import {Link, useNavigate} from 'react-router-dom';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import 'views/desktop/user/style/login.sass';

import {login} from 'api/user/login';
import {onLoginSuccess} from 'utils/auth'
import {useEffect, useRef, useState} from 'react';
import {useAppDispatch} from 'module/Module';
import {getUserData} from 'api/user/user';
import {setAuth} from 'module/auth';
import {loginEmailOptions, selectStyles} from 'utils/select-option';
import Select from 'react-select';
import {getCookie, removeCookie, setCookie} from "utils/cookie";

type FormData = {
  email: string,
  emailId: string
  emailAddress: string,
  password: string,
};

const LoginPage = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    formState: {isSubmitting, errors}
  } = useForm<FormData>();
  const [loginError, setLoginError] = useState<string>("");
  const dispatch = useAppDispatch();

  const [isRemember, setIsRemember] = useState<boolean>(false);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const addresRef = useRef<any>(null);

  function handleClickOutside(e: MouseEvent): void {
    if (addresRef.current && !addresRef.current.contains(e.target as Node)) {
      setMenuIsOpen(false)
    }
  }

  const EmailAddressChange = () => {
    setValue('email', `${watch().emailId}@${watch().emailAddress}`);
  }

  useEffect(() => {
    if (getCookie("userid")) {
      setValue('emailId', getCookie("userid").split('@')[0]);
      setValue('emailAddress', getCookie("userid").split('@')[1]);
      EmailAddressChange();
      setIsRemember(true);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSaveChange = (e: any) => {
    setIsRemember(e.target.checked);
  };

  const postLogin: SubmitHandler<FormData> = () => {
    let data = {
      email: watch().email,
      password: watch().password
    }
    login(data).then((res: any) => {
      onLoginSuccess(res);
      setCookie('login_token', res.data.yonsei_access_token, {path: '/'})
      if (isRemember) {
        setCookie('userid', watch().email);
      } else {
        removeCookie('userid');
      }
      getUserData().then((res: any) => {
        dispatch(setAuth(res.data));
      })
      return navigate('/main');
    }).catch((err: any) => {
      setLoginError(err.response.data.message);
    });
  };

  return (
    <div className="login">
      <form action="" onSubmit={handleSubmit(postLogin)}>
        <div className="login-bg"></div>
        <div className="login-container">
          <div className="login-wrap">
            <h1>로그인</h1>
            <section className="form-wrap">
              <div className="input-wrap email-wrap">
                <input type="text"
                      className="input-email"
                      placeholder="이메일 주소"
                      autoComplete="username"
                      aria-invalid={errors.emailId ? "true" : "false"}
                      {...register("emailId", {
                        required: "이메일을 입력해 주세요.",
                        onChange: () => EmailAddressChange()
                      })}/>
                <span>@</span>
                <div className="address-box" ref={addresRef}>
                  <input type="text"
                        className="input-email-address"
                        placeholder="이메일 주소"
                        autoComplete="on"
                        {...register('emailAddress', {
                            required: '이메일 주소를 선택해 주세요.',
                            onChange: (e) => {
                              e.target.value.replace(/[^A-Za-z.]/ig, '');
                              EmailAddressChange();
                            }
                          })}
                        onClick={() => setMenuIsOpen(!menuIsOpen)}
                        readOnly={readOnly}/>
                  <Select options={loginEmailOptions}
                                  styles={selectStyles}
                                  placeholder="선택해 주세요."
                                  inputId="emailAddress"
                                  isClearable={true}
                                  className="input-email-select"
                                  classNamePrefix="input-select"
                                    isSearchable={false}
                                  onChange={(e: any) => {
                                    setValue('emailAddress', e.value);
                                    setMenuIsOpen(!menuIsOpen);
                                    EmailAddressChange();
                                    if(e.label === '직접입력') {
                                      setReadOnly(false);
                                    } else {
                                      setReadOnly(true);
                                    }
                                    setFocus('emailAddress');
                                  }}
                                  menuIsOpen={menuIsOpen}
                                  />
                </div>
              </div>
              <div className="input-wrap">
                <input type="password"
                      className="input-password"
                      autoComplete="current-password"
                      aria-invalid={errors.password ? "true" : "false"}
                      {...register("password", {required: "비밀번호를 입력해주세요."})}
                      placeholder="비밀번호 (숫자, 소/대문자, 특수문자 조합 최소 8자)"/>
              </div>
            </section>
            <section className="login-helper-wrap">
              <div className="checkbox-wrap">
                <input type="checkbox" id="save" onChange={handleSaveChange}
                      checked={isRemember}/>
                <label htmlFor="save">이메일 저장</label>
              </div>
              <ul className="login-helper">
                <li>
                  <Link to="/register">회원가입</Link>
                </li>
                <li>
                  <Link to="/find/email">아이디 찾기</Link>
                </li>
                <li>
                  <Link to="/find/password">비밀번호 재설정</Link>
                </li>
              </ul>
            </section>
            {(Object.keys(errors).length > 0 || loginError !== '') &&
              <section className="error-wrap">
                {errors.emailId && <p className="error-message"
                                      role="alert">{errors.emailId.message}</p>}
                {errors.emailAddress && <p className="error-message"
                                          role="alert">{errors.emailAddress.message}</p>}
                {errors.password && <p className="error-message"
                                      role="alert">{errors.password.message}</p>}
                {loginError &&
                  <p className="error-message" role="alert">{loginError}</p>}
              </section>
            }
            <section
              className={`button-wrap${(Object.keys(errors).length > 0 || loginError !== '') ? ' errors-on' : ''}`}>
              <button type="submit" className="btn-login"
                      disabled={isSubmitting}>로그인
              </button>
            </section>
          </div>
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
