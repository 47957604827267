// @ts-ignore
import Send from 'utils/send.ts'

export const login = (data: object) => {
  return Send({
    method: 'post',
    url: '/api/tokens/login',
    data: data,
  })
}

export const logout = () => {
  return Send({
    method: 'delete',
    url: '/api/tokens'
  })
}

export const onSilentRefresh = () => {
  return Send({
    method: 'post',
    url: '/api/tokens/refresh',
  })
}
