import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {useEffect, useState} from 'react';
import {getPublicPlaylist, isPublicPlaylist} from 'api/admin/playlist';
import ReactPaginate from "react-paginate";
import {RootState, useAppDispatch, useAppSelector} from "module/Module";
import PlayListDetailPopup
  from "views/desktop/common/popup/PlayListDetailPopup";
import {setPopupMode, showDetailPopupF} from "module/lecture-add-detail-popup";


type AdminPublicPlaylistPage = {
  id: number,
  title: string,
  name: string,
  courseCount: number,
  subscribedCount: number,
  sharedAt: Array<number>,
}

const AdminPublicPlaylistPage = () => {
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const [lists, setList] = useState<Array<AdminPublicPlaylistPage>>([]);
  const [keyword, setKeyword] = useState<string>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [detectChange, setDetectChange] = useState<boolean>(true);
  const [totalElements, setTotalElements] = useState<number>(1);
  const dispatch = useAppDispatch();

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
    }
    getPublicPlaylist(params).then((res:any) => {
      setList(res.data.content);
      setTotalPage(res.data.totalPages);
      setTotalElements(res.data.totalElements);
      setCurrentPage(page.selected);
    })
  }

  useEffect(()=> {
    getList({selected: 0});
  },[detectChange])

  const changePublic = (id:number) => {
    isPublicPlaylist(id).then((res: any) => {
      alert('플레이리스트가 공유해제 되었습니다.');
      getList({selected: currentPage});
    })
  }
  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="playlist_public">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">공유 플레이 리스트 관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-between align-items-end">
                <p>총 <em>{totalElements}</em>개의 리스트 공유중</p>
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="플레이리스트명, 작성자 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '5%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '22%'}}
                                    scope="col">플레이리스트명</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">작성자</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">강의개수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">공유한 수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '18%'}}
                                    scope="col">공유 시작일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">관리</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell style={{ cursor: 'pointer' }} onClick={() => dispatch(showDetailPopupF({showDetailPopup: true, playlistId: item.id}))}>
                      {item.title}
                    </CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.courseCount ? item.courseCount : 0} 개</CTableDataCell>
                    <CTableDataCell>{item.subscribedCount ? item.subscribedCount : 0} 명</CTableDataCell>
                    <CTableDataCell>{item.sharedAt[0]}.{item.sharedAt[1]}.{item.sharedAt[2]}</CTableDataCell>
                    <CTableDataCell>
                      <CButton color="info" className="text-white" onClick={() => changePublic(item.id)}>공유해제</CButton>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={7}>
                      등록된 플레이리스트 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            <ReactPaginate breakLabel="..."
                           containerClassName="user-paginate mt-4"
                           nextLabel=">"
                           onPageChange={getList}
                           pageCount={totalPage}
                           pageClassName="user-paginate-item"
                           previousLabel="<"/>
          </CCardBody>
        </CCard>
      </CCol>
      {showDetailPopup && <PlayListDetailPopup/>}
    </CRow>
  )
}

export default AdminPublicPlaylistPage;
