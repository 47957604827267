import React, { useState } from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {findPassword} from 'api/user/find';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from "react-select/creatable";
import {
  loginEmailOptions,
  selectStyles
} from 'utils/select-option';
import Select from "react-select";

type FormData = {
  email: string,
  emailId: string,
  emailAddress: string,
  birth: string,
  sendSubEmail: boolean,
};

const MFindPasswordComponent = () => {
  const navigate = useNavigate();
  const [findError, setFindError] = useState<string>("");
  const {register, handleSubmit, setValue, watch,setFocus, control, formState: { isSubmitting, errors }} =useForm<FormData>();
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState<boolean>(true);

  const EmailAddressChange = () => {
    setValue('email', `${watch().emailId}@${watch().emailAddress}`);
  }

  const postFindPassword: SubmitHandler<FormData> = () => {
    let data = {
      email: `${watch().emailId}@${watch().emailAddress}`,
      birthday: watch().birth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'),
      sendSubEmail: watch().sendSubEmail,
    }
    findPassword(data).then((res: any) => {
      alert('새로운 비밀번호를 보냈습니다.');
      navigate('/');
    }).catch((err: any) => {
      setFindError('요청하신 자료가 존재하지 않습니다.');
    });
  };

  return (
    <div className="find-password">
      <form onSubmit={handleSubmit(postFindPassword)}>
        <p className="tip">비밀번호를 찾기 위해<br/>이메일을 입력해 주세요.</p>
        <div className="form-wrap">
          <div className="input-wrap email-wrap">
            <input type="text"
                   className="input-email"
                   placeholder="이메일 앞주소"
                   autoComplete="username"
                   aria-invalid={errors.emailId ? "true" : "false"}
                   {...register("emailId", {
                     required: "이메일을 입력해 주세요." ,
                     onChange: () => EmailAddressChange()
                   })}/>
            <span>@</span>
            <div className="address-box">
              <input type="text"
                     className="input-email-address"
                     placeholder="이메일 주소"
                     {...register('emailAddress', {
                       required: '이메일 주소를 선택해 주세요.',
                       onChange: (e) => {
                         e.target.value.replace(/[^A-Za-z.]/ig, '');
                         EmailAddressChange();
                       }
                     })}
                     onClick={() => setMenuIsOpen(!menuIsOpen)}
                     readOnly={readOnly}/>
              <Select options={loginEmailOptions}
                      styles={selectStyles}
                      placeholder="선택해 주세요."
                      inputId="emailAddress"
                      isClearable={true}
                      className="input-email-select"
                      classNamePrefix="input-select"
                      isSearchable={false}
                      onChange={(e: any) => {
                        setValue('emailAddress', e.value);
                        setMenuIsOpen(!menuIsOpen);
                        EmailAddressChange();
                        if(e.label === '직접입력') {
                          setReadOnly(false);
                        } else {
                          setReadOnly(true);
                        }
                        setFocus('emailAddress');
                      }}
                      menuIsOpen={menuIsOpen} />
            </div>
          </div>
          <div className="input-wrap">
            <input type="text" id="birth" className="input-birth" placeholder="생년월일 8자리 숫자만 입력 예)20220527"
                    aria-invalid={errors.birth ? "true" : "false"} maxLength={8}
                    {...register("birth", { required: "생년월일을 입력해 주세요.", minLength: {value: 8, message: "8자리를 입력해주세요."},
                    onChange: e => { setValue('birth',e.target.value.replace(/[^0-9.]/g, ''))}, })}/>
            <label htmlFor="birth" className="hidden-label ir_pm">생년월일</label>
          </div>
          <div className="checkbox-wrap">
            <input type="checkbox" id="sub_email_check" {...register('sendSubEmail')}/>
            <label htmlFor="sub_email_check">대체 이메일에도 새로운 비밀번호 보내기</label>
          </div>
        </div>
        {(Object.keys(errors).length > 0 || findError !== '')&&
          <div className="error-wrap">
            {errors.emailId && <p className="m-error-message" role="alert">{errors.emailId.message}</p>}
            {errors.emailAddress && <p className="m-error-message" role="alert">{errors.emailAddress.message}</p>}
            {errors.birth && <p className="error-message" role="alert">{errors.birth.message}</p>}
            {findError && <p className="m-error-message" role="alert">{findError}</p>}
          </div>
        }
        <div className={`button-wrap${(Object.keys(errors).length > 0 || findError !== '')? ' errors-on':''}`}>
          <button>비밀번호 재설정</button>
        </div>
      </form>
    </div>
  );
};

export default MFindPasswordComponent;
