import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CTable, CTableBody, CTableDataCell,
  CTableHead, CTableHeaderCell, CTableRow
} from '@coreui/react';
import {Link} from 'react-router-dom';
import {useEffect, useState} from "react";
import {getNewUserData} from "api/admin/user";

type NewRegister = {
  id: number,
  role: string,
  email: string,
  name: string,
  studentYear: string,
  studentNumber: string,
  createdAt: Array<number>
}

const AdminDashboardRegister = () => {
  const [lists, setLists] = useState<Array<NewRegister>>()

  const getList = () => {
    let params= {
      masking: true,
      keyword: "",
      role: "",
      page: 1,
      size: 5,
    }
    getNewUserData(params).then((res: any) => {
      setLists(res.data.content);
    })
  };

  useEffect(() => {
    getList();
  },[]);

  const getUserRoleInKorea = (data: string) => {
    switch(data) {
      case 'ROLE_ADMIN' : return '관리자'
      case 'ROLE_PROFESSOR' : return('교수')
      case 'ROLE_STUDENT' : return('학생')
      case 'ROLE_INSIDER' : return('내부인')
      case 'ROLE_DISPATCH_OUTSIDER' : return('파견외부인')
      case 'ROLE_OUTSIDER' : return('외부인')
    }
  }

  return (
    <CCol lg="12 mb-5">
      <CCard id="dashboard_status">
        <CCardHeader className="d-flex justify-content-between">
          <h1 className="mb-0 pt-2 pb-2 fs-5">신규가입 접수</h1>
          {(lists && lists.length >= 5) && <Link to="/admin/user/new" className="btn">더보기</Link>}
        </CCardHeader>

        <CCardBody>
          <CTable className="text-center" responsive="sm">
            <CTableHead color="dark">
              <CTableRow>
                <CTableHeaderCell style={{width: '15%'}}
                                  scope="col">구분</CTableHeaderCell>
                <CTableHeaderCell style={{width: '35%'}}
                                  scope="col">이메일</CTableHeaderCell>
                <CTableHeaderCell style={{width: '25%'}}
                                  scope="col">이름</CTableHeaderCell>
                <CTableHeaderCell style={{width: '25%'}}
                                  scope="col">가입신청일</CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {(lists && lists.length) ? lists.map((item : any) => (
                <CTableRow key={item.id} style={{borderBottom: '1px solid #d8d8d8'}}>
                  <CTableDataCell>{getUserRoleInKorea(item.role)}</CTableDataCell>
                  <CTableDataCell>{item.email}</CTableDataCell>
                  <CTableDataCell>{item.name}</CTableDataCell>
                  <CTableDataCell>{item.createdAt[0]}.{item.createdAt[1]}.{item.createdAt[2]} {item.createdAt[3]}:{item.createdAt[4]}:{item.createdAt[5]}</CTableDataCell>
                </CTableRow>
              )) : (
                <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                  <CTableDataCell colSpan={4}>
                    등록된 신규 회원 목록이 없습니다.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </CCardBody>
      </CCard>
    </CCol>
  )
}

export default AdminDashboardRegister;
