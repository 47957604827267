import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol, CFormInput,
  CRow,
  CTable, CTableBody, CTableDataCell,
  CTableHead, CTableHeaderCell, CTableRow
} from '@coreui/react';
import {useEffect, useState} from 'react';
import {
  acceptStatus,
  downloadAdminExcel,
  getLectureStatus,
  refuseStatus
} from "api/admin/lecture";
import {useNavigate, useParams} from "react-router";
import ReactPaginate from "react-paginate";

type LectureList = {
  id: number,
  studentNumber: string,
  studentYear: number,
  name: string,
  email: string,
  progressSecond: number,
  createdAt: Array<number>,
  modifiedAt: Array<number>
}

const AdminLectureStatusPage = () => {
  const navigate = useNavigate();
  const courseId = Number(useParams().id);
  const getParams = new URLSearchParams(window.location.search);
  const title = getParams.get("title");
  const category = getParams.get("category");
  const permission = getParams.get("permission");
  const [lists, setList] = useState<Array<LectureList>>([]);
  const [isMasking, setIsMasking] = useState<boolean>(true);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(1);
  const [detectChange, setDetectChange] = useState<boolean>(true);

  const getList = (page: any) => {
    let params = {
      masking: isMasking,
      start: start,
      end: end,
      page:  page.selected + 1,
      size: 10
    }
    getLectureStatus(courseId, params).then((res: any) => {
      setList(res.data.content);
      setTotalPage(res.data.totalPages);
      setTotalElements(res.data.totalElements);
    })
  }

  useEffect(() => {
    getList({selected: 0});
  }, [detectChange, isMasking])

  const accept = () => {
    acceptStatus(courseId).then((res: any) => {
      alert('승인 되었습니다.');
      navigate(-1);
    });
  }

  const refuse = () => {
    refuseStatus(courseId).then((res: any) => {
      alert('거절 되었습니다.');
      navigate(-1);
    });
  }

  const downloadDataExcel = () => {
    let params = {
      masking: isMasking,
      start: start,
      end: end,
    }
    downloadAdminExcel(courseId, params).then((res: any) => {
      const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}_수강현황.xlsx`);
      document.body.appendChild(link);
      link.click();
    })
  }

  const studentYearChange = (studentYear: number | null) => {
    switch(studentYear) {
      case 0 : return "";
      case 1 : return "예과 1학년";
      case 2 : return "예과 2학년";
      case 3 : return "본과 1학년";
      case 4 : return "본과 2학년";
      case 5 : return "본과 3학년";
      case 6 : return "본과 4학년";
      case 7 : return "휴학";
      default : return "";
    }
  }

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="lecture_status">
          <CCardHeader className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0 pt-2 pb-2 fs-5 fw-bold">
              <CBadge color="info" className="me-3">{category}</CBadge>
              {title}
              <span className="fw-normal fs-6 ms-3">수강현황</span>
            </h1>
            <CButton color="success" variant="outline" onClick={downloadDataExcel}>엑셀저장</CButton>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-center align-items-center py-3">
                <span className="me-3">시청기간 조회</span>
                <CFormInput type="date" className="w-25" onChange={(e) => setStart(e.target.value)}/>
                <span className="mx-3">~</span>
                <CFormInput type="date" className="w-25" onChange={(e) => setEnd(e.target.value)}/>
                <CButton type="button" color="primary" className="ms-3" onClick={(e) => setDetectChange(!detectChange)}>조회</CButton>
              </div>
            </div>

            <div className="card-handler mb-3">
              <div className="d-flex justify-content-between align-items-end">
                <div>
                <span className="me-3">총 <em>{totalElements}</em>명이 시청함</span>
                <CButton type="button" color="dark" variant="outline" onClick={()=> setIsMasking(!isMasking)}>마스킹(*) 해제 조회</CButton>
                </div>
                <div>
                  {(permission === 'WAIT' || permission === 'RETRY' || permission === 'ACCEPTED' ) &&
                    <span className="me-3">현황요청</span>
                  }
                  { ( permission === 'WAIT' || permission === 'RETRY') &&
                    <CButton color="success text-white me-2" onClick={accept}>승인</CButton>
                  }
                  { (permission === 'WAIT' || permission === 'RETRY' || permission === 'ACCEPTED' ) &&
                    <CButton color="danger text-white" onClick={refuse}>거절</CButton>
                  }

                </div>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '11%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '6%'}}
                                    scope="col">학번</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '6%'}}
                                    scope="col">학년</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '7%'}}
                                    scope="col">이름</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">이메일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">시청 시간</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">수강 시작 일자</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">마지막 시청일자</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {(lists && lists.length) ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{item.studentNumber}</CTableDataCell>
                    <CTableDataCell>{studentYearChange(item.studentYear)}</CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.email }</CTableDataCell>
                    <CTableDataCell>{Math.floor(item.progressSecond/3600) + '시' + Math.floor((item.progressSecond%3600)/60) + '분' + item.progressSecond%60 + '초'}</CTableDataCell>
                    <CTableDataCell>{item.createdAt[0]}.{item.createdAt[1]}.{item.createdAt[2]}</CTableDataCell>
                    <CTableDataCell>{item.modifiedAt[0]}.{item.modifiedAt[1]}.{item.modifiedAt[2]}</CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={6}>수강한 학생이 없습니다.</CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminLectureStatusPage;
