import 'views/desktop/lecture/style/watch-information.scoped.sass'
import images from 'assets/images/global/icon_file.svg';
import {useRef} from "react";

type files= {
  id: number,
  name: string,
  url: string,
}

type WatchInformationDetail = {
  id: number,
  title: string,
  description: string,
  courseFiles: Array<files>
}

interface ListProps {
  data: WatchInformationDetail | undefined
}

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1;
const date = new Date().getDate();

const today = `${year}-${month >= 10 ? month : '0' + month}-${date >= 10 ? date : '0' + date}`

const WatchInformation = ({data}: ListProps) => {
  const downloadRef = useRef <Array<HTMLAnchorElement | null>> ([]);
  const downloadAll = () => {
    const zip = require('jszip')();
    const FileSaver = require('file-saver');
    let files = data?.courseFiles ?? [];

    files.forEach(x => {
      zip.file(x.name, x.url);
    })


    zip.generateAsync({type: "blob"}).then((content: any) => {
      FileSaver.saveAs(content, `첨부파일_${data?.id}_${today}.zip`);
    });
  }

  return (
    <>
      <article className="watch-description-wrap">
        <div className="title-wrap">
          <h3>강의소개</h3>
        </div>
        <div className="content-wrap">
          <pre>
            {data?.description}
          </pre>
        </div>
      </article>
      <article className="watch-attachment-wrap">
        <div className="title-wrap">
          <h3>첨부파일 <span>({data?.courseFiles?.length})</span></h3>
          { Number(data?.courseFiles?.length) > 0 &&
            <a href="/" className="btn-download-all" onClick={e => {e.preventDefault();downloadAll();}} target="_blank">
              <img src={images} alt="다운로드"/>
              전체 다운로드
            </a>
          }
        </div>
        <div className="content-wrap">
          {data?.courseFiles && data?.courseFiles.map((file,index) =>
            <a href={`${file.url}`} download={`${data?.title}_${today}`} key={file.id} ref={itself => downloadRef.current[index] = itself} className="btn-download" target="_blank">
              <img src={images} alt="다운로드"/>
              {file.name}
            </a>
          )}
        </div>
      </article>
    </>
  )
}

export default WatchInformation;
