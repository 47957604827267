import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {RootState, useAppSelector} from 'module/Module';
import {getCookie} from "../utils/cookie";

const CheckRoute = ({
     children,
     check,
   } : { children: JSX.Element; check: boolean;}) => {
  const isCheck:boolean = !!getCookie("login_check");
  const loading:boolean = useAppSelector((state: RootState) => state.authentication.loading);
  let location = useLocation();

  if (loading) {
    return <p>Checking authentication..</p>;
  }

  if (!isCheck) {
    alert('접근 권한이 없습니다.');
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

export default CheckRoute;
