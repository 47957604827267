import { Link } from 'react-router-dom';
import 'views/desktop/main/style/main-newest-lecture.scoped.sass'
import React, {useEffect, useState} from "react";
import {getMainLecture} from 'api/main/lecture';
import {Thumbnail} from "utils/type";
import {secondToTime} from "../../../../utils/helper";

type MainNewestLectureType = {
  id: number,
  major: string,
  category: string,
  title: string,
  durationTotal: number,
  courseThumbnail: Thumbnail,
  thumbnail: Thumbnail,
  user: string,
  videoType: string;
  videoKey: string;
  createdAt: Array<number>,
}

const MainNewestLecture = () => {
  const [lists, setLists] = useState<Array<MainNewestLectureType>>([]);

  useEffect(() => {
    let params = {
      recommend: false,
      page: 1,
      size: 8,
    }
      getMainLecture(params).then((res:any) => {
        setLists(res.data)
      })
  },[])

  return (
    <article className="newest-lecture">
      <div className="title-wrap">
        <div className="title-wrap">
          <h2>최신강의</h2>
        </div>
        <Link to="/lecture/list" className="btn-create">전체 강의</Link>
      </div>
      <div className="content-wrap">
      {lists.length > 0 &&
        <ul className="newest-card-list">
          {lists.map( (list) => (
            <li key={list.id} className="newest-card-item">
              <Link to={`/lecture/watch/${list.id}`}>
                {list.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={list.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                    <span className="lecture-term">{secondToTime(Number(list.durationTotal))}</span>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={list.courseThumbnail?.url ? list.courseThumbnail.url : `https://img.youtube.com/vi/${list.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                    <span className="lecture-term">{secondToTime(Number(list.durationTotal))}</span>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type" style={
                    {backgroundColor: list?.category === '기초의학교실' ? '#f8cbad'
                        : list?.category === '임상의학교실' ? '#c6e0b4'
                        : list?.category === '인문사회의학교실' ? '#d8c5ff'
                        : list?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{list.major}</span>
                  <h3 className="lecture-name">{list.title}</h3>
                  <p className="lecture-information">
                    <span className="writer">{list.user}</span>
                    <span className="date">{list.createdAt[0]}.{list.createdAt[1].toString().padStart(2,'0')}.{list.createdAt[2].toString().padStart(2,'0')}</span>
                  </p>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      }
      {
        lists.length ===0 && <p className="none-list">최신 강의가 없습니다.</p>
      }
      </div>
    </article>
  );
};

export default MainNewestLecture;
