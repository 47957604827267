import {Link} from 'react-router-dom';

import 'views/mobile/notice/style/m-notice.sass'
import {useEffect, useState} from 'react';
import {noticeList} from 'api/notice/notice';
import {setTitle} from "module/mobile/title";
import {useAppDispatch} from "module/Module";

type Notice = {
  id: number,
  title: string,
  createdAt: Array<number>,
}

const MNotice = () => {
  const [lists, setList] = useState<Array<Notice>>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('공지사항'));

    let params = {
      page: 0,
      size: 10,
    }
    noticeList(params).then((res: any) => {
      let newItems = res.data.content;
      setList(newItems);
    })
  }, [])

  return (
    <div className="m-notice">
      <div className="content-wrap">
        <ul>
          {lists && lists.map((item) => (
            <li key={item.id}>
              <p className="notice-title"><Link to={`/notice/${item.id}`}>{item.title}</Link></p>
              <p className="notice-date">{item.createdAt[0]}.{item.createdAt[1].toString().padStart(2,'0')}.{item.createdAt[2].toString().padStart(2,'0')}</p>
            </li>
          ))}
        </ul>
          { lists.length === 0 && (
            <p className="notice-none">
              공지사항이 없습니다.
            </p>
          )}
      </div>
    </div>
  );
};

export default MNotice;
