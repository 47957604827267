import React from 'react';
import {Outlet} from 'react-router-dom';
import MHeader from 'views/mobile/common/layout/MHeader';
import MFooter from 'views/mobile/common/layout/MFooter';
import MAside from 'views/mobile/common/layout/MAside';
import {RootState, useAppSelector} from 'module/Module';

const MAppLayout = () => {
  const isOpen:boolean = useAppSelector((state: RootState) => state.asideModule.isOpen);

  return (
    <>
      <MHeader/>
      {isOpen && <MAside/>}
      <main id="app_main">
        <Outlet/>
      </main>
      <MFooter/>
    </>
  )
}

export default MAppLayout
