import { useState, useEffect } from 'react';
import {setTitle} from 'module/mobile/title';
import {useAppDispatch} from 'module/Module';
import { useParams, useNavigate } from 'react-router-dom';

// style
import 'views/mobile/user/style/m-find.sass'

// component
import MFindEmailComponent from 'views/mobile/user/components/MFindEmail';
import MFindPasswordComponent from 'views/mobile/user/components/MFindPassword';

const MFindPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('아이디 찾기/비밀번호 재설정'));
  });

  const params = useParams();
  const showType = params.show;
  const navigate = useNavigate();
  const [show, setShow] = useState <string> (showType!);
  const [active, setActive] = useState<number>(show === 'email' ? 0 : 1 );

  const showFind = () => {
    switch (show) {
      case 'email': return <MFindEmailComponent />
      case 'password': return <MFindPasswordComponent />
    }
  };

  const clickEmail = () => {
    setShow('email');
    navigate('/find/email', {replace: true});
    setActive(0);
  }

  const clickPassword = () => {
    setShow('password');
    navigate('/find/password', {replace: true});
    setActive(1);
  }

  return (
    <div className="m-find">
      <section className="navigation-wrap">
        <ul className="find-navigation">
          <li className={active === 0 ? 'active' : ''}>
            <button onClick={clickEmail}>아이디 찾기</button>
          </li>
          <li className={active === 1 ? 'active' : ''}>
            <button onClick={clickPassword}>비밀번호 재설정</button>
          </li>
        </ul>
      </section>
      <div className="m-container">
        <section className="content-wrap">
          {showFind()}
        </section>
      </div>
    </div>
  );
};

export default MFindPage;
