// react
import React, { useEffect } from 'react';
import {useDispatch} from 'react-redux';
import Routes from 'routes/Routes';
import {useLocation, useNavigate} from "react-router-dom";
// utils
import {removeCookie} from 'utils/cookie';
import {onLoginSuccess} from 'utils/auth'
// redux
import {deleteAuth, setAuth} from 'module/auth';
import {setCategory} from 'module/class-category';
// api
import {onSilentRefresh} from 'api/user/login';
import {getUserData} from 'api/user/user';
import {getClassCategory} from 'api/common/category';
import {withCookies} from "react-cookie";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    onSilentRefresh().then((res:any) => {
      onLoginSuccess(res);
      getUserData().then((res: any) => {
        dispatch(setAuth(res.data));
      })
    }).catch((err:any) => {
      if(err.response.status===401){
        alert('로그인이 만료되었습니다.')
        dispatch(deleteAuth());
        removeCookie('login_token', {path: '/'});
        navigate('/')
      }
    })

    getClassCategory().then((res: any) => {
      dispatch(setCategory(res.data));
    }).catch(() => {
      dispatch(setCategory([]));
    })
  },[dispatch, pathname])

  return (
    <>
      <Routes />
    </>
  );
}

export default withCookies(App);
