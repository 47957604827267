import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { noticeCreate } from 'api/admin/notice';

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol, CFormInput, CFormLabel, CFormTextarea, CInputGroup, CInputGroupText,
  CRow
} from '@coreui/react';

type FormData = {
  title: string,
  content: string,
  file: object,
};

const AdminNoticeCreatePage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const postNotice = () => {
    // @ts-ignore
    const formData = new FormData(document.getElementById("notice_create_form"));

    noticeCreate(formData).then((res: any) => {
      alert('공지사항이 등록되었습니다.');
      navigate(-1);
    }).catch((err:any) => {
      alert(err.message);
    })
  }

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="notice_create">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">공지사항 생성</h1>
          </CCardHeader>
          <form id="notice_create_form" onSubmit={handleSubmit(postNotice)}>
            <CCardBody className="card-body">
              <div className="mb-3">
                <CFormLabel htmlFor="title">제목</CFormLabel >
                <CInputGroup className="mb-3">
                  <CInputGroupText  id="basic-addon1">제목</CInputGroupText >
                  <CFormInput type="text"
                         id="title"
                         placeholder="제목 입력"
                         {...register("title", {
                           required: "제목을 입력해 주세요.",
                           minLength: {value: 2, message: "2자 이상 입력해 주세요."},
                           maxLength: {value: 60, message: "60자까지 입력해 주세요."}
                         })}/>
                </CInputGroup >
                {Object.keys(errors).length > 0 &&
                  <div className="error-wrap">
                    {errors.title && <p className="error-message"
                                        role="alert">{errors.title.message}</p>}
                  </div>
                }
              </div>
              <div className="mb-3">
                <CFormLabel  htmlFor="content">내용입력</CFormLabel>
                <CInputGroup className="mb-3" size="lg">
                  <CFormTextarea id="content"
                            rows={11}
                            style={{resize: "none"}}
                            {...register("content", {
                              required: "내용을 입력해 주세요.",
                              minLength: {value: 2, message: "2자 이상 입력해 주세요."}
                            })}></CFormTextarea>
                </CInputGroup>
                {Object.keys(errors).length > 0 &&
                  <div className="error-wrap">
                    {errors.content && <p className="error-message"
                                        role="alert">{errors.content.message}</p>}
                  </div>
                }
              </div>
              <div className="mb-3">
                <CFormInput label="파일첨부" type="file" id="formFile"
                            {...register("file")} accept=".jpg, .png, .pdf"/>
              </div>
              <CFormInput type="hidden" name="public" value={"true"}></CFormInput>
            </CCardBody>

            <CCardFooter className="d-flex justify-content-end">
              <CButton type="submit" color="primary" className="me-3">등록</CButton>
              <CButton type="button" color="dark" variant="outline" onClick={() => {
                if(window.confirm("해당 페이지에서 나가시겠습니까?")){navigate(-1)}
              }}>취소
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminNoticeCreatePage;
