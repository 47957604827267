import {useParams} from 'react-router';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol, CFormInput, CInputGroup,
  CNav,
  CNavItem,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {getUserPlaylist, getUserSubPlaylist} from "api/admin/user";
import ReactPaginate from "react-paginate";
import {RootState, useAppDispatch, useAppSelector} from "module/Module";
import PlayListDetailPopup
  from "views/desktop/common/popup/PlayListDetailPopup";
import {showDetailPopupF} from "module/lecture-add-detail-popup";

type StudentSubscribe = {
  id: number,
  title: string,
  name: string,
  courseCount: number,
  modifiedAt: Array<number>,
}

const AdminStudentSubscribePage = () => {
  const userId = Number(useParams().id);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const [keyword, setKeyword] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(1);
  const [detectChange, setDetectChange] = useState<boolean>(true);
  const [lists, setLists] = useState<Array<StudentSubscribe>>([]);
  const dispatch = useAppDispatch();

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 10
    }
    getUserSubPlaylist(userId, params).then((res:any) => {
      setLists(res.data.content);
      setTotalPage(res.data.totalPages);
    })
  }

  useEffect(() => {
    getList({selected: 0});
  },[detectChange]);

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="student_subscribe">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">학생 회원관리</h1>
          </CCardHeader>

          <CCardBody>
            <CNav variant="tabs" className="mb-3">
              <CNavItem active>
                <Link to={`/admin/user/student/edit/${userId}`} className="nav-link">회원정보</Link>
              </CNavItem>
              <CNavItem>
                <Link to={`/admin/user/student/playlist/${userId}`} className="nav-link">내 플레이리스트</Link>
              </CNavItem>
              <CNavItem>
                <Link to={`/admin/user/student/subscribe/${userId}`} className="nav-link active">구독 플레이리스트</Link>
              </CNavItem>
            </CNav>

            <div className="card-search mb-3">
              <div className="d-flex justify-content-end">
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="플레이리스트명, 작성자 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '35%'}}
                                    scope="col">플레이리스트 명</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">작성자</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">강의 개수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '25%'}}
                                    scope="col">최종 업데이트</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell style={{ cursor: 'pointer' }} onClick={() => dispatch(showDetailPopupF({showDetailPopup: true, playlistId: item.id}))}>
                      {item.title}
                    </CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.courseCount}</CTableDataCell>
                    <CTableDataCell>{item.modifiedAt[0]}.{item.modifiedAt[1]}.{item.modifiedAt[2]}</CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={5}>
                      등록된 플레이리스트가 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
      {showDetailPopup && <PlayListDetailPopup/>}
    </CRow>
  )
}

export default AdminStudentSubscribePage
