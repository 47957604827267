import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  mode: '',
}

type playlistModeType = {
  mode: string,
}

const playlistMode = createSlice({
  name: 'playlistMode',
  initialState,
  reducers: {
    setMode(state: playlistModeType, action: PayloadAction<string>) {
      state.mode = action.payload;
    }
  }
});

export const { setMode } = playlistMode.actions;
export default playlistMode.reducer;
