import 'views/mobile/main/style/m-main-subscribe.scoped.sass';
import { useEffect } from 'react';
import {delSubscribe, getSubscribePlayListData} from 'api/playlist/playlist';
import {CommonPlaylistProps} from 'utils/type';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import { ChangeSub, getSubPlaylist } from 'module/sub-playlist';
import {setWatchMode} from 'module/watch-mode';
import { Link } from 'react-router-dom';

const MMainSubscribePlaylist = () => {
  const dispatch = useAppDispatch();

  const subPlaylist:Array<CommonPlaylistProps> = useAppSelector((state: RootState) => state.subPlaylist.subPlaylist);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);

  useEffect(() => {
    let params = {
      page: 1,
      size: 5
    }
    getSubscribePlayListData(params).then((res: any) => {
      dispatch(getSubPlaylist(res.data.content));
    })
  }, [subChange]);

  const delSubscribeF = (Id:number) =>{
    if(window.confirm('해당 플레이리스트를 구독 취소하시겠습니까?')) {
      delSubscribe(Id).then((res: any) => {
        dispatch(ChangeSub(!subChange));
      })
    }
  }

  const noneComponent = () => {
    let playListNone = [];

    for(let i =  0 ; i < 5 - subPlaylist.length; i++) {
      playListNone.push(
        <div className={`subscribe-none`} key={`none_${i}`}>
          <p>구독한 플레이리스트가 없습니다.</p>
          <Link to='/playlist/public'>전체 공유 플레이리스트로 이동하기 &gt;</Link>
        </div>
      )
    }

    return playListNone;
  }

  return (
    <article className="main-subscribe-lecture">
      <div className="m-container">
        <div className="title-wrap">
          <h2>구독 플레이리스트</h2>
          {subPlaylist.length >=5 && <Link to="/playlist/subscribe" className="btn-more">+</Link>}
        </div>
        <div className="content-wrap">
          {subPlaylist.map((data,index) => index<5 && (
          <div className="subscribe-box-wrap" key={index}>
            <div className="subscribe-title-wrap">
              <div className="subscribe-title-text">
                {data?.courses[0] ? 
                  (
                    <Link
                      className='subscribe-title'
                      to={`/lecture/watch/${data?.courses[0].id}`}
                      onClick={() => dispatch(setWatchMode({playlistMode: true, playlistId: data?.id}))}>{data.title}&ensp;
                    </Link>
                  ) : (
                    <span className='subscribe-title'>{data.title}&ensp;</span>
                  )
                }
                <span className="subscribe-info">'{data.name}'님의 플레이리스트입니다.</span>
              </div>
              <button className="btn-cancel-subscribe" onClick={()=> delSubscribeF(data.id)}>구독취소</button>
            </div>
            <div className="subscribe-content-wrap">
              <ul>
                {data.courses.length > 0 ?
                  data.courses.map((element, index) => (
                  <li>
                    <Link to={`/lecture/watch/${element.id}`}>
                      {index+1}. <span className="lecture-title">&nbsp;{element.title}&nbsp;</span> / {element.name}
                    </Link>
                  </li>)) : (<li>등록한 강의가 없습니다.</li>)}
              </ul>
            </div>
          </div>
          ))}
          {noneComponent()}
        </div>
      </div>
    </article>
  )
}

export default MMainSubscribePlaylist;
