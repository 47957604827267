import {PlayList} from 'utils/type';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {showDetailPopupF} from 'module/lecture-add-detail-popup';
import {ChangePlaylist, getDelList, getDelPlaylist, getMyPlaylist} from 'module/my-playlist';
import { changeShare } from 'api/playlist/playlist'

type PlaylistObjectProps = {
  data: PlayList,
}

const MyPlaylistCard = ({data}: PlaylistObjectProps) => {
  const dispatch = useAppDispatch();

  const delList:Array<number> = useAppSelector((state: RootState) => state.myPlaylist.delList);
  const playlists:Array<PlayList> = useAppSelector((state: RootState) => state.myPlaylist.myPlaylist);

  const mode:string = useAppSelector((state: RootState) => state.playlistMode.mode);

  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);

  const del = (playlistId: any, data: any) => {
    dispatch(getDelList(playlistId));
    dispatch(getDelPlaylist(data));
    let newArray = [...playlists];
    newArray = newArray.filter(x => x.id !== playlistId);
    dispatch(getMyPlaylist(newArray));
  }

  const ChangeShare = (e: any, Id:any) => {
    e.stopPropagation();
    changeShare(Id).then((res: any) => {
      if(!(data.shareable)){
        alert('공유 되었습니다.')
      } else {
        alert('공유 해제 되었습니다.')
      };
      dispatch(ChangePlaylist(!myChange));
    })
  }
  
  return (
    <div className="my-playlist-card-wrap">
      {(mode === 'edit' && !delList.includes(data.id)) && <button type="button" className="btn-drag"></button>}
      <div className={`my-playlist-card ${mode==='edit' ? 'edit-mode' : ''}`} onClick={() => !(mode==='edit') ? dispatch(showDetailPopupF({showDetailPopup: true, playlistId: data.id})) : ''}>
        <div className="card-header">
          <h3 className="playlist-title">{data.title}</h3>
          <span className="playlist-lecture-count">총 <b>{data.courses.length}</b>개 강의</span>
          {!(data.shareable) ? (<button className="share" onClick={(e) => ChangeShare(e, data.id)}>공유하기</button>) : (<button className="share-cancel" onClick={(e) => ChangeShare(e, data.id)}>공유해제</button>)}
        </div>
        <div className="card-content">
          <p className="playlist-description">{data.description}</p>
          {data.userCount && <p className="playlist-share">공유된 수 : <b>{data.userCount}</b>명</p>}
        </div>
        <span className="playlist-date">등록일자: {data.createdAt[0]}.{data.createdAt[1].toString().padStart(2,'0')}.{data.createdAt[2].toString().padStart(2,'0')}</span>
      </div>
      {(mode === 'edit' && !delList.includes(data.id)) && (<button type="button" className="btn-delete" onClick={() => del(data.id, data)}>삭제</button>)}
    </div>
  )
}
export default MyPlaylistCard;
