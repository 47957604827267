import Send from 'utils/send';

export const getTag = () => {
  return Send({
    method: 'get',
    url: '/api/courses/favorite-tag',
  })
}

export const getAllTag = () => {
  return Send({
    method: 'get',
    url: '/api/tags/list'
  })
}

export const putTag = (data: any) => {
  return Send({
    method: 'put',
    url: '/api/courses/favorite-tag',
    data: data
  })
}
