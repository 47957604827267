import React from 'react';
import {Outlet} from 'react-router-dom';
import MSimpleHeader from 'views/mobile/common/layout/MSimpleHeader';
import MFooter from 'views/mobile/common/layout/MFooter';

const MHalfFreeLayout = () => (
  <>
    <MSimpleHeader/>
    <main id="app_free_main">
      <Outlet/>
    </main>
    <MFooter/>
  </>
)

export default MHalfFreeLayout
