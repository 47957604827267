// @ts-ignore
import Send from 'utils/send.ts'

export const inquiryList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/inquiries',
    params: params
  })
}

export const inquiryDetail = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/admin/inquiries/${id}`,
  })
}

export const inquiryPost = (id: number, data: any) => {
  return Send({
    method: 'post',
    url: `/api/admin/inquiries/${id}`,
    data: data
  })
}
