import { useState } from 'react';
import { Link } from 'react-router-dom';
import 'views/desktop/main/style/main-myplaylist.scoped.sass'
import {PlayList} from 'utils/type';
import { RootState, useAppSelector } from 'module/Module';
import MainMyPlayList from './MainMyPlayList';
import MainSubscribePlaylist from './MainSubscribePlaylist';

const MainMyList = () => {
  const playlist:Array<PlayList> = useAppSelector((state: RootState) => state.myPlaylist.myPlaylist);
  const subPlaylist:Array<PlayList> = useAppSelector((state: RootState) => state.subPlaylist.subPlaylist);

  const [show, setShow] = useState <string> ('my');
  const [active, setActive] = useState<number>(show === 'my' ? 0 : 1 );

  const showContent = () => {
    switch (show) {
      case 'my': return <MainMyPlayList />
      case 'sub': return <MainSubscribePlaylist />
    }
  };

  const clickNav = (type: string, active: number) => {
    setShow(type);
    setActive(active);
  }

  return (
    <article className="my-playlist">
      <div className="menu-wrap">
        <ul className="menu-list">
          <li className={active === 0 ? 'active' : ''} onClick={() => clickNav('my', 0)}>
            <h2>내 플레이리스트</h2>
            {playlist.length >= 5 && <Link to="/playlist/my" className="btn-more">+</Link>}
          </li>
          <li className={active === 1 ? 'active' : ''} onClick={() => clickNav('sub', 1)}>
            <h2>구독 플레이리스트</h2>
            {subPlaylist.length >=5 && <Link to="/playlist/subscribe" className="btn-more">+</Link>}
          </li>
        </ul>
      </div>
      {showContent()}
    </article>
  );
};

export default MainMyList;
