import {getWatchedList} from 'api/main/lecture';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import 'views/mobile/main/style/m-main-wacth-lecture.scoped.sass'

type watchedLectureType = {
  id: number,
  major: string,
  name: string,
  title: string
}

const MMainWatchLecture = () => {
  const [lists, setLists] = useState<Array<watchedLectureType>>();

  const getList = () => {
    let params = {
      size: 15
    }
    getWatchedList(params).then((res: any) => {
      setLists(res.data);
    })
  }
  useEffect(() => {
    getList()
  },[])

  return (
    <article className="main-watch-lecture">
      <div className="m-container">
        <div className="title-wrap">
          <h2>최근 시청한 강의</h2>
        </div>
        <div className="content-wrap">
          <div className="lecture-box">
            {(lists && lists.length > 0) &&
            <ul className="lecture-list">
              {lists.map((list,index) =>
                <li key={list.id}>
                  <Link to={`/lecture/watch/${list.id}`}>
                  <p>{index+1}. {list.major} / <b>&nbsp;{list.title}&nbsp;</b> / {list.name}</p>
                  </Link>
                </li>
                )}
            </ul>
            }
            {lists?.length === 0 && <p className="none-list">아직 시청한 강의가 없습니다.</p>}
          </div>
        </div>
      </div>
    </article>
  )
}

export default MMainWatchLecture;
