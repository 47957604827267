import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  deleteAlarm,
  deleteAllAlarm,
  getAlarm
} from "api/common/alarm";

import 'views/mobile/common/layout/m-alaram.scoped.sass'
import {useAppDispatch} from "module/Module";
import {setTitle} from "module/mobile/title";

type AlarmType = {
  id: number,
  courseId : number,
  commentId: number,
  title: string,
  category: string,
  createdAt: Array<number>,
}

const MAlarm = () => {
  const [alarmList, setAlarmList] = useState<Array<AlarmType>> ([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setTitle('알림 메세지'));
  });

  const getAlarmList = () => {
    getAlarm().then((res:any) => {
      setAlarmList(res.data);
    })
  }

  const deleteAlarmItem = (id: number) => {
    deleteAlarm(id).then((res: any) => {
      getAlarmList();
    })
  }
  const deleteAllAlarmList = () => {
    deleteAllAlarm().then((res: any) => {
      getAlarmList();
    })
  }
  useEffect(() => {
    getAlarmList();
  }, [])

  return (
    <div className="m-alarm-box">
      <div className="alarm-header">
        {alarmList?.length > 0 && <button type="button" className="alarm-read-all" onClick={deleteAllAlarmList}>모두읽음</button>}
      </div>
      <div className="alarm-body">
        {alarmList && (
          <ul>
            {alarmList.map((list) =>
              <li key={list.courseId}>
                <Link to={`/lecture/watch/${list.courseId}`} target="_blank" onClick={() => deleteAlarmItem(list.id)}>
                  [{list.category}] {list.title} 강의에 댓글이 등록되었습니다.
                </Link>
                <p className="alarm-date">{list.createdAt[0]}.{list.createdAt[1]?.toString().padStart(2,'0')}.{list.createdAt[2].toString().padStart(2,'0')}</p>
              </li>
            )}
          </ul>
        )}
        {(!alarmList || alarmList.length === 0) && <p className="alarm-none">새로운 알림이 없습니다.</p>}
      </div>
    </div>
  )
}

export default MAlarm
