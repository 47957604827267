import { useState } from 'react';
import { Link } from 'react-router-dom';
import {SubmitHandler, useForm} from 'react-hook-form';
import {findEmail} from 'api/user/find';

type FormData = {
  name: string,
  birth: string,
};

const MFindEmailComponent = () => {
  const [isSuccess, setIsSuccess] = useState <boolean> (false);
  const [myId, setMyId] = useState <string>("");
  const [findError, setFindError] = useState<string>("");
  const {register, handleSubmit, watch, setValue, formState: { isSubmitting, errors }} =useForm<FormData>();

  const postFindEmail: SubmitHandler<FormData> = () => {
    let data = {
      name: watch().name,
      birthday: watch().birth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
    }
    findEmail(data).then((res: any) => {
      setIsSuccess(true);
      setMyId(res.data.email);
    }).catch((err: any) => {
      setIsSuccess(false);
      setMyId('');
      setFindError("등록되지 않은 정보입니다. 입력하신 정보를 다시 확인해주세요.");
    });
  };

  const showComponent = () => {
    switch (isSuccess) {
      case false: return (
        <>
          <form onSubmit={handleSubmit(postFindEmail)}>
            <p className="tip">회원가입 시에 등록한 이름과 생년월일을 입력하시면<br /> 아이디를 확인할 수 있습니다.</p>
            <div className="form-wrap">
              <div className="input-wrap">
                <input type="text" id="name" className="input-name" placeholder="이름"
                       aria-invalid={errors.name ? "true" : "false"}
                       {...register("name", { required: "이름을 입력해 주세요.", minLength: {value: 2, message: "2자 이상 입력해 주세요."}})}/>
                <label htmlFor="name" className="hidden-label ir_pm">이름</label>
              </div>
              <div className="input-wrap">
                <input type="text" id="birth" className="input-birth" placeholder="생년월일 8자리 숫자만 입력 예)20220527"
                       aria-invalid={errors.birth ? "true" : "false"} maxLength={8}
                       {...register("birth", { required: "생년월일을 입력해 주세요.", minLength: {value: 8, message: "8자리를 입력해주세요."},
                       onChange: e => { setValue('birth',e.target.value.replace(/[^0-9.]/g, ''))}, })}/>
                <label htmlFor="birth" className="hidden-label ir_pm">생년월일</label>
              </div>
            </div>
            {(Object.keys(errors).length > 0 || findError !== '')&&
              <div className="error-wrap">
                {errors.name && <p className="m-error-message" role="alert">{errors.name.message}</p>}
                {errors.birth && <p className="m-error-message" role="alert">{errors.birth.message}</p>}
                {findError && <p className="m-error-message" role="alert">{findError}</p>}
              </div>
            }
            <div className={`button-wrap${(Object.keys(errors).length > 0 || findError !== '')? ' errors-on':''}`}>
              <button>아이디 찾기</button>
            </div>
          </form>
        </>
      )
      case true: return (
        <>
          <p className="tip">이메일 확인 후, 로그인 또는 비밀번호 재설정을<br/> 진행해 주세요.</p>
          <p className="my-id">{myId}</p>
          <div className="button-wrap">
            <Link to="/">로그인 하기</Link>
          </div>
        </>
      )
    }
  };

  return (
    <div className="find-email">
      {showComponent()}
    </div>
  );
};

export default MFindEmailComponent;
