import Send from 'utils/send'

export const getDashboardStatus = (params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/courses/permission/dashboard`,
    params: params,
  })
}

export const getLectureList = (params: any) => {
  return Send ({
    method: 'get',
    url: `/api/admin/courses`,
    params: params
  })
}

export const getLectureStatus = (courseId: number, params: any) => {
  return Send ({
    method: 'get',
    url: `/api/admin/courses/${courseId}/progress`,
    params: params
  })
}

export const acceptStatus = (courseId: number) => {
  return Send ({
    method: 'post',
    url: `/api/admin/courses/${courseId}/permission`,
  })
}

export const refuseStatus = (courseId: number) => {
  return Send ({
    method: 'delete',
    url: `/api/admin/courses/${courseId}/permission`,
  })
}

export const reThumLecture = (id:number) => {
  return Send ({
    method: 'patch',
    url: `/api/courses/videos/${id}/vimeo/thumbnail`,
  })
}

export const reDurLecture = (id:number) => {
  return Send ({
    method: 'patch',
    url: `/api/courses/videos/${id}/vimeo/duration`,
  })
}

export const deleteLecture = (id:number) => {
  return Send ({
    method: 'delete',
    url: `/api/admin/courses/${id}`,
  })
}

export const patchLecture = (id:number) => {
  return Send ({
    method: 'patch',
    url: `/api/admin/courses/${id}`,
  })
}

export const getRecommendList = (id: number, params: any) => {
  return Send ({
    method: 'get',
    url: `/api/admin/courses/recommend`,
    params: params,
  })
}

export const postRecommendCourse = (id: number, data: any) => {
  return Send({
    method: 'post',
    url: `/api/admin/courses/${id}/recommend`,
    data: data,
  })
}

export const deleteRecommendCourse = (id: number, data:any) => {
  return Send({
    method: 'delete',
    url: `/api/admin/courses/${id}/recommend`,
    data: data,
  })
}

export const downloadAdminExcel = (courseId: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/admin/courses/${courseId}/progress/excel`,
    responseType: 'blob',
    params: params
  })
}
