import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CommonPlaylistProps} from 'utils/type'


const publicPlaylist = createSlice({
  name: 'publicPlaylist',
  initialState: [] as Array<CommonPlaylistProps>,
  reducers: {
    getPublicPlaylist(state: Array<CommonPlaylistProps>, action: PayloadAction<Array<CommonPlaylistProps>>) {
      return action.payload;
    }
  }
});

export const { getPublicPlaylist } = publicPlaylist.actions;
export default publicPlaylist.reducer;
