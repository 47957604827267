import { useEffect, useState } from "react";
import { useParams } from "react-router";

// style
import "views/desktop/lecture/style/lecture-watch.scoped.sass";

// component
import WatchVideo from "views/desktop/lecture/components/WatchVideo";
import WatchInformation from "views/desktop/lecture/components/WatchInformation";
import WatchComment from "views/desktop/lecture/components/WatchComment";

import { getLectureDetail } from "api/lecture/lecture";
import { Tag } from "utils/type";
import { RootState, useAppDispatch, useAppSelector } from "module/Module";
import PlayListPopup from "../../common/popup/PlayListPopup";
import { showPopupF } from "module/lecture-add-popup";

type Video = {
  id: number;
  key: string;
  length: number;
  type: string;
  outlink: boolean;
  thumbnail: Thumbnail;
};

type files = {
  id: number;
  name: string;
  url: string;
};

type Thumbnail = {
  id: number;
  name: string;
  url: string;
};

type WatchVideoDetailType = {
  id: number;
  title: string;
  major: string;
  category: string;
  videoDto: Video;
  wishMe: boolean;
  wishCount: number;
  likeMe: boolean;
  likeCount: number;
  playlistMe: boolean;
  playlistCount: number;
  mine: boolean;
  public: boolean;
  progressSecond: number;
  createdAt: Array<Number>;
  courseTags: Array<Tag>;
  viewCount: number;
  user: string;
};

type WatchInformationType = {
  id: number;
  title: string;
  description: string;
  courseFiles: Array<files>;
};

const LectureWatchPage = () => {
  const dispatch = useAppDispatch();
  const lectureId = Number(useParams().id);
  const lectureWatchChange: boolean = useAppSelector(
    (state: RootState) => state.lectureWatch.change
  );
  const showPopup: boolean = useAppSelector(
    (state: RootState) => state.lectureAddPopup.showPopup
  );
  const [videoDetail, setVideoDetail] = useState<WatchVideoDetailType | any>();
  const [informationDetail, setInformationDetail] = useState<
    WatchInformationType
  >();

  useEffect(() => {
    dispatch(showPopupF(false));
    getLectureDetail(lectureId).then((res: any) => {
      setVideoDetail({
        id: res.data.id,
        title: res.data.title,
        major: res.data.major,
        category: res.data.category,
        videoDto: res.data.videoDto,
        wishMe: res.data.wishMe,
        wishCount: res.data.wishCount,
        likeMe: res.data.likeMe,
        likeCount: res.data.likeCount,
        playlistMe: res.data.playlistMe,
        playlistCount: res.data.playlistCount,
        mine: res.data.mine,
        public: res.data.public,
        progressSecond: res.data.progressSecond,
        createdAt: res.data.createdAt,
        courseTags: res.data.courseTags,
        viewCount: res.data.viewCount,
        user: res.data.user,
      });

      setInformationDetail({
        id: res.data.id,
        title: res.data.title,
        description: res.data.description,
        courseFiles: res.data.courseFiles,
      });
    });
  }, [lectureId, lectureWatchChange]);

  return (
    <div className="lecture-watch">
      <div className="u-container">
        <section className="watch-top">
          <WatchVideo data={videoDetail} />
        </section>
        <section className="watch-middle">
          <WatchInformation data={informationDetail} />
        </section>
        <section className="watch-bottom">
          <WatchComment />
        </section>
      </div>
      {showPopup && <PlayListPopup />}
    </div>
  );
};

export default LectureWatchPage;
