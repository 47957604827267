import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  showPopup: false,
  courseId: 0,
}

type ShowPopup = {
  showPopup: boolean,
  courseId: number | undefined,
}

const lectureAddPopup = createSlice({
  name: 'lectureAdd',
  initialState,
  reducers: {
    showPopupF(state: ShowPopup, action: PayloadAction<boolean>) {
      state.showPopup = action.payload;
    },
    setCourseId(state: ShowPopup, action: PayloadAction<number | undefined>) {
      state.courseId = action.payload;
    }
  }
});

export const { showPopupF, setCourseId } = lectureAddPopup.actions;
export default lectureAddPopup.reducer;
