import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  playlistMode: false,
  playlistId: 0,
}

type watchModeType = {
  playlistMode: boolean,
  playlistId: number | null | undefined,
}

const watchMode = createSlice({
  name: 'watchMode',
  initialState,
  reducers: {
    setWatchMode(state: watchModeType, action: PayloadAction<watchModeType>) {
      state.playlistMode = action.payload.playlistMode;
      state.playlistId = action.payload.playlistId;
    }
  }
});

export const { setWatchMode } = watchMode.actions;
export default watchMode.reducer;
