import React, {useEffect, useState} from 'react';
import * as tus from 'tus-js-client';

import {SubmitHandler, useFieldArray, useForm} from 'react-hook-form';
import {
  createLecture,
  uploadVideo
} from 'api/lecture/management';
import {fileController} from 'api/common/file';
import {useNavigate} from 'react-router-dom';

import defaultThumbnails from 'assets/images/global/default_lecture_thumbnail.png'
import iconTooltip from 'assets/images/global/icon_tooltip.svg';
import {getUserTags} from 'api/user/user';
import { youtubeDuration } from 'utils/helper';
import axios from "axios";

interface Tags {
  name: string,
}

type FormData = {
  title: string,
  description: string,
  tags: Array<Tags>,
  tagCheck: string,
  video: Video,
  videoCheck: string,
  isPublic: boolean,
  file: object,
};

type File = {
  id: number,
  name: string,
  url: string
}

type Video = {
  videoUploadType: string
  embedUrl: string,
  uploadLink: string,
  videoName: string,
  videoType: string,
  videoKey: string,
  length: number,
  fileId: number | null,
  isOutlink: boolean,
  videoDuration: number,
  thumbnailUrl: string | undefined,
  videoLink: string
  video: object
}

const CreateForm = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    formState: {isSubmitting, errors}
  } = useForm<FormData>({
    defaultValues: {
      video: {
        videoUploadType: "file",
        embedUrl: "",
        uploadLink: "",
        videoName: "",
        videoKey: "",
        length: 0,
        isOutlink: false,
        videoDuration: 0,
        videoType:"VIMEO",
        video: {},
        fileId: null,
        thumbnailUrl: defaultThumbnails,
        videoLink: ""}
    },
  });

  const getUserData = () => {
    getUserTags().then((res: any) => {
      let newObj:Array<Tags> = [];
      setValue('tagCheck', "Y");
      res.data.forEach((x:any, i:number) => {
        newObj.push({name: x.name});
      });
      if(res.data.length < 5) {
        for(let i = 0; i< 5-res.data.length; i++) {
          newObj.push({name: ""});
        }
      }
      if(!res.data) {
        for(let i = 0; i< 5; i++) {
          newObj.push({name: ""});
        }
      }
      tagReplace(newObj)
    })
  };

  const [btnClick, setBtnClick] = useState<boolean>(false);

  useEffect(() => {
    getUserData();

    if(errors.videoCheck){
      window.scrollTo(0, 450);
    }

    if(progress !== null){
      alert('영상이 업로드 중입니다. 잠시만 기다려주세요.')
    }
  }, [btnClick]);

  const {fields: tagField, append: tagAppend, replace: tagReplace} = useFieldArray({
    control,
    name: "tags",
  });

  const validateTags = () => {
    let flag:boolean = false;
    watch().tags.forEach(tag => {
      if(tag.name !== '') flag = true;
    })

    if(flag) setValue('tagCheck', "Y");
    else setValue('tagCheck', "N");
    trigger('tagCheck');
  }

  const [clickCheck, setClickCheck] = useState<boolean> (false);

  const postLecture: SubmitHandler<FormData> = () => {
    let tagsArray:Array<string> = [];
    setClickCheck(true);
    if(clickCheck) {
      alert('처리 중입니다. 잠시만 기다려 주세요.');
    }

    watch().tags.forEach(tag => {
      tagsArray.push(tag.name);
    });

    let checkVideoType = false;

    if(watch().video.videoType === 'YOUTUBE') {
      checkVideoType = true;
      let params = {
        part: 'contentDetails',
        id: watch().video.videoKey,
        key: 'AIzaSyDiilPHvYKaXofhuqAKlUEktZeGJVvlSSg',
      }
      delete axios.defaults.headers.common["Authorization"];
      axios({
        "url" : 'https://www.googleapis.com/youtube/v3/videos',
        "params" : params,
        method: 'get',
      }).then((res: any) => {
        setValue(`video.videoDuration`, youtubeDuration(res.data.items[0].contentDetails.duration));

          let data = {
            title: watch().title,
            description: watch().description,
            isPublic: watch().isPublic,
            files: files,
            tags: tagsArray,
            video: watch().video,
          }
          createLecture(data).then((res: any) => {
            alert('강의 생성이 완료되었습니다.');
            navigate('/lecture/management/list')
          }).catch(() => {
            alert('오류가 발생하였습니다.');
            setClickCheck(false);
          });

      })
    }
    if(!checkVideoType) {
      let data = {
        title: watch().title,
        description: watch().description,
        isPublic: watch().isPublic,
        files: files,
        tags: tagsArray,
        video: watch().video,
      }

      createLecture(data).then((res: any) => {
        alert('강의 생성이 완료되었습니다.');
        navigate('/lecture/management/list')
      }).catch(() => {
        alert('오류가 발생하였습니다.');
        setClickCheck(false);
      });
    }
  };

  const videoValidation = () => {
    let flag:boolean = false;
    if(watch().video.embedUrl !== '') flag=true;
    else flag = false;

    if(flag) setValue('videoCheck', "Y");
    else setValue('videoCheck', "N");
    trigger('videoCheck');
  }

  // Upload Video
  const [progress, setProgress] = useState<string|null>(null);
  const handleChange = async (eventObject: any) => {
    const file = eventObject.target.files[0];
    const fileSize = file.size.toString();

    let data = {
      filesize: fileSize,
      name: file.name
    }
    uploadVideo(data).then((res: any) => {
      const upload = new tus.Upload(file, {
        endpoint: 'https://api.vimeo.com/me/videos',
        uploadUrl: res.data.uploadLink,
        retryDelays: [0, 3000, 5000, 10000, 20000],
        metadata: {
          filename: file.name,
          filetype: file.type
        },
        headers: {},
        onError: function (error) {
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          let num:string|null = ((bytesUploaded * 100) / bytesTotal).toFixed(2);
          if(num === '100.00') num = null;
          let newNum = progress;
          newNum = num;
          setProgress(newNum);
        },
        onSuccess: function () {
          const newVideo = {
            embedUrl: res.data.embedUrl,
            videoUploadType: getValues(`video.videoUploadType`),
            uploadLink: res.data.uploadLink,
            videoLink: res.data.videoLink,
            videoKey: res.data.videoKey,
            length: Number(fileSize),
            videoDuration: 0,
            fileId: getValues(`video.fileId`),
            thumbnailUrl: getValues(`video.thumbnailUrl`),
            videoType: 'VIMEO',
            isOutlink: getValues(`video.isOutlink`),
            video: getValues(`video.video`),
            videoName: file.name,
          }
          setValue('video', newVideo);
          videoValidation();
        }
      });

      upload.start();
    }).catch((err: any) =>{
      alert(err.response.data.message)
    })
  };

  // upload thumbnail
  const thumbnailUpload = (eventObject: any) => {
    videoValidation();
    var formData = new FormData();
    const thumbnail = eventObject.target.files[0];
    eventObject.target.value = ''
    formData.append("file", thumbnail);
    var fileReader = new FileReader();
    fileReader.readAsDataURL(thumbnail);
    fileReader.onload = function(e:any) {
      setValue('video.thumbnailUrl', e.target.result);
    }
    fileController(formData).then((res: any) => {
      setValue('video.fileId', res.data.id);
    }).catch(() => {
      alert('파일용량이 너무 큽니다.');
    })
  };

  // upload file
  const [files, setFiles] = useState<Array<File>>([]);

  const fileUpload = (eventObject: any) => {
    var formData = new FormData();
    const file = eventObject.target.files[0];
    eventObject.target.value = ''
    formData.append("file", file);

    fileController(formData).then((res: any) => {
      const newFile = {
        name: res.data.name,
        id: res.data.id,
        url: res.data.url
      }
      setFiles([newFile, ...files]);
    }).catch((err: any) => {
      alert('파일용량이 너무 큽니다.');
    })
  };

  const deleteFile = (e: any) => {
    const name = e.target.getAttribute('name');
    setFiles(files.filter(file => file.name !== name));
  };

  const getYoutubeId = (url: string) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length===11)? match[7] : "";
  };

  const backEvent = () => {
    let check = watch().description === '' && watch().title === '';
    let tagCheck = true;
    let videoCheck = true;
    let videoLoadCheck = false
    watch().tags.some( (tag) => {
      if(tag.name !== '') tagCheck = false;
      return tag.name !== '';
    })
    if(watch().video.embedUrl !== '') videoCheck = false;
    if(progress !== null){
      videoLoadCheck = true;
    }
    if(check && tagCheck && videoCheck) {
      navigate('/lecture/management/list');
    } else {
      if(videoLoadCheck){
        if(window.confirm('영상 업로드가 완료되지않았습니다. 해당 페이지에서 나가시겠습니까?')) navigate('/lecture/management/list')
      } else {
        if(window.confirm('강의등록 페이지에서 나가시겠습니까?')) navigate('/lecture/management/list');
      }
    }
  };

  return (
    <form id="lecture_create_form" onSubmit={handleSubmit(postLecture)}>
      <section className="create-form-wrap">
        <div className="form-common input-wrap name-wrap">
          <label htmlFor="input_title"><span
            className="require">*</span> 제목</label>
          <input type="text" id="input_title" placeholder="제목을 국문은 40자, 영문은 80자 내로 입력해 주세요."
                 maxLength={80}
                 aria-invalid={errors.title ? "true" : "false"}
                 {...register("title", {
                    required: "제목을 입력해 주세요.",
                    minLength: {value: 2, message: "2자 이상 입력해 주세요."},
                    maxLength: {value: 80, message: "80자 이내로 입력해 주세요."},

                    onChange: e => {
                      let eng_check = /^[a-zA-z ]+$/;
                      if(eng_check.test(e.target.value)) {
                        if(e.target.value.length >= 80) {
                          e.target.value = e.target.value.substr(0, 80);
                        }
                      } else {
                        if(e.target.value.length >= 40) {
                          e.target.value = e.target.value.substr(0, 40);
                        }
                      }
                    }
                 })}/>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.title && <p className="error-message"
                                  role="alert">{errors.title.message}</p>}
            </div>
          }
        </div>

        <div className="form-common input-wrap lecture-information-wrap">
          <label htmlFor="input_information"><span
            className="require">*</span> 강의 소개</label>
          <textarea id="input_information"
                    aria-invalid={errors.description ? "true" : "false"}
                    placeholder="강의 소개 내용을 500자 내로 작성해 주세요."
                    maxLength={500}
                    {...register("description", {
                      required: "강의 소개 내용을 입력해 주세요.",
                      minLength: {value: 2, message: "2자 이상 입력해 주세요."},
                      maxLength: {value: 500, message: "500자 이내로 입력해 주세요."}
                    })}></textarea>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.description && <p className="error-message"
                                        role="alert">{errors.description.message}</p>}
            </div>
          }
        </div>
        <section className="upload-wrap">
          <div className="box-wrap">
          <h2>영상</h2>
          <div className="upload-box" >
            <div className="video-wrap">
              <h3><span className="require">*</span> 영상 업로드</h3>
              <div className="video-content">
                <div className="radio-wrap">
                  <input type="radio"
                          name='upload_type'
                          id='upload_by_file'
                          defaultChecked={true}
                          onChange={() => {
                            setValue('video.videoUploadType', 'file');
                            setValue('video.videoType', 'VIMEO');
                            setValue('video.isOutlink', false);
                            videoValidation();
                          }}
                          value="file"/>
                  <label htmlFor='upload_by_file' className='tooltip-label'>
                    <span>파일 선택</span>
                    <img src={iconTooltip} alt=""/>
                    <span className="tooltip-box">
                      <span className="tooltip-content">최대 권장 용량 : <b>1GB</b></span>
                      <span className="tooltip-content">최대 권장 시간 : <b>1시간</b></span>
                      <span className="tooltip-content">권장 화질 : <b>720P</b></span>
                    </span>
                  </label>
                  <div className="file-wrap">
                    <input type='file' id='video_upload'
                            accept='video/*'
                            {...register('video.video', {
                              required: {value: getValues('video.videoUploadType') === 'file',message: "영상을 업로드 해주세요."},
                              onChange: e => handleChange(e)
                            })}
                            disabled={getValues('video.videoUploadType') !== 'file'}/>
                    <label htmlFor={'video_upload'}>파일 선택</label>
                    {progress != null && <div style={{ padding: '10px 0', fontSize: '12px'}}>{progress} %</div>}
                  </div>
                </div>
                {watch().video.videoName !== '' && (
                  <div className="selected-video-wrap">
                    <p className="video-name">{watch().video.videoName}</p>
                    <span className="upload-complete">업로드 완료</span>
                    <button className="btn-delete-video" onClick={() => {
                      const newVideo = {
                        videoUploadType: getValues('video.videoUploadType'),
                        video: {},
                        embedUrl: '',
                        uploadLink: '',
                        videoLink: '',
                        videoKey: '',
                        length: 0,
                        videoDuration: 0,
                        isOutlink: false,
                        fileId: getValues('video.fileId'),
                        thumbnailUrl: getValues('video.thumbnailUrl'),
                        videoType: 'VIMEO',
                        videoName: '',
                      }
                      setValue('video', newVideo);
                      videoValidation();
                    }}></button>
                  </div>
                )}
              </div>
              <div className="video-content">
                <div className="radio-wrap">
                  <input type="radio" id='upload_by_url'
                          name='upload_type'
                          onChange={() => {
                            setValue('video.videoUploadType', 'url');
                            setValue('video.videoType', 'YOUTUBE');
                            setValue('video.isOutlink', true);
                          }}
                          value="url"/>
                  <label htmlFor={'upload_by_url'}>URL 입력</label>
                  <div className="input-wrap">
                    <input type="text"
                            placeholder="URL 등록"
                            onChange={(e) => {
                              setValue('video.embedUrl', e.target.value);
                              setValue('video.videoKey', getYoutubeId(e.target.value));
                              videoValidation();
                            }}
                            disabled={getValues('video.videoUploadType') !== 'url'}/>
                  </div>
                </div>
                {getValues('video.videoUploadType') !== 'file' &&
                  <div className="checkbox-wrap">
                    <input type="checkbox"
                            id='check_youtube'
                            disabled={getValues('video.videoUploadType') !== 'url'}
                            defaultChecked={true}
                            onChange={(e) => {
                              if(!e.target.checked) alert('해제할 경우 저작권법에 위배되어 불이익이 발생할 수 있습니다.')
                              setValue('video.isOutlink', e.target.checked);
                            }
                    }/>
                    <label htmlFor='check_youtube'>유튜브에서 보기</label>
                  </div>
                }
              </div>
            </div>
            <div className="thumbnail-wrap">
              <h3>썸네일 등록<span>(20MB 이하)</span></h3>
              <div className="thumbnail-content">
                <img src={watch().video.thumbnailUrl} alt=""/>
                <div className="sub-button-wrap">
                  <input type="file"
                          id='video_thumbnail'
                          className="input-thumbnail"
                          onChange={(e) => thumbnailUpload(e)}
                          accept=".jpg, .jpeg, .png"/>
                  <label htmlFor='video_thumbnail' className="btn-thumbnail">사진 {watch().video.thumbnailUrl !== defaultThumbnails ? '변경' : '선택'}</label>
                  {watch().video.thumbnailUrl !== defaultThumbnails &&
                    <button type="button"
                            className="btn-thumbnail-delete"
                            onClick={() => {
                              setValue('video.thumbnailUrl', defaultThumbnails);
                              setValue('video.fileId', null)
                            }}>삭제하기</button>
                  }
                </div>
              </div>
            </div>
          </div>
            <input type="hidden" value={"N"} {...register('videoCheck', {
              pattern: {value:/Y/, message: '영상을 업로드 해주세요.'},
            })}/>
          </div>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.videoCheck && <p className="error-message"
                                  role="alert">{errors.videoCheck.message}</p>}
            </div>
          }
          {/* {videoFields.length <= 10  &&
            <div className="sub-button-wrap">
              <button type="button"
                      className="btn-add"
                      onClick={() =>{
                        videoAppend({
                          videoUploadType: 'file',
                          video: {},
                          embedUrl: '',
                          uploadLink: '',
                          videoLink: '',
                          videoKey: '',
                          fileId: null,
                          videoDuration: 0,
                          thumbnailUrl: defaultThumbnails,
                          length: 0,
                          videoType: 'vimeo',
                          videoName: '',
                        });
                        setValue('videoCheck', "N");
                      }}>영상 추가</button>
            </div>
          } */}
        </section>

        <div className="form-common input-wrap tag-wrap">
          <label htmlFor="input_tag">
            <span className="require">*</span> 태그<br/>
            <span className='tip'>회원가입 시 등록한 태그가 기본으로 등록됩니다. 강의와 관련된 태그를 추가해주세요.</span>
          </label>
          <div className="input-multi-wrap">
            {tagField.map((tag, index) => (
              <input type="text" id="input_tag"
                     key={index}
                     placeholder="태그 입력"
                     {...register(`tags.${index}.name`, {
                       onChange: e => {
                         let eng_check = /^[a-zA-z ]+$/;
                         let kor_eng_check = /^^(?=.*[A-Za-z])(?=.*[ㄱ-ㅎ|가-힣])[ㄱ-ㅎ|가-힣|a-z|A-Z|]*/;
                         if(eng_check.test(e.target.value)) {
                           if(e.target.value.length >= 40) {
                             e.target.value = e.target.value.substr(0, 40);
                           }
                          } else if(kor_eng_check.test(e.target.value)) {
                            if(e.target.value.length >= 30) {
                              e.target.value = e.target.value.substr(0, 30);
                            }
                          } else {
                           if(e.target.value.length >= 20) {
                             e.target.value = e.target.value.substr(0, 20);
                           }
                         }
                         validateTags();
                       },
                     })}/>
            ))}
            <input type="hidden" {...register('tagCheck', {
              required: "태그를 입력해 주세요.",
              pattern: {value:/Y/, message:'태그를 입력해 주세요.'},
            })}/>
          </div>
          {Object.keys(errors).length > 0 &&
            <div className="error-wrap">
              {errors.tagCheck && <p className="error-message"
                                     role="alert">태그를 입력해 주세요.</p>}
            </div>
          }
          {tagField.length <= 9 &&
            <div className="sub-button-wrap">
              <button className="btn-add"
                      type="button"
                      onClick={() =>
                        tagAppend({
                          name: "",
                        })
                      }>추가
              </button>
            </div>
          }
        </div>

        <div className="form-common radio-container">
          <p className="label">공개상태</p>
          <div className="radio-wrap">
            <input type="radio" id="is_public" value="true"
                   defaultChecked={true} {...register("isPublic", {required: false})}/>
            <label htmlFor="is_public">공개</label>
          </div>
          <div className="radio-wrap">
            <input type="radio" id="is_privacy"
                   value="false" {...register("isPublic", {required: false})}/>
            <label htmlFor="is_privacy">비공개</label>
          </div>
        </div>
        <p className="tip">공개 : 강의가 모든 사용자에게 전체 공개됩니다.<br />비공개 : 강의를 전체 공개하지 않습니다. </p>

        <div className="form-common file-wrap">
          <div className="input-wrap">
            <p className="label">파일첨부</p>
            <input type="file" id="input_file" onChange={fileUpload} accept=".pdf, .jpg, .png, .zip"/>
            <label htmlFor="input_file">첨부하기</label>
          </div>
          <p className="tip">업로드 가능한 첨부파일 종류 : PDF, JPG, PNG, ZIP 파일<br/>용량 : 파일 1개 당, 20MB 이하</p>
          {files && (
            <ul className="file-list">
              {
                files.map((file: File) => (
                  <li key={file.id}>
                    <p className="file-name">{file.name}</p>
                    <button type="button" className="btn-delete-file"
                            name={file.name} onClick={deleteFile}></button>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </section>

      <section className="button-wrap">
        <button type="button" className="btn-cancel" onClick={backEvent}>뒤로가기</button>
        <button className="btn-submit" disabled={clickCheck} onClick={()=>setBtnClick(!btnClick)}>등록하기</button>
      </section>
    </form>
  )
}

export default CreateForm;
