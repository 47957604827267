import 'views/desktop/main/style/main-public-playlist.scoped.sass'
import { Link } from 'react-router-dom';
import {useEffect} from 'react';
import {getData} from 'api/playlist/public-playlist';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {getPublicPlaylist} from 'module/public-playlist';
import {CommonPlaylistProps} from 'utils/type';
import { setPopupMode, showDetailPopupF } from 'module/lecture-add-detail-popup';

const MainPublicPlaylist = () => {
  const dispatch = useAppDispatch();

  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);

  const getList = () => {
    let params = {
      page: 1,
      size: 5
    }
    getData(params).then((res: any) => {
      dispatch(getPublicPlaylist(res.data.content));
    })
  }

  const publicPlaylists:Array<CommonPlaylistProps> = useAppSelector((state: RootState) => state.publicPlaylist);

  useEffect(() => {
    getList();
  },[subChange, myChange])

  return (
    <article className="public-playlist">
      <div className="title-wrap">
        <h2>공유 플레이리스트</h2>
        {publicPlaylists.length >= 5 && <Link to="/playlist/public" className="btn-more">더보기 <b>+</b></Link>}
      </div>
      <div className="content-wrap">
        <ul className="public-playlist-card-list">
          {
            publicPlaylists.length > 0  && publicPlaylists.map(x => (
              <li key={x.id} className="playlist-item" onClick={() => {dispatch(showDetailPopupF({showDetailPopup: true, playlistId: x.id})); dispatch(setPopupMode('public'))}}>
                <p className="playlist-text"><b className="playlist-name">{x.title}</b> / {x.name} / {x.courseCount ? x.courseCount : 0}개</p>
                <p className="playlist-status">(현재 공유된 수 : {x.sharedCount}명)</p>
              </li>
          ))}
          {
            publicPlaylists.length === 0 && <li className="none-list">현재 공유되고 있는 플레이 리스트가 없습니다.</li>
          }
        </ul>
      </div>
    </article>
  );
};

export default MainPublicPlaylist;
