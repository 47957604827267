import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput, CFormSelect,
  CInputGroup, CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {masking} from 'utils/helper'
import {getUsersList} from "api/admin/user";
import ReactPaginate from "react-paginate";

type Professor = {
  id: number,
  email: string,
  name: string,
}

const AdminProfessorPage = () => {
  const [lists, setLists] = useState<Array<Professor>>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [isMasking, setIsMasking] = useState<boolean>(true);

  const [totalPage, setTotalPage] = useState<number>(1);
  const [detectChange, setDetectChange] = useState<boolean>(true);

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      masking: isMasking,
      role: 'PROFESSOR',
      page:  page.selected + 1,
      size: 10
    }
    getUsersList(params).then((res: any) => {
      setLists(res.data.content);
      setTotalPage(res.data.totalPages);
    })
  }

  useEffect(() => {
    getList({selected: 0});
  },[detectChange, isMasking])

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="professor">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">교수 회원관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <CButton type="button" color="dark" variant="outline" onClick={()=> setIsMasking(!isMasking)}>마스킹(*) 해제 조회</CButton>
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="이름 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">회원번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '35%'}}
                                    scope="col">이메일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '30%'}}
                                    scope="col">이름</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">정보 수정</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{isMasking ?  masking(item.email):item.email }</CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>
                      <Link to={`/admin/user/professor/edit/${item.id}`} className="btn btn-info text-white">수정</Link>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={4}>
                      등록된 교수 회원 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>

            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminProfessorPage
