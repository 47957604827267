import {showPopupF} from 'module/register-popup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';

const RegisterPopup = () => {
  const service_title = "서비스 이용약관 동의"
  const service_content = `
  <h3>제 1장 총칙</h3>
    <h4>제1조 (목적)</h4>
    <p>본 약관은 회원이 연세대학교 의과대학(이하 "연세의대")이 온라인으로 제공하는 온라인 교육 및 제반 서비스(이하 “서비스”)의 이용과 관련하여 연세의대와 회원간의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</p>
    <h4>제2조 (용어의 정의)</h4>
    <ul>
      <li>
        <p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
        <ul>
          <li>1. “연세의대”라 함은 온라인을 통하여 서비스를 제공하는 사업자를 의미합니다.</li>
          <li>2. “회원”이라 함은 본 약관에 동의하고 서비스 이용 자격을 부여받은 자를 의미합니다.</li>
          <li>3. “서비스”라 함은 연세의대가 온라인으로 제공하는 서비스를 의미합니다.</li>
          <li>4. “가입”이라 함은 사이트에서 제공하는 신청서 양식에 해당 정보를 기입하고, 본 약관에 동의하여 서비스 이용계약을 완료시키는 행위</li>
          <li>5. “계정(ID)”이라 함은 회원의 식별과 서비스 이용을 위하여 회원이 선정하고 연세의대가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.</li>
          <li>6. “계정정보“라 함은 회원의 계정, 비밀번호, 성명 등 회원이 연세의대에 제공한 일반정보 및 병원등록번호등을 통칭합니다.</li>
          <li>7. “비밀번호”라 함은 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의 정보 및 권익보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수문자의 조합을 의미합니다.</li>
          <li>8. “고유식별정보”라 함은 주민등록번호, 외국인등록번호를 의미합니다.</li>
          <li>9. “민감정보”라 함은 건강에 관한 정보를 의미합니다.</li>
          <li>10. “탈퇴(해지)”라 함은 회원이 이용계약을 종료시키는 행위</li>
        </ul>
      </li>
      <li>② 이 약관에서 사용하는 용어의 정의는 제1항 각호에서 정하는 것을 제외하고는 관계법령 및 기타 일반적인 상관례에 의합니다.</li>
    </ul>
    <h4>제3조 (정보의 제공)</h4>
    <p>연세의대는 다음 각 호의 사항을 홈페이지에 게시하여, 회원이 이를 쉽게 알 수 있도록 합니다. 다만, 개인정보처리방침과 약관은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</p>
    <ul>
      <li>① 상호 및 소재지 주소(회원의 불만을 처리할 수 있는 곳의 주소를 포함한다)</li>
      <li>② 전화번호</li>
      <li>③ 이용약관과 개인정보처리방침</li>
      <li>④ 환자권리장전과 의료정보윤리헌장</li>
    </ul>
    <h4>제4조 (서비스의 제공 및 변경)</h4>
    <ul>
      <li></li>
      <li>① "연세의대"는 그 변경될 서비스의 내용 및 제공일자를 제5조 제5항에서 정한 방법으로 이용자에게 통지하고, 제4조에서 정한 서비스를 변경하여 제공할 수 있습니다.</li>
      <li>② "연세의대"는 서비스 내용의 변경으로 인하여 이용자가 입은 손해에 대하여 배상하지 아니합니다. 단, 병원의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.</li>
    </ul>
    <h4>제5조 (약관의 명시와 개정)</h4>
    <ul>
      <li>① 연세의대는 이 약관의 내용을 회원이 알 수 있도록 사이트의 초기 서비스화면(전면)에 게시하거나 연결화면을 제공하는 방법으로 회원에게 공지합니다.</li>
      <li>② 연세의대는 회원이 연세의대와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.</li>
      <li>③ 연세의대는 서비스를 이용하고자 하는 자(이하 “이용자”라 한다)가 약관의 내용을 쉽게 알 수 있도록 작성하고 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 계약 해제ㆍ해지, 연세의대의 면책사항 및 회원에 대한 피해보상 등과 같은 중요한 내용을 회원이 쉽게 이해할 수 있도록 굵은 글씨 등으로 처리하거나 별도의 연결화면 또는 팝업화면 등을 제공하고 이용자의 동의를 얻도록 합니다.</li>
      <li>④ 연세의대는 「약관의 규제에 관한 법률」, 「개인정보보호법」 등 관련 법령에 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
      <li>⑤ 연세의대가 약관을 개정할 경우에는 적용일자 및 개정내용, 개정사유 등을 명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나 중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한 기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다.</li>
      <li>⑥ 연세의대는 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 개정약관 공지 시 회원이 동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는 내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를 표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있습니다.</li>
      <li>⑦ 회원이 개정약관의 적용에 동의하지 않는 경우 연세의대 또는 회원은 서비스 이용계약을 해지할 수 있습니다.</li>
    </ul>
    <h4>제6조 (약관 외 준칙)</h4>
    <p>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「약관의 규제에 관한 법률」, 「개인정보보호법」 등 관련 법령에 따릅니다.</p>
    <h3>제 2장 이용계약의 체결</h3>
    <h4>제7조 (이용신청 및 방법)</h4>
    <ul>
      <li>① 연세의대가 제공하는 서비스를 이용하고자 하는 자는 연세의대가 사이트의 초기 화면이나 서비스, 홈페이지에서 제공하는 이용신청서를 작성하는 방법으로 이용신청을 하여야 합니다.</li>
      <li>② 이용자는 이용신청 시 연세의대에서 요구하는 제반 정보를 제공하여야 합니다.</li>
      <li>③ 이용자는 제1항의 이용 신청 시 본인의 실명 및 실제 정보를 기재하여야 합니다. 실명 또는 식별정보를 허위로 기재하거나 타인의 명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고, 연세의대는 이용계약을 취소하거나 해지할 수 있습니다.</li>
      <li>④ 청소년(14세 미만의 자)이 이용신청을 할 경우에는 법정대리인의 동의를 얻어야 하고, 구체적인 동의절차는 관련법률 및 시행령에 따라 연세의대가 제공하는 방법에 따르도록 합니다.</li>
    </ul>
    <h4>제8조 (이용신청의 승낙과 제한)</h4>
    <ul>
      <li>① 연세의대는 연세의대가 이용자에게 요구하는 정보에 대해 이용자가 실명 및 실제 정보를 정확히 기재하여 이용신청을 한 경우에 상당한 이유가 없는 한 이용신청을 승낙합니다.</li>
      <li>
        <p>② 연세의대는 다음 각 호의 어느 하나에 해당하는 이용신청에 대해서는 승낙을 하지 않을 수 있습니다.</p>
        <ul>
          <li>1. 제7조에 위반하여 이용신청을 하는 경우</li>
          <li>2. 청소년(제7조 제4항의 청소년과 같다)이 법정대리인의 동의를 얻지 아니하거나 동의를 얻었음을 확인할 수 없는 경우</li>
          <li>3. 제3자의 신용카드, 유/무선 전화, 은행 계좌 등을 무단으로 이용 또는 도용하여 결제하는 경우</li>
          <li>4. 대한민국 이외의 국가 중 연세의대에서 아직 서비스를 제공할 것으로 결정하지 않은 국가에서 서비스를 이용하는 경우로 연세의대가 해외 서비스 업체와 체결한 계약이나 특정 국가에서 접속하는 회원에 대한 서비스 제공과 관련하여 서비스 제공을 제한할 필요가 있는 경우</li>
          <li>5. 「개인정보보호법」 및 그 밖의 관계 법령에서 금지하는 위법행위를 할 목적으로 이용신청을 하는 경우</li>
          <li>6. 그 밖에 1호 내지 5호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우</li>
        </ul>
      </li>
      <li>
        <p>③ 연세의대는 다음 각 호의 어느 하나에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.</p>
        <ul>
          <li>1. 연세의대의 설비에 여유가 없거나 기술적 장애가 있는 경우</li>
          <li>2. 서비스 상의 장애 또는 서비스의 결제수단의 장애가 발생한 경우</li>
          <li>3. 그 밖에 위 각 호에 준하는 사유로서 이용신청의 승낙이 곤란한 경우</li>
        </ul>
      </li>
    </ul>
    <h4>제9조 (회원 계정(ID) 및 비밀번호)</h4>
    <ul>
      <li>① 연세의대는 회원에 대하여 회원의 정보 보호, 서비스 이용안내 등의 편의를 위해 회원이 선정한 일정한 문자, 숫자 또는 특수 문자의 조합을 계정으로 부여합니다.</li>
      <li>② 연세의대는 계정정보를 통하여 당해 회원의 서비스 이용가능 여부 등의 제반 회원 관리업무를 수행합니다.</li>
      <li>③ 회원은 자신의 계정정보를 선량한 관리자로서의 주의 의무를 다하여 관리하여야 합니다. 회원이 본인의 계정정보를 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 회원에게 책임이 있습니다.</li>
      <li>④ 비밀번호의 관리책임은 회원에게 있으며, 회원이 원하는 경우에는 보안상의 이유 등으로 언제든지 변경이 가능합니다.</li>
      <li>⑤ 회원은 정기적으로 비밀번호를 변경하여야 합니다.</li>
    </ul>
    <h4>제10조 (회원 정보의 제공 및 변경)</h4>
    <ul>
      <li>① 회원은 이 약관에 의하여 연세의대에 정보를 제공하여야 하는 경우에는 진실된 정보를 제공하여야 하며, 허위정보 제공으로 인해 발생한 불이익에 대해서는 보호받지 못합니다.</li>
      <li>② 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 계정(ID) 등은 수정이 불가능합니다.</li>
      <li>③ 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 기타 방법으로 연세의대에 대하여 그 변경사항을 알려야 합니다.</li>
      <li>④ 제2항의 변경사항을 연세의대에 알리지 않아 발생한 불이익에 대하여 연세의대는 책임을 지지 않습니다.</li>
    </ul>
    <h4>제11조 (개인정보의 보호 및 관리)</h4>
    <ul>
      <li>① 연세의대는 관계 법령이 정하는 바에 따라 계정정보를 포함한 회원의 개인정보를 보호하기 위해 노력합니다. 회원 개인정보의 보호 및 사용에 대해서는 관계법령 및 연세의대가 별도로 고지하는 개인정보처리방침이 적용됩니다.</li>
      <li>② 서비스의 일부로 제공되는 개별 서비스를 제외한 것으로서 홈페이지 및 서비스 별 웹사이트에서 단순히 링크된 제3자 제공의 서비스에 대하여는 연세의대의 개인정보처리방침이 적용되지 않습니다.</li>
      <li>③ 연세의대는 회원의 귀책사유로 인하여 노출된 회원의 계정정보를 포함한 모든 정보에 대해서 일체의 책임을 지지 않습니다.</li>
    </ul>
    <h3>제3장 계약 당사자의 의무</h3>
    <h4>제12조 (연세의대의 의무)</h4>
    <ul>
      <li>① 연세의대는 관련 법령을 준수하고, 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 합니다.</li>
      <li>② 연세의대는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 연세의대는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.</li>
      <li>③ 연세의대는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 결함 및 장애 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.</li>
    </ul>
    <h4>제13조 (회원의 의무)</h4>
    <ul>
      <li>
        <p>① 회원은 다음 행위를 하여서는 안 됩니다.</p>
        <ul>
          <li>1. 신청 또는 변경 시 허위내용의 기재</li>
          <li>2. 타인의 정보도용</li>
          <li>3. 연세의대의 임직원, 운영자, 기타 관계자를 사칭하는 행위</li>
          <li>4. 연세의대가 게시한 정보의 변경</li>
          <li>5. 연세의대가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
          <li>6. 연세의대가 제공 또는 승인하지 아니한 컴퓨터 프로그램이나 기기 또는 장치를 제작, 배포, 이용, 광고하는 행위</li>
          <li>7. 연세의대와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
          <li>8. 연세의대 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</li>
          <li>9. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에 반하는 정보를 공개 또는 게시하는 행위</li>
          <li>10. 연세의대의 동의 없이 영리, 영업, 광고, 정치활동 등을 목적으로 서비스를 사용하는 행위</li>
          <li>11. 기타 관련 법령에서 금지하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위</li>
        </ul>
      </li>
      <li>② 회원은 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 연세의대가 통지하는 사항 등을 확인하고 준수할 의무가 있습니다.</li>
    </ul>
    <h3>제4장 서비스 이용</h3>
    <h4>제14조 (서비스의 변경 및 내용수정)</h4>
    <ul>
      <li>① 회원은 연세의대가 제공하는 서비스를 이 약관, 운영정책 및 연세의대가 설정한 규칙에 따라 이용할 수 있습니다.</li>
      <li>② 연세의대는 서비스를 통하여 회원에게 제공하는 내용의 제작, 변경, 유지, 보수에 관한 포괄적인 권한을 가집니다.</li>
      <li>③ 연세의대가 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 서비스 수정을 할 수 있으며, 서비스 수정을 하는 경우에는 변경 후 해당 사이트 등을 통하여 공지합니다.</li>
    </ul>
    <h4>제15조 (서비스의 제공 및 중단 등)</h4>
    <ul>
      <li>
        <p>① 연세의대의 서비스는 1일 24시간동안 제공하며, 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간 동안 서비스가 제공되지 아니할 수 있으며, 해당 시간 동안 연세의대는 서비스를 제공할 의무가 없습니다.</p>
        <ul>
          <li>1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 서비스의 수정을 위하여 필요한 경우</li>
          <li>2. 해킹 등의 전자적 침해사고, 통신사고, 미처 예상하지 못한 서비스의 불안정성에 대응하기 위하여 필요한 경우</li>
          <li>3. 천재지변, 비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가능할 경우</li>
        </ul>
      </li>
      <li>② 연세의대는 제1항 제1호의 경우, 일정 시간을 정하여 서비스를 중지할 수 있습니다. 이 경우 연세의대는 그 사실을 회원에게 초기 화면이나 홈페이지에 고지합니다.</li>
      <li>③ 제1항 제2호의 경우, 연세의대는 사전 고지 없이 서비스를 일시 중지할 수 있습니다. 연세의대는 이러한 경우 그 사실을 초기 화면이나 홈페이지에 사후 고지할 수 있습니다.</li>
      <li>④ 연세의대는 연세의대가 제공하는 서비스 이용과 관련하여 이용자에게 발생한 어떠한 손해에 대해서도 책임을 지지 않습니다. 다만, 연세의대의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.</li>
    </ul>
    <h4>제16조 (정보의 제공)</h4>
    <p>연세의대는 다음의 사항을 초기 화면이나 홈페이지에 회원이 알기 쉽게 표시합니다.</p>
    <ul>
      <li>① 상호, 주소, 연락처 등</li>
      <li>② 기타 연세의대가 필요하다고 인정하는 사항</li>
    </ul>
    <h4>제17조 (정보의 수집 등)</h4>
    <p>연세의대는 다음의 사항을 초기 화면이나 홈페이지에 회원이 알기 쉽게 표시합니다.</p>
    <ul>
      <li>① 연세의대는 서비스 내에서 회원의 서비스 이용을 연세의대가 필요하다고 판단하는 경우에 한하여 본 정보를 열람하도록 할 것이며, 본 정보는 연세의대만이 보유하고 법령으로 권한을 부여 받지 아니한 제3자는 절대로 열람할 수 없습니다.</li>
      <li>② 연세의대는 서비스 운영 및 프로그램 안정화 등 서비스 품질 개선을 위하여 회원 PC 등 단말기 설정 및 사양 정보를 수집•활용할 수 있습니다.</li>
    </ul>
    <h4>제18조 (저작권 등의 귀속)</h4>
    <ul>
      <li>① 서비스나 홈페이지 내 연세의대가 제작한 콘텐츠에 대한 저작권 기타 지적재산권은 연세의대의 소유입니다.</li>
      <li>② 회원은 연세의대가 제공하는 서비스를 이용함으로써 얻은 정보 중 연세의대 또는 제공업체에 지적재산권이 귀속된 정보를 연세의대 또는 제공업체의 사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.</li>
      <li>③ 연세의대는 회원이 게시하거나 등록하는 서비스 내의 게시물, 게시 내용에 대해 제 13조에서 규정하는 금지 행위에 해당된다고 판단되는 경우, 사전통지 없이 이를 삭제하거나 이동 또는 등록을 거부할 수 있습니다.</li>
      <li>④ 연세의대가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이 침해된 회원은 연세의대에게 당해 정보의 삭제 또는 반박 내용의 게재를 요청할 수 있습니다. 이 경우 연세의대는 신속하게 필요한 조치를 취하고, 이를 신청인에게 통지합니다.</li>
    </ul>
    <h3>제5장 계약 해제•해지 및 이용제한</h3>
    <h4>제19조 (회원의 해제 및 해지)</h4>
    <ul>
      <li>① 회원은 서비스 이용계약을 해지(이하 '회원탈퇴'라 한다)할 수 있습니다. 회원이 회원탈퇴를 신청한 경우 연세의대는 회원 본인 여부를 확인할 수 있으며, 해당 회원이 본인으로 확인되는 경우에 회원의 신청에 따른 조치를 취합니다.</li>
      <li>② 회원이 회원탈퇴를 원하는 경우에는 서비스 내 회원탈퇴 절차를 통하여 회원탈퇴를 할 수 있습니다.</li>
    </ul>
    <h4>제20조 (연세의대의 해제 및 해지)</h4>
    <ul>
      <li>① 연세의대는 회원이 이 약관에서 정한 회원의 의무를 위반한 경우에는 회원에 대한 사전 통보 후 계약을 해지할 수 있습니다. 다만, 회원이 현행법 위반 및 고의 또는 중대한 과실로 연세의대에 손해를 입힌 경우에는 사전 통보 없이 이용계약을 해지할 수 있습니다.</li>
      <li>
        <p>② 연세의대가 이용계약을 해지하는 경우 연세의대는 회원에게 서면, 전자우편 또는 이에 준하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다.</p>
        <ul>
          <li>1. 해지사유</li>
          <li>2. 해지일</li>
        </ul>
      </li>
    </ul>
    <h4>제21조 (회원에 대한 서비스 이용제한)</h4>
    <ul>
      <li>
        <p>① 연세의대는 회원에게 다음 각 호의 구분에 따라 회원의 서비스 이용을 제한할 수 있습니다.</p>
        <ul>
          <li>1. 회원 이용제한: 일정기간 또는 영구히 회원의 서비스 이용을 제한</li>
        </ul>
      </li>
      <li>② 연세의대의 이용제한이 정당한 경우에 연세의대는 이용제한으로 인하여 회원이 입은 손해를 배상하지 않습니다.</li>
    </ul>
    <h4>제22조 (잠정조치로서의 이용제한)</h4>
    <ul>
      <li>
        <p>① 연세의대는 다음 각 호에 해당하는 문제에 대한 조사가 완료될 때까지 계정을 정지할 수 있습니다.</p>
        <ul>
          <li>1. 계정이 해킹 또는 도용당하였다는 정당한 신고가 접수된 경우</li>
          <li>2. 불법프로그램 사용자 등 위법행위자로 합리적으로 의심되는 경우</li>
          <li>3. 그 밖에 위 각호에 준하는 사유로 계정의 잠정 조치가 필요한 경우</li>
        </ul>
      </li>
      <li>② 제1항의 경우 연세의대는 조사가 완료된 후 회원의 서비스 이용을 재개합니다 다만, 제1항에 의한 위법행위자로 판명된 경우에는 그러하지 아니합니다.</li>
    </ul>
    <h4>제23조 (이용제한의 사유와 절차)</h4>
    <ul>
      <li>① 연세의대는 위반행위의 내용, 정도, 횟수, 결과 등 제반 사정을 고려하여 이용제한이 이루어지는 구체적인 사유 및 절차를 운영정책으로 정합니다.</li>
      <li>
        <p>② 연세의대가 제22조에서 정한 이용제한을 하는 경우에는 회원에게 서면 또는 전자우편이나 초기 화면 또는 홈페이지에 게재하는 방법으로 다음 각 호의 사항을 회원에게 통보합니다.</p>
        <ul>
          <li>1. 이용제한 사유</li>
          <li>2. 이용제한 유형 및 기간</li>
          <li>3. 이용제한에 대한 이의신청 방법</li>
        </ul>
      </li>
    </ul>
    <h4>제24조 (이용제한에 대한 이의신청 절차)</h4>
    <ul>
      <li>① 회원이 연세의대의 이용제한에 불복하고자 할 때에는 통보를 받은 날로부터 15일 이내에 연세의대의 이용제한에 불복하는 이유를 기재한 이의신청서를 서면, 전자우편 또는 이에 준하는 방법으로 연세의대에 제출하여야 합니다.</li>
      <li>② 제1항의 이의신청서를 접수한 연세의대는 접수한 날로부터 15일 이내에 회원의 불복 이유에 대하여 서면, 전자우편 또는 이에 준하는 방법으로 답변하여야 합니다. 다만, 연세의대는 15일 이내에 답변이 곤란한 경우 회원에게 그 사유와 처리일정을 통보합니다.</li>
      <li>③ 연세의대는 위 답변 내용에 따라 상응하는 조치를 취하여야 합니다.</li>
    </ul>
    <h4>제25조 (양도금지)</h4>
    <p>회원이 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</p>
    <h3>제6장 손해배상</h3>
    <h4>제26조 (손해배상)</h4>
    <ul>
      <li>① 연세의대가 고의 또는 중과실로 회원에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.</li>
      <li>② 회원이 본 약관을 위반하여 연세의대에 손해를 끼친 경우, 회원은 연세의대에 대하여 그 손해에 대하여 배상할 책임이 있습니다.</li>
    </ul>
    <h4>제27조 (연세의대의 면책)</h4>
    <ul>
      <li>① 연세의대는 전시, 사변, 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 기술적 결함 기타 불가항력적 사유로 서비스를 제공할 수 없는 경우에는 책임이 면제됩니다.</li>
      <li>② 연세의대는 회원의 귀책사유로 인한 서비스의 중지, 이용장애 및 계약해지에 대하여 책임이 면제됩니다.</li>
      <li>③ 연세의대는 기간통신 사업자가 전기통신서비스를 중지하거나 정상적으로 제공하지 아니하여 회원에게 손해가 발생한 경우에 대해서 연세의대의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
      <li>④ 연세의대는 사전에 공지된 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 서비스가 중지되거나 장애가 발생한 경우에 대해서 연세의대의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
      <li>⑤ 연세의대는 회원의 컴퓨터 환경으로 인하여 발생하는 제반 문제 또는 연세의대의 고의 또는 중대한 과실이 없는 네트워크 환경으로 인하여 발생하는 문제에 대해서 책임이 면제됩니다.</li>
      <li>⑥ 연세의대는 회원 또는 제3자가 서비스 내 또는 웹사이트 상에 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해서는 연세의대의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
      <li>⑦ 연세의대는 회원 상호간 또는 회원과 제3자간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</li>
      <li>⑧ 연세의대가 제공하는 서비스 중 무료서비스의 경우에는 연세의대의 고의 또는 중대한 과실이 없는 한 연세의대는 손해배상을 하지 않습니다.</li>
      <li>⑨ 연세의대는 회원의 컴퓨터 오류에 의한 손해가 발생한 경우 또는 신상정보 및 전자우편주소를 부정확하게 기재하거나 미기재하여 손해가 발생한 경우에 대하여 연세의대의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.</li>
    </ul>
    <h4>제28조 (회원의 고충처리 및 분쟁해결)</h4>
    <ul>
      <li>① 연세의대는 회원의 편의를 고려하여 회원의 의견이나 불만을 제시하는 방법을 초기화면이나 홈페이지에서 안내합니다. 연세의대는 이러한 회원의 의견이나 불만을 처리하기 위한 전담조직을 운영합니다.</li>
      <li>② 연세의대는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 이를 신속하게 처리합니다. 다만, 처리에 장기간이 소요되는 경우에는 회원에게 장기간이 소요되는 사유와 처리일정을 홈페이지에 공지하거나 전자우편, 전화 또는 서면 등으로 통보합니다.</li>
      <li>③ 연세의대와 회원간에 분쟁이 발생하여 제3의 분쟁조정기관이 조정할 경우 연세의대는 이용제한 등 회원에게 조치한 사항을 성실히 증명하고, 조정기관의 조정에 따를 수 있습니다.</li>
    </ul>
    <h4>제29조 (회원에 대한 통지)</h4>
    <ul>
      <li>① 연세의대가 회원에게 통지를 하는 경우 회원이 지정한 전자우편주소, 휴대폰 문자메시지 등으로 할 수 있습니다.</li>
      <li>② 연세의대는 회원 전체에게 통지를 하는 경우 7일 이상 연세의대의 초기화면에 게시하거나 공지사항등을 제시함으로써 제1항의 통지에 갈음할 수 있습니다.</li>
    </ul>
    <h4>제30조 (재판권 및 준거법)</h4>
    <p>본 약관은 대한민국 법률에 따라 규율되고 해석되며, 연세의대와 회원간에 발생한 분쟁으로 소송이 제기되는 경우, 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.</p>
    <h4>[부칙]</h4>
    <p>① (시행일) 본 약관은 2023년 3월 2일부터 시행됩니다.</p>
  `

  const privacy_title = "개인정보 수집/이용 동의"
  const privacy_content = `
    <h3>개인정보의 수집 및 이용목적</h3>
    <p>연세동곡의학교육원 교육플랫폼 SEViT (세빛) (이하 “세빛”)은 모든 정보주체의 개인정보를 매우 소중하게 생각하며 개인정보를 가장 효과적이고 안전하게 보호하기 위하여 최선의 노력을 다하고 있습니다. 세빛은 의료법, 개인정보 보호법 등 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하고 있습니다.</p>
    <p>의료원 및 산하기관(연세동곡의학교육원, 의과대학 등)의 홈페이지는 통합회원제로 운영되고 있으며, 한 번의 로그인으로 사이트 이용이 가능합니다(2023 상반기 예정).</p>
    <h3>제1조 (처리하는 개인정보의 항목)</h3>
    <p>세빛은 교육 및 연구를 위해 필요한 최소한의 개인정보만을 수집합니다.</p>
    <h4>&lt;홈페이지 관련 수집항목&gt;</h4>
    <h5>① 회원가입 처리항목</h5>
    <ul>
      <li>
        <h5>1) 교수</h5>
        <ul>
          <li>- 필수항목 : 이름, 이메일, 비밀번호, 과/교실, 생년월일, 태그</li>
          <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
        </ul>
      </li>
      <li>
        <h5>2) 학생</h5>
        <ul>
          <li>- 필수항목 : 이름, 이메일, 비밀번호, 학번, 학년, 생년월일, 태그</li>
          <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
        </ul>
      </li>
      <li>
        <h5>3) 원내 직원</h5>
        <ul>
          <li>- 필수항목 : 이름, 이메일, 비밀번호, 소속, 직번, 생년월일, 태그</li>
          <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
        </ul>
      </li>
       <li>
        <h5>4) 파견 직원</h5>
        <ul>
          <li>- 필수항목 : 이름, 이메일, 비밀번호, 소속, 생년월일, 태그</li>
          <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
        </ul>
      </li>
       <li>
        <h5>5) 외부소속 가입자</h5>
        <ul>
          <li>- 필수항목 : 이름, 이메일, 비밀번호, 소속, 생년월일, 태그</li>
          <li>- 선택항목 : 프로필 사진, 대체 이메일, 전화번호</li>
        </ul>
      </li>
    </ul>
    <br/>
    <h5>② 강의등록 처리항목</h5>
    <ul>
      <li>
        <ul>
          <li>- 필수항목 : 제목, 강의 소개, 영상, 태그</li>
          <li>- 선택항목 : 썸네일, 공개상태, 파일첨부</li>
        </ul>
      </li>
    </ul>
    <br/>
    <h3>제2조 (개인정보의 처리 목적)</h3>
    <p>사용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다.</p>
    <ul>
      <li>-	강의 관리 : 강의 제공자의 기본 정보 확인 등</li>
      <li>- 강의 수강관리 : 강의 수강 관리를 위한 본인 확인 및 수강 명단 열람 등</li>
    </ul>
    <br/>
    <h3>제3조 (개인정보의 처리 및 보유기간에 관한 사항)</h3>
    <p>세빛은 위 개인정보의 처리목적이 달성되거나 사용자가 개인정보 처리 동의 철회의사를 표시한 후 5일 이내까지 사용자의 개인정보를 처리 및 보유합니다. 다만, 다음을 포함하여 관련 법령에서 보유기간을 정한 경우 해당 법령에 따라 보유합니다.</p>
    <ul>
      <li>-	신용정보의 수집/처리 및 이용 등에 관한 정보: 3년 (신용정보의 이용 및 보호에 관한 법률)</li>
      <li>- 홈페이지 회원정보의 경우 회원탈퇴 시까지 보유하고 해지 후에는 DB에서 삭제하고 보유하지 않습니다. 홈페이지 회원정보의 경우 회원탈퇴 시 탈퇴여부를 확인하기 위해 ID만을 탈퇴사유와 탈퇴일과 함께 1년을 보유하고 1년 후 지체없이 파기를 진행하며, 그 이외의 정보는 DB에서 삭제하고 보유하지 않습니다. 또한, 1년 이상 사용 이력이 없는 회원은 암호화하여 기존 회원과 별도로 1년을 보유하며 그 후 지체없이 파기를 진행합니다. 개인정보가 제3자에게 제공된 경우에는 제3자에게 파기하도록 지시합니다. 단, 다음 각 호의 경우에는 각 호에 명시한 기간 동안 개인정보를 보유합니다.</li>
      <li>① 보유기간을 회원에게 미리 고지하고 그 보유기간이 경과하지 아니한 경우와 개별적으로 회원의 동의를 받을 경우에 약속한 보유 기간 동안 개인정보를 보유합니다.</li>
      <li>② 수집목적 또는 제공받은 목적이 달성된 경우에도 상법 등 법령의 규정에 의해 보존할 필요성이 있는 경우에 개인정보를 보유합니다.</li>
    </ul>
    <br/>
    <h3>제4조 (개인정보의 제3자 제공에 관한 사항)</h3><br/>
    ① 세빛은 개인정보를 제2조(개인정보의 처리 목적)에서 고지한 범위 내에서 사용하며, 동 범위를 넘어서 이용하거나 타인 또는 타기업, 기관에 제공하지 않습니다. 다만, 정보주체의 동의가 있거나 다음과 같은 사례에 해당하는 경우에는 예외로 합니다.<br/>
    1) 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 가공하여 연구단체, 설문조사, 리서치기관 등 제 3자에게 제공하는 경우<br/>
  	2) 개인정보 보호법, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 의료법, 의료급여법, 국민건강보험법, 통계법, 소비자보호법, 형사소송법, 민사소송법 등 법률에 특별한 규정이 있는 경우<br/>
    3) 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우('법률에 특별한 규정이 있는 경우'로 행정목적이나 수사목적으로 행정관청이나 수사기관이 요구할 경우라도 법률에 규정된 바에 따라 영장 또는 수사 기관장의 직인이 날인된 서면에 의한 경우 등 적법한 절차에 따라 제공합니다)<br/>
    4) 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우<br/>
    ② 개인정보를 동의 받은 목적 외의 용도로 제공할 경우 사전에 전화 및 서면 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 정보주체가 동의하지 않는 경우에는 제공단체 및 기관에 제공하지 않습니다.
    ③ 정보주체는 개인정보의 제3자 제공에 대하여 동의를 하지 않거나 언제든지 철회할 수 있습니다.<br/><br/>
    <h3>제5조 (개인정보처리의 위탁에 관한 사항)</h3><br/>
    세빛은 개인정보 처리 등 특정 업무를 [별첨1]과 같이 위탁하고 있으며, 관련 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 공개하고 있습니다. 위탁기관의 개인정보 보유 및 이용기간은 위탁계약 종료 시까지 이며, 다만 관련 법령에서 보유기간을 정한 경우 해당 법령에 따릅니다. 위탁하는 업무의 내용과 수탁자는 [별첨1]과 같습니다.<br/><br/>
    <h3>제6조 (정보주체 및 법정대리인의 권리⋅의무 및 그 행사방법에 관한 사항)</h3><br/>
    ① 사용자의 개인정보에 대한 열람, 정정 및 삭제 요구 시 성실하게 응대하며, 해당 개인정보에 오류가 있다고 판명되는 등 정정/삭제의 필요가 있다고 인정되는 경우에는 지체 없이 처리합니다.<br/>
    [개인정보의 열람]<br/>
    홈페이지(https://yumc.ac/)에 로그인하여 회원정보의 열람이 가능합니다.<br/>
    [개인정보의 정정/삭제]<br/>
    홈페이지 (https://yumc.ac/)에 로그인 하신 후 「회원정보수정」에서 개인정보 수정이 가능합니다. 삭제를 원하실 경우 「회원탈퇴」를 하시면 회원정보가 삭제됩니다.<br/>
    ② 사용자의 개인정보에 대한 열람, 정정 및 삭제를 요구하는 경우, 사용자의 신분을 나타내는 신분증(주민등록증, 여권, 운전면허증 그 밖에 공공기관에서 발행한 본인임을 확인할 수 있는 신분증)을 제시받아 본인 여부를 확인합니다.<br/>
    ③ 대리인이 방문하여 열람 · 증명을 요구하는 경우에는 대리관계를 나타내는 위임장 및 동의서와 본인의 신분증 사본, 대리인의 신분증 등을 확인하여 진정한 대리인인지 여부를 확인합니다.<br/>
    ④ 세빛은 개인정보의 전부 또는 일부에 대하여 열람 · 증명 또는 정정을 거절할 정당한 이유가 있는 경우에는 사용자에게 이를 통지하고 그 이유를 설명합니다.<br/>
    <br/>
    <h3>제7조 (개인정보 자동 수집장치의 설치, 운영 및 그 거부에 관한 사항)</h3><br/>
    1.	세빛은 보다 적절하고 유용한 서비스를 제공하기 위하여 사용자의 정보를 수시로 저장하고 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키란 세빛 웹사이트를 운영하는데 이용되는 서버가 사용자의 컴퓨터로 전송하는 아주 작은 텍스트 파일로서 사용자의 컴퓨터 하드디스크에 저장됩니다.  사용자는 쿠키의 사용여부에 대하여 선택하실 수 있습니다. 단, 쿠키를 사용하지 않아 생기는 서비스 사용의 문제 및 제한은 세빛이 책임지지 않습니다. <br/><br/>

    2.	쿠키 설정 거부 방법<br/>
    사용자는 사용하시는 웹 브라우저의 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 단, 쿠키의 저장을 거부할 경우 로그인이 필요한 일부 서비스의 이용에 제한이 생길 수 있음을 양지하시기 바랍니다. <br/>
    - 쿠키의 사용목적<br/>
    이용자들이 방문한 의료원의 웹 사이트들에 대한 방문 및 이용형태, 보안접속 여부 등을 파악하여 최적화된 맞춤형 서비스를 제공하기 위해 사용하며, 이 외의 목적으로 이용하지 않습니다.<br/>
    - 쿠키 설정 거부 방법<br/>
    사용자는 쿠키 설정에 대한 선택권을 가지고 있으며 아래와 같은 방법으로 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
    설정방법의 예 (Chrome 기준): <br/>
    웹 브라우저 우측의 “설정>화면하단의 고급 설정>개인정보의 콘텐츠 설정 버튼>쿠키”<br/>
    단, 사용자가 쿠키 설정을 거부하였을 경우 일부 서비스 이용에 어려움이 있을 수 있습니다.<br/><br/>
    <h3>제8조 (동의철회 및 개인정보 파기에 관한 사항)</h3><br/>
    ① 사용자는 개인정보의 수집⋅이용 및 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 철회방법은 개인정보보호책임자 또는 관리부서에 서면, 전화 등으로 연락하시면 본인확인을 거쳐 개인정보 동의철회 등 필요한 조치를 합니다.<br/>
    ② 세빛은 수집된 사용자의 개인정보를 제3조의 “개인정보의 처리 및 보유기간”동안 보유합니다. 세빛은 보유기간의 경과, 처리 목적 달성 등 그 개인정보가 불필요하게 되었을 때에는 정당한 사유가 없는 한 개인정보가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기하고, 개인정보가 제3자에게 제공된 경우에는 제3자에게 파기하도록 지시합니다.<br/>
    ③ 세빛은 개인정보를 파기할 때에는, 전자적 파일 형태인 경우 복원이 불가능한 방법으로 영구삭제하고, 기타 서면 등 기록매체인 경우 파쇄 또는 소각합니다.<br/>
    <br/>
    <h3>제9조 (개인정보보호책임자에 관한 사항)</h3><br/>
    세빛은 개인정보를 보호하기 위해 최선을 다해 노력하기 위하여 개인정보보호책임자를 두고 있습니다. 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호책임자 또는 관리부서에 통지해 주십시오. 지적하신 문제를 확인하여 즉시 시정할 수 있도록 최선을 다하겠습니다.<br/><br/>

    개인정보보호책임자<br/>
    •	이름 : 임준석<br/>
    •	소속 : 연세대학교 의료원 디지털헬스실<br/>
    •	직위 : 디지털헬스실장<br/>
    •	전화 : 02-2228-1100<br/>
    •	이메일 : privacy@yuhs.ac<br/><br/>
    <h3>제10조 (개인정보의 안전성 확보조치에 관한 사항)</h3><br/>
    세빛은 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적⋅관리적 대책을 강구하고 있습니다.<br/>
    ① 기술적⋅물리적 대책<br/>
    1) 개인정보는 비밀번호에 의해 보호되며, 개인정보 파일을 안전하게 저장 및 전송할 수 있도록 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.<br/>
    2) 백신 프로그램 등 보안프로그램을 설치하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 자동으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.<br/>
    3) 해킹 등에 의해 개인정보가 유출되는 것을 방지하기 위하여, 외부로부터 접근이 통제된 구역에 시스템을 설치하고, 침입을 차단하는 장치를 이용하고 있으며, 아울러 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다.<br/>
    4) 개인정보처리자가 정보통신망을 통해 외부에서 개인정보처리시스템에 접속하려는 경우에는 가상사설망(VPN) 또는 전용선 등 안전한 접속수단을 적용하고 있습니다.<br/>
    ② 관리적 대책<br/>
    1) 세빛은 개인정보의 안전한 처리를 위한 내부 관리계획 및 개인정보에 대한 관리와 접근에 필요한 절차를 마련하여 교직원이 이를 숙지하고 준수하도록 하고 있으며 준수 여부를 주기적으로 점검하고 있습니다.
    2) 세빛은 정보를 처리할 수 있는 자를 최소한으로 제한하고 접근 권한을 관리하며, 교육을 통하여 법규 및 정책을 준수할 수 있도록 합니다. 개인정보를 처리하는 자는 다음과 같습니다.<br/>
    - 정보주체를 직⋅간접적으로 상대하여 업무를 처리하는 자<br/>
    - 개인정보보호책임자 및 개인정보처리담당자 등 개인정보 관리 및 개인정보보호 업무를 담당하는 자<br/>
    - 기타 업무상 개인정보의 접근이 불가피한 자<br/>
    3) 세빛은 개인정보 침해사고 발생에 대응하기 위한 접속기록의 보관 및 위조⋅변조 방지를 위한 조치를 시행합니다.<br/>
    4) 세빛에서 관리하는 개인정보의 처리를 다른 공공기관 또는 다른 전문기관에 위탁하는 경우, 그 업무에 종사하거나 종사하였던 자에 대해 직무상 알게 된 개인정보를 누설 또는 권한 없이 처리하거나 타인의 이용에 제공하는 등 부당한 목적을 위하여 사용하지 않도록 위탁부서에서 아래에 대한 사항에 관하여 필요한 제한이나 절차를 서면으로 정하고 수탁기관으로 하여금 준수하도록 하고 있으며, 실태점검도 실시하고 있습니다.<br/>
    - 위탁업무 수행 목적 외 개인정보의 처리 금지에 관한 사항<br/>
    - 개인정보의 기술적⋅관리적 보호조치에 관한 사항<br/>
    - 위탁업무의 목적 및 범위<br/>
    - 재위탁 제한에 관한 사항<br/>
    - 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항<br/>
    - 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독에 관한 사항<br/>
    - 수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 등 책임에 관한 사항<br/><br/>
    <h3>제11조 (정보주체의 권익침해에 대한 구제방법)</h3><br/>
    세빛의 개인정보보호책임자 또는 관리부서에 대한 신고, 상담 외에, 개인정보침해에 대한 신고, 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.<br/>
    한국인터넷진흥원(개인정보침해신고센터)<br/>
  	- 홈페이지 : http://privacy.kisa.or.kr<br/>
    - 전화번호 : (국번없이) 118<br/>
    경찰청 사이버안전국<br/>
    - 홈페이지 : http://ecrm.police.go.kr<br/>
    - 전화번호 : (국번없이) 182<br/>
    대검찰청 사이버 수사과<br/>
    - 홈페이지 : http://www.spo.go.kr<br/>
    - 전화번호 : (국번없이) 1301<br/>
    개인정보 분쟁조정위원회(개인정보보호위원회 운영)<br/>
    - 홈페이지 : http://www.kopico.go.kr<br/>
    - 전화번호 : 1833-6972<br/>
    <h3>제12조 (개인정보처리방침의 변경에 관한 사항)</h3><br/>
    현 개인정보처리방침은 정부의 정책 또는 보안기술의 변경 등에 따라 수시로 변경될 수 있으며, 내용의 추가, 삭제 및 수정이 있을 경우 개정 최소 10일 전부터 홈페이지 '공지' 란 및 기타 정보주체가 쉽게 확인 가능한 장소에 공지를 통해 고지할 것입니다.<br/>
    - 공고일자 : 2023년 3월 17일<br/>
    - 시행일자 : 2023년 3월 17일<br/><br/>

    [별첨1]개인정보의 처리 위탁에 관한 사항<br/><br/>

    <table style="text-align: center">
      <tr>
        <td style="border: 1px solid #000; padding: 10px"><b>수탁자</b></td>
        <td style="border: 1px solid #000; padding: 10px"><b>위탁하는 업무의 내용</b></td>
        <td style="border: 1px solid #000; padding: 10px"><b>개인정보 보유기간</b></td>
      </tr>
      <tr>
        <td style="border: 1px solid #000; padding: 10px">㈜온오프믹스</td>
        <td style="border: 1px solid #000; padding: 10px">교육 플랫폼 홈페이지 개발 및 유지보수</td>
        <td style="border: 1px solid #000; padding: 10px">위탁계약 종료시까지</td>
      </tr>
    </table>
  `

  const dispatch = useAppDispatch();
  const showType:string = useAppSelector((state: RootState) => state.registerShowPopup.showType);

  return (
    <div className="popup-container">
      <div className="popup">
        <div className="title-wrap">
          <h2>{showType === 'service' ? service_title : privacy_title}</h2>
          <button className="close" onClick={() => dispatch(showPopupF({showPopup: false, showType: ''}))}></button>
        </div>
        <div className="content" dangerouslySetInnerHTML={{__html: showType === 'service' ? service_content : privacy_content}}></div>
      </div>
    </div>
  )
}

export default RegisterPopup
