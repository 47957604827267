import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LectureProps} from 'utils/type';

const initialState = {
  lectureChange: false,
  lectureSearchKeyword: '',
  lectureList: [],
  lectureLength: 0,
}

type lecture = {
  lectureChange: boolean,
  lectureSearchKeyword: string | null
  lectureList: Array<LectureProps>,
  lectureLength: number
}

const getLectureList = createSlice({
  name: 'getLectureList',
  initialState: initialState,
  reducers: {
    getLecture(state: lecture, action: PayloadAction<Array<LectureProps>>) {
      state.lectureList = action.payload;
      state.lectureLength = state.lectureList.length
    },
    ChangeLecture(state: lecture, action: PayloadAction<boolean>) {
      state.lectureChange = action.payload;
    },
    setLectureSearchKeyword(state: lecture, action: PayloadAction<string | null>) {
      state.lectureSearchKeyword = action.payload;
    }
  }
});

export const { getLecture, ChangeLecture, setLectureSearchKeyword } = getLectureList.actions;
export default getLectureList.reducer;
