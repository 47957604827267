import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {isLogin} from 'utils/auth'

const PrivateRoute = ({
  redirectPath = '/',
  children,
}:any) => {
  const isLoginCheck:boolean = isLogin();

  if(!isLoginCheck) {
      alert('로그인이 필요한 페이지입니다.');
      return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
