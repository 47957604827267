import { Outlet } from 'react-router-dom';
import AdminHeader from 'views/admin/layout/AdminHeader';
import AdminNavigation from 'views/admin/layout/AdminNavigation';

import '@coreui/coreui/dist/css/coreui.min.css'
import 'views/admin/admin.sass'

import {useState} from 'react';
import {CContainer} from '@coreui/react';

const AdminPage = () => {
  const [show, setShow] = useState<boolean>(true);

  const setShowParent = (x: any) => {
    setShow(x);
  }

  return (
    <div className="admin">
      <AdminNavigation show={show}/>
      <div className="wrapper d-flex flex-column min-vh-100">
        <AdminHeader setShowParent={setShowParent}/>
        <div className="body flex-grow-1 px-3">
          <CContainer fluid className="container-fluid">
            <Outlet></Outlet>
          </CContainer>
        </div>
      </div>
    </div>
  )
}

export default AdminPage;
