import {useEffect, useState} from 'react';
import {CommonPlaylistProps} from 'utils/type';
import 'views/desktop/lecture/style/subscribe-playlist.sass'
import CommonPlaylist from 'views/desktop/common/playlist/CommonPlaylist';
import {getSubscribePlayListData} from 'api/playlist/playlist';
import {getSubPlaylist} from 'module/sub-playlist';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import PlayListDetailPopup from 'views/desktop/common/popup/PlayListDetailPopup';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';


const LectureSubscribePlaylistPage = () => {
  const dispatch = useAppDispatch();

  const [keyword, setKeyword] = useState<string>('');

  const [totalPage, setTotalPage] = useState<number>(1);

  const [isButtonClick, setIsButtonClick] = useState<boolean>(false);

  const subPlaylist:Array<CommonPlaylistProps> = useAppSelector((state: RootState) => state.subPlaylist.subPlaylist);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
      sort: 'date',
    }
    getSubscribePlayListData(params).then((res: any) => {
      dispatch(getSubPlaylist(res.data.content));
      setTotalPage(res.data.totalPages)
    })
  }

  useEffect(() => {
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
    getList({selected: 0});
  }, [isButtonClick, subChange]);

  return (
    <div className="lecture-subscribe-playlist">
      <div className="search-wrap">
        <h2>구독 플레이리스트 검색</h2>
        <div className="input-wrap">
          <input type="text" placeholder="검색어를 입력해 주세요." onChange={(e) => setKeyword(e.target.value)} onKeyPress={(e) => {if(e.key == 'Enter') setIsButtonClick(!isButtonClick);}}/>
          <button className="ir_pm" onClick={() => {
              setIsButtonClick(!isButtonClick);
            }}>검색</button>
        </div>
      </div>
      <div className="content-wrap">
        <div className="u-container">
          <div className="handler-wrap">
            <h2>구독 플레이리스트</h2>
            <div className="button-wrap">
              <Link to="/playlist/public" className="btn-add">추가</Link>
            </div>
          </div>
          <CommonPlaylist playlists={subPlaylist}/>
          {
            subPlaylist.length === 0 && <p className="list-none">구독한 플레이 리스트가 없습니다.</p>
          }
        </div>
        {totalPage >= 1 &&
          <ReactPaginate breakLabel="..."
                          containerClassName="user-paginate"
                          nextLabel="다음"
                          onPageChange={getList}
                          pageCount={totalPage}
                          pageClassName="user-paginate-item"
                          previousLabel="이전"/>
        }
      </div>
      {showDetailPopup && <PlayListDetailPopup/>}
    </div>
  )
}

export default LectureSubscribePlaylistPage;
