import {Link} from 'react-router-dom';

import 'views/desktop/notice/style/notice-detail.sass'
import {useEffect, useState} from 'react';
import {noticeDetail} from 'api/notice/notice';
import {useParams} from 'react-router';

type fileData = {
  name: string,
  url: string
}

type Data= {
  id: number,
  title: string,
  content: string,
  file: fileData | null,
  createdAt: Array<number>,
}

const NoticeDetail = () => {
  const noticeId = Number(useParams().id);

  const [data, setData] = useState<Data>({
    id: 0,
    title: '',
    content: '',
    file: null,
    createdAt: [],
  });

  useEffect(() => {

    noticeDetail(noticeId).then((res: any) => {
      setData(res.data);
    })
  }, [noticeId])

  return (
    <div className="notice-detail">
      <div className="u-container">
        <div className="title-wrap">
          <h1>공지사항</h1>
        </div>
        <div className="content-wrap">
          <div className="content-title-wrap">
            <p className="notice-title">{data.title}</p>
            <p className="notice-date">{data.createdAt[0]}.{data.createdAt[1]?.toString().padStart(2,'0')}.{data.createdAt[2]?.toString().padStart(2,'0')}</p>
          </div>
          <div className="notice-text">
            <pre>
              {data.content}
            </pre>
            {data.file != null && <a href={data.file.url} className="notice-file" download={`${data.file.name}`} target="_blank">{data.file.name}</a>}
          </div>
          <div className="button-wrap">
            <Link to="/notice" className="btn-back">목록</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetail;
