import {useEffect, useState} from 'react';
// utils
import {LectureStatusProps} from 'utils/type';
//style
import 'views/mobile/lecture-management/style/m-lecture-status.sass'
import {getStatusList} from 'api/lecture/management';
import {Navigate, useNavigate, useParams} from 'react-router';
import ReactPaginate from "react-paginate";

const MLectureStatusPage = () => {
  const navigate = useNavigate();
  const courseId = Number(useParams().id);
  const getParams = new URLSearchParams(window.location.search);
  const [lectureStatusList, setLectureStatusList] = useState<Array<LectureStatusProps>>([]);
  const [masking, setMasking] = useState<boolean>(true);
  const [change, setChange] = useState<boolean>(true);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [sort, setSort] = useState<string>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sortType, setSortType] = useState<string>("");
  const [sortNumberAsc, setSortNumberAsc] = useState<boolean>(false);
  const [sortNameAsc, setSortNameAsc] = useState<boolean>(false);
  const [sortDateAsc, setSortDateAsc] = useState<boolean>(false);

  const getList = (page: any) => {
    let params = {
      masking: masking,
      start: start,
      end: end,
      page: page.selected + 1,
      size: 10,
      sort: sort,
    }
    getStatusList(courseId,params).then((res: any) => {
      setLectureStatusList(res.data.content);
      setTotalElements(res.data.totalElements);
      setTotalPage(res.data.totalPages)
    }).catch((err: any)=>{
      alert(err.response.data.message)
      navigate(-1)
    })
  }

  useEffect(() => {
      getList({selected: 0})
  }, [ masking, change, sort]);

  const studentYearChange = (studentYear: number | null) => {
    switch(studentYear) {
      case 0 : return "";
      case 1 : return "예과 1학년";
      case 2 : return "예과 2학년";
      case 3 : return "본과 1학년";
      case 4 : return "본과 2학년";
      case 5 : return "본과 3학년";
      case 6 : return "본과 4학년";
      case 7 : return "휴학";
      default : return "";
    }
  }

  return (
    <div className="m-lecture-status">
      <div className="m-container">
        <div className="title-wrap">
          <span className="lecture-category">{getParams.get('category') ?? ''}</span>
          <h1>{getParams.get('title') ?? ''}</h1>
        </div>
      </div>
      <div className="search-wrap">
        <div className="m-container">
          <p>시청기간 조회</p>
          <div className="input-wrap">
            <input type="date" onChange={(e) => setStart(e.target.value)}/>
            <span>~</span>
            <input type="date" onChange={(e) => setEnd(e.target.value)}/>
            <div className="button-wrap">
              <button type="button" className="btn-search" onClick={() => setChange(!change)}>조회</button>
              <button type="button" className="btn-year" onClick={() => {
                setStart((new Date().getFullYear()).toString() + '-01-01');
                setEnd((new Date().getFullYear()).toString() + '-12-31');
                setChange(!change);
              }}>올해만</button>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        <div className="m-container">
          <div className="content-title-wrap">
            <p>총 <b>{totalElements}명</b>이 시청함</p>
            <button className="btn-masking" onClick={() => setMasking(!masking)}>{masking ? '마스킹(*) 해제 조회' : '마스킹(*) 조회'}</button>
          </div>
        </div>
        <table className="status-list">
          <colgroup>
            <col style={{width: "90px"}}/>
            <col style={{width: "63px"}}/>
            <col style={{width: "113px"}}/>
            <col style={{width: "88px"}}/>
          </colgroup>
          <thead>
          <tr>
            <th onClick={() => {
              setSortType('studentNumber');
              setSortNumberAsc(!sortNumberAsc);
              setSort(`${sortType},${sortNumberAsc ? 'asc' : 'desc'}`);
            }}>학번/학년</th>
            <th onClick={() => {
              setSortType('name');
              setSortNameAsc(!sortNameAsc);
              setSort(`${sortType},${sortNameAsc ? 'asc' : 'desc'}`);
            }}>이름</th>
            <th>이메일</th>
            {/* <th>수강<br/> 시작 일자</th> */}
            <th onClick={() => {
              setSortType('modifiedAt');
              setSortDateAsc(!sortDateAsc);
              setSort(`${sortType},${sortDateAsc ? 'asc' : 'desc'}`);
            }}>마지막<br/> 시청 일자</th>
            {/* <th>시청 시간</th> */}
          </tr>
          </thead>
          <tbody>
          {lectureStatusList && lectureStatusList.map(x => (
            <tr key={x.id}>
              <td>{studentYearChange(x.studentYear)}<br /> {x.studentNumber}</td>
              <td>{x.name}</td>
              <td>{x.email?.split('@')[0]}<br />@{x.email?.split('@')[1]}</td>
              <td>
                {x.createdAt[0].toString()}.{x.createdAt[1].toString().padStart(2,'0')}.{x.createdAt[2].toString().padStart(2,'0')}<br />
                {x.createdAt[3].toString().padStart(2,'0')}:{x.createdAt[4].toString().padStart(2,'0')}:{x.createdAt[5].toString().padStart(2,'0')}
              </td>
              {/* <td>
                {x.modifiedAt[0].toString()}.{x.modifiedAt[1].toString().padStart(2,'0')}.{x.modifiedAt[2].toString().padStart(2,'0')}<br />
                {x.modifiedAt[3].toString().padStart(2,'0')}.{x.modifiedAt[4].toString().padStart(2,'0')}.{x.modifiedAt[5].toString().padStart(2,'0')}
              </td>
              <td>
                {Math.floor(x.progressSecond/3600) + '시' + Math.floor((x.progressSecond%3600)/60) + '분' + x.progressSecond%60 + '초'}
              </td> */}
            </tr>
          ))}
          </tbody>
        </table>
        {totalPage >= 1 &&
          <ReactPaginate breakLabel="..."
                         containerClassName="user-paginate"
                         nextLabel="다음"
                         onPageChange={getList}
                         pageCount={totalPage}
                         pageClassName="user-paginate-item"
                         previousLabel="이전"/>
        }
      </div>
    </div>
  )
}

export default MLectureStatusPage;
