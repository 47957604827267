import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {noticeList, noticeIsPublic} from 'api/admin/notice';
import {
  CButton,
  CCard,
  CCardBody, CCardFooter,
  CCardHeader,
  CCol, CFormInput, CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import ReactPaginate from "react-paginate";

type Notice = {
  id: number,
  title: string,
  public: boolean,
  createdAt: Array<number>,
}

const AdminNoticeListPage = () => {
  const [lists, setList] = useState<Array<Notice>>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>("");
  const [detectChange, setDetectChange] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getNoticeList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 10,
    }
    noticeList(params).then((res: any) => {
      let newItems = res.data.content;
      setList(newItems);
      setTotalPage(res.data.totalPage);
      setCurrentPage(page.selected);
    })
  }

  useEffect(() => {
    getNoticeList({selected: 0});
  }, [detectChange]);

  const patchPublic = (id:number, isPublic:boolean) => {
    noticeIsPublic(id).then((res: any) => {
      if(isPublic){
        alert('게시물이 비공개 되었습니다.');
      } else {
        alert('게시물이 공개 되었습니다.');
      }
      getNoticeList({selected: currentPage});
    })
  }

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="notice_list">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">공지사항 관리</h1>

            <Link to="/admin/notice/create"
                  className="btn btn-primary text-white">작성</Link>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-end">
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="제목 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '45%'}}
                                    scope="col">제목</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">날짜</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '30%'}}
                                    scope="col">관리</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{item.title}</CTableDataCell>
                    <CTableDataCell>{item.createdAt[0]}.{item.createdAt[1]}.{item.createdAt[2]}</CTableDataCell>
                    <CTableDataCell>
                      <CButton color={item.public ? "success" : "warning"}
                               className="text-white me-3" onClick={() => patchPublic(item.id, item.public)}>{item.public ? "공개" : "비공개"}</CButton>
                      <Link to={`/admin/notice/${item.id}`}
                            className="btn btn-info text-white">수정</Link>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={4}>등록된 공지사항 목록이
                      없습니다.</CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
          </CCardBody>
          {totalPage >= 1 &&
            <ReactPaginate breakLabel="..."
                           containerClassName="user-paginate mb-3"
                           nextLabel="다음"
                           onPageChange={getNoticeList}
                           pageCount={totalPage}
                           pageClassName="user-paginate-item"
                           previousLabel="이전"/>
          }
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminNoticeListPage;
