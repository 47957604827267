import 'views/mobile/main/style/m-main-wish-lecture.sass'
import {getWishList} from 'api/main/lecture';
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import { delWish} from "api/lecture/lecture";

type wishListType = {
  id: number,
  major: string,
  name: string,
  title: string
}

const MMainLikeLecture = () => {
  const [wishList, setWishList] = useState<Array<wishListType>>([])

  const getList = () => {
    getWishList().then((res: any) => {
      setWishList(res.data);
    })
  }

  useEffect(() => {
    getList();
  }, [])

  // 좋아요
  const DelWish = (e:any,courseId: number) => {
    e.preventDefault();
    delWish(courseId).then((res: any) => {
      getList();
    });
  }

  return (
    <article className="main-wish-lecture">
      <div className="m-container">
        <div className="title-wrap">
          <h2>내가 찜한 강의</h2>
        </div>
        <div className="content-wrap">
          {wishList.length > 0 &&
            <ul>
              {wishList.map(list => (
                <li key={list.id}>
                  <button type="button" className="btn-wish" onClick={(e) => DelWish(e,list.id)}></button>
                  <Link to={`/lecture/watch/${list.id}`}>
                    <p>
                      <span className="lecture-category">{list.major}</span> &nbsp;/&nbsp;
                      <b className="lecture-title">{list.title}</b> &nbsp;/&nbsp;
                      <span className="lecture-writer">{list.name}</span>
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          }
          {wishList.length === 0 && <p className="none-list">아직 찜한 강의가 없습니다.</p>}
        </div>
      </div>
    </article>
  )
}

export default MMainLikeLecture;
