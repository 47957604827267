import { combineReducers } from 'redux';
import authentication from 'module/auth'
import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import lectureAddPopup from 'module/lecture-add-popup';
import lectureAddDetailPopup from 'module/lecture-add-detail-popup';
import registerShowPopup from 'module/register-popup';
import classCategory from 'module/class-category';
import getLectureList from 'module/lecturelist';
import setCategory from 'module/search-category';
import playlistMode from 'module/playlist-mode';
import myPlaylist from 'module/my-playlist';
import titleModule from 'module/mobile/title';
import asideModule from 'module/mobile/aside';
import subPlaylist from 'module/sub-playlist';
import publicPlaylist from 'module/public-playlist';
import lectureWatch from 'module/lecture-watch';
import lectureManagement from 'module/lecture-management';
import watchMode from 'module/watch-mode';

const initialState = {};

const rootReducer = combineReducers({
  authentication,
  lectureAddPopup,
  lectureAddDetailPopup,
  registerShowPopup,
  classCategory,
  titleModule,
  getLectureList,
  setCategory,
  asideModule,
  playlistMode,
  myPlaylist,
  subPlaylist,
  publicPlaylist,
  lectureWatch,
  lectureManagement,
  watchMode
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState: initialState,
  enhancers: (defaultEnhancers) => [...defaultEnhancers]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
