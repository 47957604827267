import {Link} from 'react-router-dom';
import {
  CNavGroup,
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav
} from '@coreui/react';


const AdminNavigation = (props: any) => {
  return (
    <CSidebar position="fixed" className={`${!props.show ? 'hide' : ''}`}>
      <CSidebarBrand>
        <Link className="nav-link" to="/">
          연세대학교 의과대학
        </Link>
      </CSidebarBrand>
      <CSidebarNav>
        <CNavItem>
          <Link className="nav-link" to="/admin/dashboard">대시보드</Link>
        </CNavItem>
        <CNavGroup toggler="회원관리">
          <CNavItem >
            <Link className="nav-link" to="/admin/user/new">
              신규가입 관리
            </Link>
          </CNavItem >
            <CNavItem >
              <Link className="nav-link" to="/admin/user/professor/list">
                교수 관리
              </Link>
            </CNavItem >
            <CNavItem >
              <Link className="nav-link" to="/admin/user/student/list">
                학생 관리
              </Link>
            </CNavItem >
          <CNavItem >
            <Link className="nav-link" to="/admin/user/insider/list">
              내부인 관리
            </Link>
          </CNavItem >
          <CNavItem >
            <Link className="nav-link" to="/admin/user/outsider/list">
              외부인 관리
            </Link>
          </CNavItem >
          <CNavItem >
            <Link className="nav-link" to="/admin/user/dispatcher/list">
              파견외부인 관리
            </Link>
          </CNavItem >
        </CNavGroup>
        <CNavGroup toggler="강의 관리">
        <CNavItem >
          <Link className="nav-link" to="/admin/lecture/list">강의 관리</Link>
          <Link className="nav-link" to="/admin/lecture/essential">필독 강의 관리</Link>
        </CNavItem >
        </CNavGroup>
        <CNavGroup toggler="플레이 리스트 관리">
            <CNavItem>
              <Link className="nav-link" to="/admin/playlist/all">
                전체 플레이리스트 관리
              </Link>
            </CNavItem>
            <CNavItem>
              <Link className="nav-link" to="/admin/playlist/public">
                공유 플레이리스트 관리
              </Link>
            </CNavItem>
        </CNavGroup>
        <CNavItem>
          <Link className="nav-link" to="/admin/notice/list">공지사항 관리</Link>
        </CNavItem>
        <CNavItem>
          <Link className="nav-link" to="/admin/inquiry/list">문의사항 관리</Link>
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  )
}

export default AdminNavigation;
