import {Link} from 'react-router-dom';
import 'views/desktop/main/style/main-taglist.sass'
import CreatableSelect from 'react-select/creatable';
import {selectStyles} from 'utils/select-option';
import {useEffect, useState} from 'react';
import {getAllTag, getTag, putTag} from 'api/main/tag';

type TagOption = {
  value: string,
  label: string,
}

type Course = {
  id: number,
  title: string,
  username: string,
  createdAt: Array<number>
}

type Tag = {
  tagName: string,
  courses: Array<Course>,
  seq: number
}

const MainTagList = () => {
  const [tagOptions, setTagOptions] = useState<Array<TagOption>>([]);
  const [tagList, setTagList] = useState<Array<Tag>>([]);
  const [newTag, setNewTag] = useState<string | undefined>("");
  const [detectChange, setDetectChange] = useState<boolean>(false);
  const [mode, setMode] = useState<{ id: number, mode: string }>({
    id: 0,
    mode: 'default'
  });

  const getList = () => {
    getTag().then((res: any) => {
    let tmpArray:Array<Tag> = [
      {tagName: '', courses: [], seq: 1},
      {tagName: '', courses: [], seq: 2},
      {tagName: '', courses: [], seq: 3},
      {tagName: '', courses: [], seq: 4},
      {tagName: '', courses: [], seq: 5}
    ]
      res.data.forEach((x: any) => {
        tmpArray.splice(x.seq-1, 1,x)
      })
    setTagList(tmpArray);
    }).catch(() => {
      let tmpArray:Array<Tag> = [
        {tagName: '', courses: [], seq: 1},
        {tagName: '', courses: [], seq: 2},
        {tagName: '', courses: [], seq: 3},
        {tagName: '', courses: [], seq: 4},
        {tagName: '', courses: [], seq: 5}
      ]
      setTagList(tmpArray);
    })
  }

  const getAllTagOptions = () => {
    getAllTag().then((res: any) => {
      let tOb: Array<TagOption> = [];

      res.data.forEach((x: any) => {
        tOb.push({
          value: x.name,
          label: x.name,
        })
      });
      setTagOptions(tOb);
    })
  }

  useEffect(() => {
    getList()
    getAllTagOptions();
  }, [detectChange])

  const updateTag = (seq:number) => {
    let data = {
      name: newTag,
      seq: seq
    }
    putTag(data).then((res: any) => {
      alert('적용되었습니다.');
      setMode({id: 0, mode: 'default'});
      getList();
    })
  }

  return (
    <article className="tag-list">
      <div className="title-wrap">
        <h2>관심 #태그 리스트</h2>
      </div>
      <div className="content-wrap">
        <ul className="tag-card-list">
          {tagList.length && tagList.map((tag, index) =>
            tag.tagName === '' ?
              <li className="tag-card none" key={index}>
                <div className="card-title-wrap">
                  <div className="input-wrap">
                    <span>#</span>
                    <CreatableSelect styles={selectStyles}
                                     options={tagOptions}
                                     isClearable={true}
                                     formatCreateLabel={(inputValue: string) => inputValue}
                                     onChange={(choice) => setNewTag(choice?.value)}
                                     className="input-tag-select"
                                     classNamePrefix="input-select"
                                     placeholder="관심 있는 태그를 입력해 주세요."/>
                    <button type="button" className="btn-tag-apply"
                            onClick={() => updateTag(tag.seq)}>적용
                    </button>
                  </div>
                </div>
                <div className="card-content-wrap lecture-none">
                  입력된 태그에 연관 된 강의가 제공됩니다.
                </div>
              </li> :
            <li className="tag-card on" key={tag.tagName + index}>
              <div className="card-title-wrap">
                <div className="input-wrap">
                  <span>#</span>
                  {(mode.id === index && mode.mode === 'edit')?
                    <CreatableSelect styles={selectStyles}
                                     options={tagOptions}
                                     isClearable={true}
                                     formatCreateLabel={(inputValue: string) => inputValue}
                                     onChange={(choice) => setNewTag(choice?.value)}
                                     className="input-tag-select"
                                     classNamePrefix="input-select"
                                     placeholder="관심 있는 태그를 입력해 주세요."/> :
                    <span>{tag.tagName}</span>
                  }
                  {(mode.id === index && mode.mode === 'edit') ?
                    <div className="btn-wrap">
                      <button type="button" className="btn-tag-apply" onClick={() => updateTag(tag.seq)}>적용</button>
                      <button type="button" className="btn-cancel" onClick={() => setMode({id: 0, mode: 'default'})}>취소</button>
                    </div>:
                    <button type="button"
                            className="btn-tag-edit"
                            onClick={() => setMode({
                              id: index,
                              mode: 'edit'
                            })}>수정
                    </button>
                  }
                </div>
                {tag.courses?.length >= 5 &&
                  <Link to={`/lecture/list?&keyword=${tag.tagName}&tagSearch=${true}`} className="btn-more">더보기 <b>+</b></Link>
                }
              </div>
              <div className="card-content-wrap">
                <ul className="lecture-list">
                  {tag.courses?.length > 0 && tag.courses.map((course, index) => (
                    <li key={tag.tagName + index}>
                      <Link to={`/lecture/watch/${course.id}`}>{index + 1}. {course.title} / {course.username}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )}
        </ul>
      </div>
    </article>
  );
};

export default MainTagList;
