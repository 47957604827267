import {showPopupF} from 'module/lecture-add-popup';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {useEffect, useState} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form';
import 'views/desktop/common/popup/playlist-popup.scoped.sass'
import { addPlayList, getMyPlayListData, newPlayList} from 'api/playlist/playlist'
import { ChangePlaylist, getMyPlaylist, resetDelList } from 'module/my-playlist';
import { ChangeLecture } from 'module/lecturelist';
import { ChangeWatch } from 'module/lecture-watch';
import {DetailPlayList, NewPlayList, PlayList} from 'utils/type';

const PlayListPopup = ({type}:any) => {
  const dispatch = useAppDispatch();
  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);
  const lectureChange:boolean = useAppSelector((state: RootState) => state.getLectureList.lectureChange);
  const lectureWatchChange: boolean = useAppSelector((state: RootState) => state.lectureWatch.change);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<NewPlayList>();

  // 플레이 리스트
  const playlist:Array<PlayList> = useAppSelector((state: RootState) => state.myPlaylist.myPlaylist);

  useEffect(() => {
    if(type==='add'){
      setShowPlaylist('add')
    } else {
      setShowPlaylist('list')
    }
    let params = null
    getMyPlayListData(params).then((res: any) => {
      dispatch(getMyPlaylist(res.data));
    })
  }, [myChange]);

  const courseId:number = useAppSelector((state: RootState) => state.lectureAddPopup.courseId);

  // 플레이리스트에 추가
  const addPlaylist = (ListId: number) => {
    addPlayList(ListId, courseId).then((res: any) => {
      alert('플레이리스트에 추가되었습니다.');
      dispatch(ChangePlaylist(!myChange));
      dispatch(ChangeLecture(!lectureChange));
      dispatch(ChangeWatch(!lectureWatchChange));
    }).catch((err: any) => {
      alert('선택한 강의는 이미 추가한 강의입니다.')
    });
    dispatch(showPopupF(false));
  }

  // 새로운 플레이리스트 post
  const postPlaylist: SubmitHandler<NewPlayList> = () => {
    let data = {
      title: watch().title,
      description: watch().description
    }

    newPlayList(data).then((res: any) => {
      alert('플레이리스트가 생성되었습니다.');
      if(type === 'add'){
        dispatch(showPopupF(false));
      } else {
        setShowPlaylist('list');
      }
      dispatch(ChangePlaylist(!myChange));
      reset();
    });
  }

  const [showPlaylist, setShowPlaylist] = useState<string>('');

  const showType = () => {
    switch(showPlaylist) {
      case 'list': return defaultPlaylist();
      case 'add' : return NewPlaylist();
    }
  }

  const checkLeture = (courses:DetailPlayList[]) => {
    for(let i=0; i<courses.length; i++){
      if(courses[i].id === courseId) {
        return false
      }
    }
    return true
  }

  const NewPlaylist = () => {
    return(
      <div className="popup">
        <div className="title-wrap">
          <h2>내 플레이리스트</h2>
          <button className="close" onClick={() =>  dispatch(showPopupF(false))}></button>
        </div>
        <form className="content" id="new_playlist_form" onSubmit={handleSubmit(postPlaylist)}>
          <div className="new-playlist-wrap">
            <div className="new-playlist title">
              <div className="title-wrap">
                <label htmlFor="title">제목</label>
              </div>
              <input type="text" id="title" {...register("title", {required: "제목을 입력해주세요.", maxLength: {value: 20, message: "20자 이하 입력해주세요."}})} placeholder='제목입력(20자 이내)'/>
              {Object.keys(errors).length > 0 &&
                <div className="error-wrap">
                  {errors.title && <p className="error-message" role="alert">{errors.title.message}</p>}
                </div>
              }
            </div>
            <div className="new-playlist description">
              <div className="title-wrap">
                <label htmlFor='description'>내용</label>
              </div>
              <textarea id="description" {...register("description", {required: "내용을 입력해주세요.", maxLength: {value: 40, message: "40자 이하 입력해주세요."}})} placeholder='간단한 소개 내용을 입력해주세요. (40자 이내)'/>
              {Object.keys(errors).length > 0 &&
                <div className="error-wrap">
                  {errors.description && <p className="error-message" role="alert">{errors.description.message}</p>}
                </div>
              }
            </div>
          </div>
          <div className="btn-box">
            {
              type === 'add' ?
              (<button type="reset" className='cancle' onClick={() => dispatch(showPopupF(false))}>취소</button>)
              : (<button type="reset" className='cancle' onClick={() => setShowPlaylist('list')}>취소</button>)
            }
            <button type="submit" className='add'>등록</button>
          </div>
        </form>
      </div>
    )
  }

  const defaultPlaylist = () => {
    return (
      <div className="popup">
      <div className="title-wrap">
        <h2>내 플레이리스트</h2>
        <button className="close" onClick={() => dispatch(showPopupF(false))}></button>
      </div>
      <div className="content">
        {playlist.length ? (
          <div className='playlist-container'>
            <ul className="playlist-wrap">
              {playlist.map(element =>(
                <li key={element.id}>
                  <div className="main">
                    <span className='playlist-title'>{element.title}</span>
                    <span className='playlist-sub'>{element.courses.length}개 강의</span>
                  </div>
                  { checkLeture(element.courses) ? (
                      <button className="btn-add" onClick={() => addPlaylist(element.id)}>추가</button>
                    ) : (
                      <button className="btn-add" disabled>추가</button>
                    )
                  }
                </li>))}
            </ul>
            <div className="btn-box">
              <button className="add" onClick={() => setShowPlaylist('add')}>플레이리스트 만들기</button>
            </div>
          </div>
        ) : (
          <div className="text">플레이리스트가 없습니다.
            <div className="btn-box">
              <button className="add" onClick={() => setShowPlaylist('add')}>플레이리스트 만들기</button>
            </div>
          </div>)}
      </div>
    </div>
    )
  }

  return (
    <div className="playlist-popup popup-container">
      {showType()}
    </div>
  )
}

export default PlayListPopup
