import { Link } from 'react-router-dom';
import {useEffect, useState} from 'react';
import 'views/desktop/main/style/main-essential-lecture.scoped.sass'
import {getMainEssentialLecture} from 'api/main/lecture';
import {Thumbnail} from "utils/type";
import {useAppDispatch} from 'module/Module';

type MainEssentialLectureType = {
  id: number,
  major: string,
  category: string,
  title: string,
  user: string,
  courseThumbnail: Thumbnail,
  thumbnail: Thumbnail,
  videoType: string;
  videoKey: string;
  createdAt: Array<number>,
}

const MainEssentialLecture = () => {
  const [essentialList, setEssentialList] = useState<MainEssentialLectureType>();
  const [essentialList2, setEssentialList2] = useState<MainEssentialLectureType>();
  const [essentialList3, setEssentialList3] = useState<MainEssentialLectureType>();
  const [essentialList4, setEssentialList4] = useState<MainEssentialLectureType>();
  const [essentialList5, setEssentialList5] = useState<MainEssentialLectureType>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalElements2, setTotalElements2] = useState<number>(0);
  const [totalElements3, setTotalElements3] = useState<number>(0);
  const [totalElements4, setTotalElements4] = useState<number>(0);
  const [totalElements5, setTotalElements5] = useState<number>(0);

  const getList = () => {
      let params = {
        seq: 1,
      }
      getMainEssentialLecture(params).then((res:any) => {
        let random = Math.floor(Math.random() * (res.data.length - 0));
        setEssentialList(res.data[random]);
        setTotalElements(res.data.length);
      })

    let params2 = {
      seq: 2,
    }
    getMainEssentialLecture(params2).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList2(res.data[random]);
      setTotalElements2(res.data.length);
    })

    let params3 = {
      seq: 3,
    }
    getMainEssentialLecture(params3).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList3(res.data[random]);
      setTotalElements3(res.data.length);
    })

    let params4 = {
      seq: 4,
    }
    getMainEssentialLecture(params4).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList4(res.data[random]);
      setTotalElements4(res.data.length);
    })

    let params5 = {
      seq: 5,
    }
    getMainEssentialLecture(params5).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList5(res.data[random]);
      setTotalElements5(totalElements+res.data.length);
    })
  }

  useEffect(() => {
    getList();
  },[])

  return (
    <article className="essential-lecture">
      <div className="title-wrap">
        <h2>필독강의</h2>
        { ((totalElements + totalElements2 + totalElements3 + totalElements4 + totalElements5) >=5 ) && <Link to="/lecture/list?recommend=true" className="btn-more">더보기 <b>+</b></Link>}
      </div>
      <div className="content-wrap">
        <ul className="essential-card-list">
          {(essentialList) &&
            <li key={essentialList.id} className="essential-card-item">
              <Link to={`/lecture/watch/${essentialList?.id}`}>
                {essentialList.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={essentialList.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={essentialList.courseThumbnail?.url ? essentialList.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type"  style={
                    {backgroundColor: essentialList?.category === '기초의학교실' ? '#f8cbad'
                        : essentialList?.category === '임상의학교실' ? '#c6e0b4'
                        : essentialList?.category === '인문사회의학교실' ? '#d8c5ff'
                        : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{essentialList?.major}</span>
                  <div className="lecture-information">
                    <h3 className="lecture-name">{essentialList?.title}</h3>
                    <p className="writer">{essentialList?.user}</p>
                    <p className="date">{essentialList?.createdAt[0]}.{essentialList?.createdAt[1].toString().padStart(2,'0')}.{essentialList?.createdAt[2].toString().padStart(2,'0')}</p>
                  </div>
                </div>
              </Link>
            </li>
            }
          {(essentialList2) &&
            <li key={essentialList2.id} className="essential-card-item">
              <Link to={`/lecture/watch/${essentialList2?.id}`}>
                {essentialList2.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={essentialList2.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={essentialList2.courseThumbnail?.url ? essentialList2.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList2.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type" style={
                    {backgroundColor: essentialList2?.category === '기초의학교실' ? '#f8cbad'
                        : essentialList2?.category === '임상의학교실' ? '#c6e0b4'
                        : essentialList2?.category === '인문사회의학교실' ? '#d8c5ff'
                        : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{essentialList2?.major}</span>
                  <div className="lecture-information">
                    <h3 className="lecture-name">{essentialList2?.title}</h3>
                    <p className="writer">{essentialList2?.user}</p>
                    <p className="date">{essentialList2?.createdAt[0]}.{essentialList2?.createdAt[1].toString().padStart(2,'0')}.{essentialList2?.createdAt[2].toString().padStart(2,'0')}</p>
                  </div>
                </div>
              </Link>
            </li>
          }
          {(essentialList3) &&
            <li key={essentialList3.id} className="essential-card-item">
              <Link to={`/lecture/watch/${essentialList3?.id}`}>
                {essentialList3.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={essentialList3.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={essentialList3.courseThumbnail?.url ? essentialList3.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList3.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type" style={
                    {backgroundColor: essentialList3?.category === '기초의학교실' ? '#f8cbad'
                        : essentialList3?.category === '임상의학교실' ? '#c6e0b4'
                        : essentialList3?.category === '인문사회의학교실' ? '#d8c5ff'
                        : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{essentialList3?.major}</span>
                  <div className="lecture-information">
                    <h3 className="lecture-name">{essentialList3?.title}</h3>
                    <p className="writer">{essentialList3?.user}</p>
                    <p className="date">{essentialList3?.createdAt[0]}.{essentialList3?.createdAt[1].toString().padStart(2,'0')}.{essentialList3?.createdAt[2].toString().padStart(2,'0')}</p>
                  </div>
                </div>
              </Link>
            </li>
          }
          {(essentialList4) &&
            <li key={essentialList4.id} className="essential-card-item">
              <Link to={`/lecture/watch/${essentialList4?.id}`}>
                {essentialList4.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={essentialList4.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={essentialList4.courseThumbnail?.url ? essentialList4.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList4.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type" style={
                    {backgroundColor: essentialList4?.category === '기초의학교실' ? '#f8cbad'
                        : essentialList4?.category === '임상의학교실' ? '#c6e0b4'
                        : essentialList4?.category === '인문사회의학교실' ? '#d8c5ff'
                        : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{essentialList4?.major}</span>
                  <div className="lecture-information">
                    <h3 className="lecture-name">{essentialList4?.title}</h3>
                    <p className="writer">{essentialList4?.user}</p>
                    <p className="date">{essentialList4?.createdAt[0]}.{essentialList4?.createdAt[1].toString().padStart(2,'0')}.{essentialList4?.createdAt[2].toString().padStart(2,'0')}</p>
                  </div>
                </div>
              </Link>
            </li>
          }
          {(essentialList5) &&
            <li key={essentialList5.id} className="essential-card-item">
              <Link to={`/lecture/watch/${essentialList5?.id}`}>
                {essentialList5.videoType === 'VIMEO' ? (
                  <div className="img-wrap">
                    <img src={essentialList5.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  ) : (
                  <div className="img-wrap">
                    <img src={essentialList5.courseThumbnail?.url ? essentialList5.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList5.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                  </div>
                  )
                }
                <div className="text-wrap">
                  <span className="lecture-type" style={
                    {backgroundColor: essentialList5?.category === '기초의학교실' ? '#f8cbad'
                        : essentialList5?.category === '임상의학교실' ? '#c6e0b4'
                        : essentialList5?.category === '인문사회의학교실' ? '#d8c5ff'
                        : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                    }
                  }>{essentialList5?.major}</span>
                  <div className="lecture-information">
                    <h3 className="lecture-name">{essentialList5?.title}</h3>
                    <p className="writer">{essentialList5?.user}</p>
                    <p className="date">{essentialList5?.createdAt[0]}.{essentialList5?.createdAt[1].toString().padStart(2,'0')}.{essentialList5?.createdAt[2].toString().padStart(2,'0')}</p>
                  </div>
                </div>
              </Link>
            </li>
          }
        </ul>
        {(!essentialList && !essentialList2 && !essentialList3 && !essentialList4 && !essentialList5 ) && <p className="none-list">필독 강의가 없습니다.</p>}
      </div>
    </article>
  );
};

export default MainEssentialLecture;
