import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  showPopup: false,
  showType: ''
}

type ShowPopup = {
  showPopup: boolean,
  showType: string
}

const registerShowPopup = createSlice({
  name: 'registerShow',
  initialState,
  reducers: {
    showPopupF(state: ShowPopup, action: PayloadAction<ShowPopup>) {
        state.showPopup = action.payload.showPopup;
        state.showType = action.payload.showType;
    }
  }
});

export const { showPopupF } = registerShowPopup.actions;
export default registerShowPopup.reducer;