import Send from 'utils/send';

export const getLectureList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/courses/search',
    params: params
  })
}

export const getLectureDetail = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/courses/${id}`
  })
}

export const deleteLecture = (id:number) => {
  return Send({
    method: 'delete',
    url: `/api/courses/${id}`
  })
}

export const putLectureTime = (Id: number, data: any) => {
  return Send({
    method: 'put',
    url: `/api/courses/${Id}/progress`,
    data: data,
  })
}

export const addWish = (id: number | undefined) => {
  return Send({
    method: 'post',
    url: `/api/courses/${id}/wish`
  })
}

export const delWish = (id: number) => {
  return Send({
    method: 'delete',
    url: `/api/courses/${id}/wish`
  })
}

export const addLike = (id: number | undefined) => {
  return Send({
    method: 'post',
    url: `/api/courses/${id}/like`
  })
}

export const delLike = (id: number) => {
  return Send({
    method: 'delete',
    url: `/api/courses/${id}/like`
  })
}

export const postComment = (id: number, data: any) => {
  return Send({
    method: 'post',
    url: `/api/courses/${id}/comment`,
    data: data,
  })
}

export const getComment = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/courses/${id}/comment`
  })
}

export const putComment = (courseId: number, commentId: number, data: any) => {
  return Send({
    method: 'put',
    url: `/api/courses/${courseId}/comment/${commentId}`,
    data: data,
  })
}

export const deleteComment = (courseId: number, commentId: number) => {
  return Send({
    method: 'delete',
    url: `/api/courses/${courseId}/comment/${commentId}`,
  })
}

export const postLikeComment = (courseId: number) => {
  return Send({
    method: 'post',
    url: `/api/course-comments/${courseId}/like`
  })
}

export const deleteLikeComment = (courseId: number) => {
  return Send({
    method: 'delete',
    url: `/api/course-comments/${courseId}/like`
  })
}

export const downloadExcel = (courseId: number, params: any) => {
  return Send({
    method: 'get',
    url: `/api/courses/${courseId}/progress/excel`,
    responseType: 'blob',
    params: params
  })
}
