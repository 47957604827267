import React from 'react';
import {Outlet} from 'react-router-dom';
import SimpleHeader from 'views/desktop/common/layout/SimpleHeader';
import Footer from 'views/desktop/common/layout/Footer';

const AppFreeLayout = () => (
  <>
    <SimpleHeader/>
    <main id="app_free_main">
      <Outlet/>
    </main>
    <Footer/>
  </>
)

export default AppFreeLayout
