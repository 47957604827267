import { CommonPlaylistProps } from 'utils/type';
import CommonPlaylistCard from 'views/desktop/common/playlist/CommonPlaylistCard';

type PlaylistArrayProps = {
  playlists: Array<CommonPlaylistProps>
}

const CommonPlaylist = ({playlists} : PlaylistArrayProps) => {
  return (
    <>
    {playlists &&
      <ul className="common-playlist-list">
        {playlists.map((data, i:number) => (
        <li key={i}>
          <CommonPlaylistCard data={data}/>
        </li>
        ))}
      </ul>
    }
    </>
  )
}
export default CommonPlaylist;
