import {setPopupMode, showDetailPopupF} from 'module/lecture-add-detail-popup';
import {setWatchMode} from 'module/watch-mode';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {useEffect, useState} from 'react';
import 'views/desktop/common/popup/playlist-popup.scoped.sass'
import { getDetailData, delCourse, Subscribe, delSubscribe, changeShare, delPlayList, sortCourses } from 'api/playlist/playlist'
import {Courses, PlayList2, EditPlayList} from 'utils/type';
import { ReactSortable } from 'react-sortablejs';
import { ChangePlaylist } from 'module/my-playlist';
import { ChangeSub } from 'module/sub-playlist';
import { SubmitHandler, useForm } from 'react-hook-form';
import {Link} from "react-router-dom";

const PlayListDetailPopup = () => {

  const dispatch = useAppDispatch();
  const mode:string = useAppSelector((state: RootState) => state.lectureAddDetailPopup.mode);
  const playlistId:number | null = useAppSelector((state: RootState) => state.lectureAddDetailPopup.playlistId);

  const [detailData, setDetailData] = useState<PlayList2>();

  const [courses, setCourses] = useState<Array<Courses>>([]);

  const [delList, setDelList] = useState<Array<number>>([]);
  const [delCourses, setDelCourses] = useState<Array<Courses>>([]);

  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<EditPlayList>();

  useEffect(() => {
    getDetailData(playlistId).then((res: any) => {
      setValue('title', res.data.title);
      setValue('description', res.data.description);
      setDetailData(res.data);
      setCourses(res.data.courses);
    })
  },[myChange])

  type SortData = {
    title : string,
    description: string,
    courses: [{
      courseId: number,
      seq: number,
    }]
  };

  const SortPlayList: SubmitHandler<EditPlayList> = () => {
    let newCourses:SortData = {
      title: watch().title,
      description: watch().description,
      courses: [{courseId: 0, seq: 0}],
    };

    courses.forEach((x,index) => {
      if(!delList.includes(x.id)){
        newCourses.courses.push({
          courseId: x.id,
          seq: index,
        })
      }
    });

    delList.forEach(x => {
      delCourse(playlistId, x);
    });

    setDelList([]);

    sortCourses(playlistId, newCourses).then((res: any) => {
      alert('저장 하였습니다.');
      dispatch(ChangePlaylist(!myChange));
      dispatch(setPopupMode(''))
      setDelCourses([]);
    });
  }

  const DelCourses = (courseId:number, data: any) =>{
    setDelList([...delList, courseId]);
    setDelCourses([...delCourses, data]);
    let newArray = [...courses];
    newArray = newArray.filter(x => x.id !== courseId);
    setCourses(newArray);
  }

  const close = () =>{
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
    dispatch(setPopupMode(''))
    setDelList([]);
    setDelCourses([]);
    dispatch(ChangePlaylist(!myChange));
  }

  const DelPlayList = () =>{
    if(window.confirm('플레이리스트를 삭제하시겠습니까?')) {
        delPlayList(playlistId).then((res: any) => {
          dispatch(ChangePlaylist(!myChange));
          dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}));
          dispatch(setPopupMode(''));
        })
    }
  }

  const SubscribeF = (Id:any) =>{
    if(window.confirm('해당 플레이리스트를 구독하시겠습니까?')) {
      Subscribe(Id).then((res: any) => {
        dispatch(ChangeSub(!subChange));
        dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}));
        dispatch(setPopupMode(''));
      }).catch((err:any) =>{

      })
    }
  }

  const delSubscribeF = (Id:any) =>{
    if(window.confirm('해당 플레이리스트를 구독 취소하시겠습니까?')) {
      delSubscribe(Id).then((res: any) => {
        dispatch(ChangeSub(!subChange));
        dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}));
        dispatch(setPopupMode(''));
      })
    }
  }

  const ChangeShare = (Id:any) => {
    changeShare(Id).then((res: any) => {
      if(!(detailData?.shareable)){
        alert('공유 되었습니다.')
      } else {
        alert('공유 해제 되었습니다.')
      };
      dispatch(ChangePlaylist(!myChange));
    })
  }

  return (
    <div className="playlist-detail-popup popup-container">
      <div className="popup">
        {!(mode==='edit') ? (
          <div className='courses-header'>
            <div className="title-wrap">
              <h2>{detailData?.title}</h2>
              <div className="courses-length">총 {detailData?.courses.length}개의 강의</div>
              {(detailData?.mine) ? (
                <div className="btn-box">
                  {!(detailData?.shareable) ? (<button className="share" onClick={() => ChangeShare(detailData?.id)}>공유하기</button>) : (<button className="share-cancel" onClick={() => ChangeShare(detailData?.id)}>공유해제</button>)}
                  <button className="edit" onClick={() => dispatch(setPopupMode('edit'))}>제목과 내용 수정</button>
                  {detailData?.courses[0] &&
                    <Link
                      className='play'
                      to={`/lecture/watch/${detailData?.courses[0].id}`}
                      onClick={() => dispatch(setWatchMode({playlistMode: true, playlistId: detailData?.id}))}>모두 재생
                    </Link>
                  }
                </div>) : (
                <div className="btn-box">
                  {detailData?.meSubscribed ? (
                      <button className="cancel" onClick={()=> delSubscribeF(detailData?.id)}>구독취소</button>
                    ) : (
                      <button className="add" onClick={()=> SubscribeF(detailData?.id)}>구독하기</button>
                    )
                  }
                  {detailData?.courses[0] &&
                    <Link
                      className='play'
                      to={`/lecture/watch/${detailData?.courses[0].id}`}
                      onClick={() => dispatch(setWatchMode({playlistMode: true, playlistId: detailData?.id}))}>모두 재생
                    </Link>
                  }
                </div>
              )}
              <button className="close" onClick={() => close()}></button>
            </div>
            <div className='courses-content'>{detailData?.description}</div>
          </div>
        ):(
          <div className="courses-header edit">
            <form id="courses_edit" onSubmit={handleSubmit(SortPlayList)}>
              <div className="title-wrap">
                <input type="text" className="title" id="title" defaultValue={detailData?.title} {...register("title", {required: "제목을 입력해주세요.", maxLength: {value: 20, message: "20자 이하 입력해주세요."}})} placeholder='제목입력(20자 이내)'/>
                <div className="btn-box">
                  <button type="submit" className="submit">저장</button>
                  <button type="button" className="remove" onClick={() => DelPlayList()}>그룹삭제</button>
                </div>
                <button className="close" onClick={() => {dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null})); dispatch(setPopupMode(''))}}></button>
              </div>
              {Object.keys(errors).length > 0 &&
                <div className="error-wrap">
                  {errors.title && <p className="error-message" role="alert">{errors.title.message}</p>}
                </div>
              }
              <input type="text" className="description" id="description" defaultValue={detailData?.description} {...register("description", {required: "내용을 입력해주세요.", maxLength: {value: 40, message: "40자 이하 입력해주세요."}})} placeholder='간단한 소개 내용을 입력해주세요. (40자 이내)'/>
              {Object.keys(errors).length > 0 &&
                <div className="error-wrap">
                  {errors.description && <p className="error-message" role="alert">{errors.description.message}</p>}
                </div>
              }
            </form>
          </div>
        )}
        <div className="content">
          <div className='playlist-container'>
            <ReactSortable tag="ul"
                          list={courses}
                          setList={setCourses}
                          className="my-playlist-course-list"
                          animation={150}
                          delayOnTouchOnly={true}
                          delay={2}
                          handle=".btn-drag">
              {courses.map((element, index) =>(
                <li key={index}>
                  {mode==='edit' &&
                    <button
                      type="button"
                      className="btn-drag"
                      onMouseDown={() => dispatch(setPopupMode('edit'))}>
                    </button>
                  }
                    <Link to={`/lecture/watch/${element.id}`}>{index+1}. {element.category} I {element.title} I {element.user}</Link>
                    {mode==='edit' && <button className='detail-remove' onClick={()=> DelCourses(element.id, element)}>삭제</button>}
                </li>
              ))}
            </ReactSortable>
            {delCourses &&
              <ul className="delete-list">
                {delCourses.map((element, index) =>(
                  <li key={index} className="del-course">
                    <p>{index+1}.{element.category} I {element.title} I {element.user}</p>
                  </li>
                ))}
              </ul>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayListDetailPopup
