import {RootState, useAppSelector} from 'module/Module';

import 'views/mobile/common/layout/m-simple-header.scoped.sass'
import back from 'assets/images/global/icon_back.svg';
import { useNavigate } from 'react-router';

const MSimpleHeader = () => {
  const title:string = useAppSelector((state: RootState) => state.titleModule.title);
  const navigate = useNavigate();

  return (
    <header id="m_header">
      <div className="m-container">
        <div className="menu-wrap">
          <img src={back} alt="뒤로가기" onClick={()=> navigate(-1)} className="btn-dropdown"/>
        </div>
        <div className="title-wrap">
          <h1>{title}</h1>
        </div>
      </div>
    </header>
  )
}

export default MSimpleHeader;
