import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {useEffect, useState} from 'react';
import {
  deletePlaylist,
  getAllPlaylist,
  isPublicPlaylist
} from 'api/admin/playlist';
import ReactPaginate from 'react-paginate';
import {RootState, useAppDispatch, useAppSelector} from "module/Module";
import PlayListDetailPopup
  from "views/desktop/common/popup/PlayListDetailPopup";
import {setPopupMode, showDetailPopupF} from "module/lecture-add-detail-popup";

type AdminPlaylist = {
  id: number,
  title: string,
  name: string,
  courseCount: number,
  sharedCount: number,
  shareable: boolean,
  createdAt: Array<number>,
}

const AdminPlaylistPage = () => {
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const [lists, setList] = useState<Array<AdminPlaylist>>([]);
  const [keyword, setKeyword] = useState<string>();
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [detectChange, setDetectChange] = useState<boolean>(false);
  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);
  const dispatch = useAppDispatch();

  const getPlaylist = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
    }
    getAllPlaylist(params).then((res: any) => {
      setList(res.data.content);
      setTotalPage(res.data.totalPages);
      setCurrentPage(page.selected);
    })
  }

  useEffect(() => {
    getPlaylist({selected: 0});
  },[detectChange,myChange])

  const changePublic = (id:number, isShareable:boolean) => {
    isPublicPlaylist(id).then((res: any) => {
      if(isShareable){
        alert('플레이리스트가 공유해제 되었습니다.');
      } else {
        alert('플레이리스트가 공유 되었습니다.');
      }
      getPlaylist({selected: currentPage});
    })
  }

  const deleteItem = (id:number) => {
    deletePlaylist(id).then((res: any) => {
      alert('삭제되었습니다.');
      getPlaylist({selected: currentPage});
    })
  }

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <>
    <CRow>
      <CCol lg="12">
        <CCard id="playlist_all">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">전체 플레이 리스트 관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-end">
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="플레이리스트명, 작성자 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '5%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '22%'}}
                                    scope="col">플레이리스트명</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">작성자</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">강의개수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">공유한 수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '18%'}}
                                    scope="col">생성일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">관리</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell style={{ cursor: 'pointer' }} onClick={() => dispatch(showDetailPopupF({showDetailPopup: true, playlistId: item.id}))}>
                      {item.title}
                    </CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.courseCount ? item.courseCount : 0} 개</CTableDataCell>
                    <CTableDataCell>{item.sharedCount ? item.sharedCount : 0} 명</CTableDataCell>
                    <CTableDataCell>{item.createdAt[0]}.{item.createdAt[1]}.{item.createdAt[2]}</CTableDataCell>
                    <CTableDataCell>
                      <CButton  className="btn btn-info text-white me-3"
                                onClick={() => {
                                  dispatch(showDetailPopupF({
                                    showDetailPopup: true,
                                    playlistId: item.id
                                  }));
                                  dispatch(setPopupMode('edit'));
                                }}>수정</CButton>
                      <CButton color={item.shareable ? "warning" : "success"}
                               className="text-white me-3" onClick={() => {changePublic(item.id, item.shareable)}}>{item.shareable ? '공유해제' : '공유'}</CButton>
                      <CButton color="danger" className="text-white" onClick={() => deleteItem(item.id)}>삭제</CButton>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={7}>
                      등록된 플레이리스트 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mt-4  mb-3"
                             nextLabel=">"
                             onPageChange={getPlaylist}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="<"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
      {showDetailPopup && <PlayListDetailPopup/>}
    </CRow>
    </>
  )
}

export default AdminPlaylistPage;
