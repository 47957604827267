import {CommonPlaylistProps} from 'utils/type';
import 'views/mobile/lecture/style/m-public-playlist.sass'
import MCommonPlaylist from 'views/mobile/common/playlist/MCommonPlaylist';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import {getData} from 'api/playlist/public-playlist';
import {getPublicPlaylist} from 'module/public-playlist';
import {useEffect, useState} from 'react';
import MPlayListDetailPopup from 'views/mobile/common/popup/MPlayListDetailPopup';
import ReactPaginate from 'react-paginate';
import {useNavigate} from "react-router-dom";
import { showDetailPopupF } from 'module/lecture-add-detail-popup';


const MLecturePublicPlayListPage = () => {
  const dispatch = useAppDispatch();
  const getParams = new URLSearchParams(window.location.search);
  const [keyword, setKeyword] = useState<string | null>(getParams.get("keyword"));

  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [sort, setSort] = useState<string>('date');

  const [isButtonClick, setIsButtonClick] = useState<boolean>(false);

  const publicPlaylists:Array<CommonPlaylistProps> = useAppSelector((state: RootState) => state.publicPlaylist);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const subChange:boolean = useAppSelector((state: RootState) => state.subPlaylist.subChange);
  const navigate = useNavigate();

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 20,
      sort: sort,
    }
    getData(params).then((res: any) => {
      dispatch(getPublicPlaylist(res.data.content));
      setTotalElements(res.data.totalElements);
      setTotalPage(res.data.totalPages)
    })
  }

  useEffect(() => {
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
    getList({selected: 0});
  },[isButtonClick, subChange, sort])

  return (
    <div className="m-lecture-public-playlist">
      <div className="search-wrap">
        <h2>공유 플레이리스트 검색</h2>
        <div className="input-wrap">
          <input type="text" placeholder="검색어를 입력해 주세요." onChange={(e) => setKeyword(e.target.value)} defaultValue={getParams.get("keyword") ? '' : ''}/>
          <button className="ir_pm" onClick={() => {
              setIsButtonClick(!isButtonClick);
              navigate(`/playlist/public?keyword=${keyword}`);
            }}>검색</button>
        </div>
      </div>
      <div className="m-container">
        <div className="content-wrap">
          <div className="handler-wrap">
            <h2>총 <b>{totalElements}</b>개</h2>
            <ul className="order-list">
              <li className={sort === 'date' ? 'active' : ''}>
                <button onClick={() => setSort('date')}>최신순</button>
              </li>
              <li className={sort === 'share' ? 'active' : ''}>
                <button onClick={() => setSort('share')}>공유순</button>
              </li>
            </ul>
          </div>
          <MCommonPlaylist playlists={publicPlaylists}/>
          {totalPage >= 1 &&
            <ReactPaginate breakLabel="..."
                            containerClassName="m-user-paginate"
                            nextLabel="다음"
                            onPageChange={getList}
                            pageCount={totalPage}
                            pageClassName="user-paginate-item"
                            previousLabel="이전"/>
          }
        </div>
      </div>
      {showDetailPopup && <MPlayListDetailPopup/>}
    </div>
  )
}

export default MLecturePublicPlayListPage;
