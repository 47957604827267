import Send from 'utils/send';

export const noticeList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/notices',
    params: params
  })
}

export const noticeDetail = (id: number) => {
  return Send({
    method: 'get',
    url: `/api/notices/${id}`,
  })
}
