import React from 'react';
import {useDispatch} from 'react-redux';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {RootState, useAppSelector} from 'module/Module';
import { otpStatus } from 'api/admin/otp';
import { setAdminCheck } from 'module/auth';

const RoleRoute = ({
     children,
     roles,
     check,
 } : { children: JSX.Element; roles: Array<string>; check: boolean;}) => {
  const role:string = useAppSelector((state: RootState) => state.authentication.user.role);
  const isAdminCheck:boolean = useAppSelector((state: RootState) => state.authentication.isAdminCheck);
  const loading:boolean = useAppSelector((state: RootState) => state.authentication.loading);
  const adminLoading:boolean = useAppSelector((state: RootState) => state.authentication.adminLoading);
  let location = useLocation();

  const dispatch = useDispatch();

  otpStatus().then((res: any) => {
    if(res.data.success) {
      dispatch(setAdminCheck(true));
    } else {
      dispatch(setAdminCheck(false));
    }
  }).catch(() => {
    dispatch(setAdminCheck(false));
  })

  if (loading) {
    <p>Checking authentication..</p>;
    alert('접근 권한이 없습니다.');
    return <Navigate to="/" />;
  }

  if (adminLoading) {
    return <p>Checking authentication..</p>;
  }

  const userHasRequiredRole = !!(role && roles.includes(role) && isAdminCheck === check);

  if (!userHasRequiredRole) {
    alert('접근 권한이 없습니다.');
    return <Navigate to="/admin/confirm" state={{ from: location }} />;
  }

  return children ? children : <Outlet />;
};

export default RoleRoute;
