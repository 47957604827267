import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CInputGroup,
  CNav,
  CNavItem,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import {useEffect, useState} from 'react';
import {getProfessorCourse} from "api/admin/user";
import {formatBytes} from "utils/helper";
import {deleteLecture, patchLecture} from "api/admin/lecture";
import ReactPaginate from "react-paginate";

type ProfessorPlaylist = {
  id: number,
  title: string,
  createdAt: Array<number>,
  viewCount: number,
  wishCount: number,
  playlistCount: number,
  likeCount: number,
  commentCount: number,
  isPublic: boolean,
}

const AdminProfessorLecturePage = () => {
  const userId = Number(useParams().id);
  const [keyword, setKeyword] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [lengthTotal, setLengthTotal] = useState<number>(0);
  const [sizeTotal, setSizeTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [detectChange, setDetectChange] = useState<boolean>(true);

  const [lists, setLists] = useState<Array<ProfessorPlaylist>>([]);

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 10
    }
    getProfessorCourse(userId, params).then((res: any) => {
      setLists(res.data.courseDtos.content);
      setTotalPage(res.data.courseDtos.totalPages);
      setTotalElements(res.data.courseDtos.totalElements);
      setLengthTotal(res.data.lengthTotal ?? 0);
      setSizeTotal(res.data.sizeTotal ?? 0);
      setCurrentPage(page.selected);
    })
  }

  useEffect(() => {
    getList({selected: 0});
  },[detectChange])

  const updatePublic = (id:number) => {
    patchLecture(id).then((res: any) => {
      alert('수정되었습니다.');
      getList({selected: currentPage});
    })
  }

  const deleteCourse = (id:number) => {
    deleteLecture(id).then((res:any) => {
      alert('삭제되었습니다.');
      getList({selected: currentPage});
    })
  }

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="professor_lecture">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">교수 회원관리</h1>
          </CCardHeader>

          <CCardBody>
            <CNav variant="tabs" className="mb-3">
              <CNavItem active>
                <Link to={`/admin/user/professor/edit/${userId}`} className="nav-link">회원정보</Link>
              </CNavItem>
              <CNavItem>
                <Link to={`/admin/user/professor/lecture/${userId}`} className="nav-link active">강의 정보</Link>
              </CNavItem>
              <CNavItem>
                <Link to={`/admin/user/professor/playlist/${userId}`} className="nav-link">플레이리스트 정보</Link>
              </CNavItem>
            </CNav>

            <div className="card-search mb-3">
                <div className="d-flex justify-content-between align-items-end">
                  <p>
                    <span>등록한 강의 총 개수: <em>{totalElements}</em>개</span>
                    <span className="mx-2">I</span>
                    <span>등록한 강의 총 용량: <em className="text-danger">{formatBytes(lengthTotal)}</em></span>
                    <span className="mx-2">I</span>
                    <span>첨부파일 총 용량: <em className="text-info">{formatBytes(sizeTotal)}</em></span>
                  </p>
                  <CInputGroup className="w-25">
                    <CFormInput type="text" placeholder="강의명 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                    <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                  </CInputGroup>
                </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '8%'}}
                                    scope="col">강의번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '17%'}}
                                    scope="col">강의명</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '13%'}}
                                    scope="col">강의 업로드 일자</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '8%'}}
                                    scope="col">조회수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '8%'}}
                                    scope="col">찜수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">플레이리스트 등록수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '8%'}}
                                    scope="col">좋아요수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '8%'}}
                                    scope="col">댓글 수</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">관리</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {(lists && lists.length) ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{item.title}</CTableDataCell>
                    <CTableDataCell>{item.createdAt[0]}{item.createdAt[1]}{item.createdAt[2]}</CTableDataCell>
                    <CTableDataCell>{item.viewCount}</CTableDataCell>
                    <CTableDataCell>{item.wishCount}</CTableDataCell>
                    <CTableDataCell>{item.playlistCount}</CTableDataCell>
                    <CTableDataCell>{item.likeCount}</CTableDataCell>
                    <CTableDataCell>{item.commentCount}</CTableDataCell>
                    <CTableDataCell>
                      <Link to={`/lecture/management/edit/${item.id}`} className="btn btn-info text-white me-3">수정</Link>
                      <CButton color={item.isPublic ? "success": "warning"}
                               className="text-white me-3" onClick={() => updatePublic(item.id)}>{item.isPublic ? '공개' : '비공개'}</CButton>
                      <CButton color="danger"
                               className="text-white" onClick={() => deleteCourse(item.id)}>삭제</CButton>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={9}>
                      등록된 강의가 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminProfessorLecturePage
