// style
import 'views/desktop/lecture-management/style/lecture-create.sass'

// component
import EditForm from 'views/desktop/lecture-management/components/EditForm';

const LectureCreatePage = () => {
  return (
    <div className="lecture-create">
      <div className="title-wrap">
        <h1>강의 수정하기</h1>
        <p><span className="require">*</span> 필수 입력 사항</p>
      </div>
      <div className="content-wrap">
        <EditForm/>
      </div>
    </div>
  );
};

export default LectureCreatePage;
