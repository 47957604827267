import 'views/mobile/main/style/m-main-essential-lecture.scoped.sass'
import {useEffect, useState} from "react";
import {getMainEssentialLecture, getMainLecture} from "api/main/lecture";
import {Link} from "react-router-dom";
import defaultThumbnails from 'assets/images/global/default_lecture_thumbnail.png';
import {Thumbnail} from "utils/type";

type MainEssentialLectureType = {
  id: number,
  major: string,
  category: string,
  title: string,
  user: string,
  courseThumbnail: Thumbnail,
  thumbnail: Thumbnail,
  videoType: string;
  videoKey: string;
  createdAt: Array<number>,
}


const MMainEssentialLecture = () => {
  const [essentialList, setEssentialList] = useState<MainEssentialLectureType>();
  const [essentialList2, setEssentialList2] = useState<MainEssentialLectureType>();
  const [essentialList3, setEssentialList3] = useState<MainEssentialLectureType>();
  const [essentialList4, setEssentialList4] = useState<MainEssentialLectureType>();
  const [essentialList5, setEssentialList5] = useState<MainEssentialLectureType>();

  const getList = () => {
    let params = {
      seq: 1,
    }
    getMainEssentialLecture(params).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList(res.data[random]);
    })

    let params2 = {
      seq: 2,
    }
    getMainEssentialLecture(params2).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList2(res.data[random]);
    })

    let params3 = {
      seq: 3,
    }
    getMainEssentialLecture(params3).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList3(res.data[random]);
    })

    let params4 = {
      seq: 4,
    }
    getMainEssentialLecture(params4).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList4(res.data[random]);
    })

    let params5 = {
      seq: 5,
    }
    getMainEssentialLecture(params5).then((res:any) => {
      let random = Math.floor(Math.random() * (res.data.length - 0));
      setEssentialList5(res.data[random]);
    })
  }

  useEffect(() => {
    getList();
  },[])

  return (
    <article className="main-essential-lecture">
      <div className="m-container">
        <div className="title-wrap">
          <h2>필독 강의</h2>
          { (essentialList && essentialList2 && essentialList3 && essentialList4 && essentialList5 ) && <Link to="/lecture/list?recommend=true" className="btn-more">더보기 <b>+</b></Link>}
        </div>
        <div className="content-wrap">
            <ul className="lecture-list">
              {(essentialList) &&
                <li key={essentialList.id} className="lecture-card">
                  <Link to={`/lecture/watch/${essentialList?.id}`}>
                    {essentialList.videoType === 'VIMEO' ? (
                      <div className="img-wrap">
                        <img src={essentialList.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      ) : (
                      <div className="img-wrap">
                        <img src={essentialList.courseThumbnail?.url ? essentialList.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      )
                    }
                    <div className="lecture-text-wrap">
                      <span className="lecture-badge" style={
                        {backgroundColor: essentialList?.category === '기초의학교실' ? '#f8cbad'
                            : essentialList?.category === '임상의학교실' ? '#c6e0b4'
                            : essentialList?.category === '인문사회의학교실' ? '#d8c5ff'
                            : essentialList?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                        }
                      }>{essentialList?.major}</span>
                        <h3 className="lecture-title">{essentialList?.title}</h3>
                      <div className="lecture-information-wrap">
                        <p className="lecture-writer">{essentialList?.user}</p>
                        <p className="lecture-date">{essentialList?.createdAt[0]}.{essentialList?.createdAt[1].toString().padStart(2,'0')}.{essentialList?.createdAt[2].toString().padStart(2,'0')}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              }
              {(essentialList2) &&
                <li key={essentialList2.id} className="lecture-card">
                  <Link to={`/lecture/watch/${essentialList2?.id}`}>
                    {essentialList2.videoType === 'VIMEO' ? (
                      <div className="img-wrap">
                        <img src={essentialList2.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      ) : (
                      <div className="img-wrap">
                        <img src={essentialList2.courseThumbnail?.url ? essentialList2.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList2.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      )
                    }
                    <div className="lecture-text-wrap">
                      <span className="lecture-badge" style={
                        {backgroundColor: essentialList2?.category === '기초의학교실' ? '#f8cbad'
                            : essentialList2?.category === '임상의학교실' ? '#c6e0b4'
                            : essentialList2?.category === '인문사회의학교실' ? '#d8c5ff'
                            : essentialList2?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                        }
                      }>{essentialList2?.major}</span>
                      <h3 className="lecture-title">{essentialList2?.title}</h3>
                      <div className="lecture-information-wrap">
                        <p className="lecture-writer">{essentialList2?.user}</p>
                        <p className="lecture-date">{essentialList2?.createdAt[0]}.{essentialList2?.createdAt[1].toString().padStart(2,'0')}.{essentialList2?.createdAt[2].toString().padStart(2,'0')}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              }
              {(essentialList3) &&
                <li key={essentialList3.id} className="lecture-card">
                  <Link to={`/lecture/watch/${essentialList3?.id}`}>
                    {essentialList3.videoType === 'VIMEO' ? (
                      <div className="img-wrap">
                        <img src={essentialList3.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      ) : (
                      <div className="img-wrap">
                        <img src={essentialList3.courseThumbnail?.url ? essentialList3.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList3.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      )
                    }
                    <div className="lecture-text-wrap">
                      <span className="lecture-badge"  style={
                        {backgroundColor: essentialList3?.category === '기초의학교실' ? '#f8cbad'
                            : essentialList3?.category === '임상의학교실' ? '#c6e0b4'
                            : essentialList3?.category === '인문사회의학교실' ? '#d8c5ff'
                            : essentialList3?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                        }
                      }>{essentialList3?.major}</span>
                      <h3 className="lecture-title">{essentialList3?.title}</h3>
                      <div className="lecture-information-wrap">
                        <p className="lecture-writer">{essentialList3?.user}</p>
                        <p className="lecture-date">{essentialList3?.createdAt[0]}.{essentialList3?.createdAt[1].toString().padStart(2,'0')}.{essentialList3?.createdAt[2].toString().padStart(2,'0')}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              }
              {(essentialList4) &&
                <li key={essentialList4.id} className="lecture-card">
                  <Link to={`/lecture/watch/${essentialList4?.id}`}>
                    {essentialList4.videoType === 'VIMEO' ? (
                      <div className="img-wrap">
                        <img src={essentialList4.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      ) : (
                      <div className="img-wrap">
                        <img src={essentialList4.courseThumbnail?.url ? essentialList4.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList4.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      )
                    }
                    <div className="lecture-text-wrap">
                      <span className="lecture-badge"  style={
                        {backgroundColor: essentialList4?.category === '기초의학교실' ? '#f8cbad'
                            : essentialList4?.category === '임상의학교실' ? '#c6e0b4'
                            : essentialList4?.category === '인문사회의학교실' ? '#d8c5ff'
                            : essentialList4?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                        }
                      }>{essentialList4?.major}</span>
                      <h3 className="lecture-title">{essentialList4?.title}</h3>
                      <div className="lecture-information-wrap">
                        <p className="lecture-writer">{essentialList4?.user}</p>
                        <p className="lecture-date">{essentialList4?.createdAt[0]}.{essentialList4?.createdAt[1].toString().padStart(2,'0')}.{essentialList4?.createdAt[2].toString().padStart(2,'0')}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              }
              {(essentialList5) &&
                <li key={essentialList5.id} className="lecture-card">
                  <Link to={`/lecture/watch/${essentialList5?.id}`}>
                    {essentialList5.videoType === 'VIMEO' ? (
                      <div className="img-wrap">
                        <img src={essentialList5.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      ) : (
                      <div className="img-wrap">
                        <img src={essentialList5.courseThumbnail?.url ? essentialList5.courseThumbnail.url : `https://img.youtube.com/vi/${essentialList5.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>
                      </div>
                      )
                    }
                    <div className="lecture-text-wrap">
                      <span className="lecture-badge"  style={
                        {backgroundColor: essentialList5?.category === '기초의학교실' ? '#f8cbad'
                            : essentialList5?.category === '임상의학교실' ? '#c6e0b4'
                            : essentialList5?.category === '인문사회의학교실' ? '#d8c5ff'
                            : essentialList5?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                        }
                      }>{essentialList5?.major}</span>
                      <h3 className="lecture-title">{essentialList5?.title}</h3>
                      <div className="lecture-information-wrap">
                        <p className="lecture-writer">{essentialList5?.user}</p>
                        <p className="lecture-date">{essentialList5?.createdAt[0]}.{essentialList5?.createdAt[1].toString().padStart(2,'0')}.{essentialList5?.createdAt[2].toString().padStart(2,'0')}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              }
            </ul>
          {(!essentialList && !essentialList2 && !essentialList3 && !essentialList4 && !essentialList5 ) && <p className="none-list">필독 강의가 없습니다.</p>}
        </div>
      </div>
    </article>
  )
}

export default MMainEssentialLecture;
