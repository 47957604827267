import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
}

type asideIsOpen = {
  isOpen: boolean
}

const asideModule = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setIsOpen(state: asideIsOpen, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    }
  }
});

export const { setIsOpen } = asideModule.actions;
export default asideModule.reducer;
