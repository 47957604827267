import Send from 'utils/send';

export const getAllPlaylist = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/playlists',
    params: params
  })
}

export const getPublicPlaylist = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/admin/playlists/shares',
    params: params
  })
}

export const isPublicPlaylist = (id: number) => {
  return Send({
    method: 'patch',
    url: `/api/admin/playlists/${id}`,
  })
}

export const deletePlaylist = (id: number) => {
  return Send({
    method: 'delete',
    url: `/api/admin/playlists/${id}`
  })
}
