import {createSlice, PayloadAction} from '@reduxjs/toolkit'

const initialState = {
  title: ''
}

type Title = {
  title: string
}

const titleModule = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setTitle(state: Title, action: PayloadAction<string>) {
      state.title = action.payload;
    }
  }
});

export const { setTitle } = titleModule.actions;
export default titleModule.reducer;
