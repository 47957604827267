import { CommonPlaylistProps } from 'utils/type';
import MCommonPlaylistCard from 'views/mobile/common/playlist/MCommonPlaylistCard';

type PlaylistArrayProps = {
  playlists: Array<CommonPlaylistProps>
}

const MCommonPlaylist = ({playlists} : PlaylistArrayProps) => {
  return (
    <>
    {playlists &&
      <ul className="common-playlist-list">
        {playlists.map((data, i:number) => (
        <li key={i}>
          <MCommonPlaylistCard data={data}/>
        </li>
        ))}
      </ul>
    }
      {
        playlists.length === 0 && <p className="list-none">공유된 플레이 리스트가 없습니다.</p>
      }
    </>
  )
}
export default MCommonPlaylist;
