import 'views/mobile/lecture/style/m-my-playlist.sass'
import MMyPlaylist from 'views/mobile/lecture/components/MMyPlaylist'
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';
import MPlayListPopup from 'views/mobile/common/popup/MPlayListPopup';
import MPlayListDetailPopup from 'views/mobile/common/popup/MPlayListDetailPopup';
import { useEffect, useState } from 'react';
import { ChangePlaylist } from 'module/my-playlist';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { showPopupF } from 'module/lecture-add-popup';

const MLectureMyPlayListPage = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
  },[])

  const [keyword, setKeyword] = useState<string>('');

  const showPopup:boolean = useAppSelector((state: RootState) => state.lectureAddPopup.showPopup);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);
  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);

  return (
    <div className="m-lecture-my-playlist">
      <div className="search-wrap">
        <h2>내 플레이리스트 검색</h2>
        <div className="input-wrap">
          <input type="text" placeholder="검색어를 입력해 주세요." onChange={(e) => setKeyword(e.target.value)}/>
          <button className="ir_pm" onClick={() => {
              dispatch(ChangePlaylist(!myChange));
            }}>검색</button>
        </div>
      </div>
      <div className="m-container">
        <div className="content-wrap">
          <MMyPlaylist word={keyword}/>
        </div>
      </div>
      {showPopup && <MPlayListPopup type={'add'}/>}
      {showDetailPopup && <MPlayListDetailPopup/>}
    </div>
  );
};

export default MLectureMyPlayListPage;
