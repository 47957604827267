import {Link} from 'react-router-dom';
import 'views/desktop/common/layout/simple-header.scoped.sass';
import logo from 'assets/images/global/logo_plus_blue.png'

const SimpleHeader = () => {
  return (
    <header id="header">
      <div className="u-container">
        <Link to="/">
          <img className='logo-univ' src={logo} alt="연세대학교 의과대학"/>
        </Link>
      </div>
    </header>
  )
}

export default SimpleHeader;
