import {Link} from 'react-router-dom';

import 'views/desktop/notice/style/notice.sass'
import {useEffect, useState} from 'react';
import {noticeList} from 'api/notice/notice';

type Notice = {
  id: number,
  title: string,
  createdAt: Array<number>,
}

const Notice = () => {

  const [lists, setList] = useState<Array<Notice>>([]);

  useEffect(() => {
    let params = {
      page: 0,
      size: 10,
    }
    noticeList(params).then((res: any) => {
      let newItems = res.data.content;
      setList(newItems);
    })
  }, [])

  return (
    <div className="notice">
      <div className="u-container">
        <div className="title-wrap">
          <h1>공지사항</h1>
        </div>
        <div className="content-wrap">
          <table>
            <thead>
            <tr>
              <th>제목</th>
              <th>날짜</th>
            </tr>
            </thead>
            <tbody>
            {
              lists && lists.map((item) => (
                <tr key={item.id}>
                  <td><Link to={`/notice/${item.id}`}>{item.title}</Link></td>
                  <td>{item.createdAt[0]}.{item.createdAt[1].toString().padStart(2,'0')}.{item.createdAt[2].toString().padStart(2,'0')}</td>
                </tr>
            ))}
            {
              lists.length === 0 && (
                <tr>
                  <td colSpan={2}>
                    공지사항이 없습니다.
                  </td>
                </tr>
              )
            }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Notice;
