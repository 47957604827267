export const masking = (data:string) => {
  let maskingData = '';
  for(let i = 0 ; i< data.length; i++) {
    if(i === 0 || i ===1) {
      maskingData += "*";
    }else {
      maskingData += data[i];
    }
  }
  return maskingData
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const youtubeDuration = (time: string) => {
  var reptms = /^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/;
  var hours = 0, minutes = 0, seconds = 0, totalseconds;

  var matches = reptms.exec(time);
  if(matches) {
    if (matches[1]) hours = Number(matches[1]);
    if (matches[2]) minutes = Number(matches[2]);
    if (matches[3]) seconds = Number(matches[3]);
  }
  totalseconds = hours * 3600  + minutes * 60 + seconds;

  return totalseconds;
}

export const secondToTime = (sec: number) => {
  let hours   = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - (hours * 3600)) / 60);
  let seconds = sec - (hours * 3600) - (minutes * 60);
  let minutesText;
  let secondsText;

  if (minutes < 10) {minutesText = "0"+String(minutes);}
  else minutesText = minutes;

  if (seconds < 10) {secondsText = "0"+String(seconds);}
  else secondsText = seconds;

  return `${hours}:${minutesText}:${secondsText}`
}
