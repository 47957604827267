import {useEffect, useState} from 'react';
import {setMode} from 'module/playlist-mode';
import { showPopupF } from 'module/lecture-add-popup';
import {ReactSortable} from 'react-sortablejs';
import {PlayList} from 'utils/type';
import PlaylistCard from 'views/desktop/lecture/components/MyPlaylistCard';
import {delPlayList, getMyPlayListData, sortPlayList} from 'api/playlist/playlist';
import {ChangePlaylist, getMyPlaylist, resetDelList} from 'module/my-playlist';
import {RootState, useAppDispatch, useAppSelector} from 'module/Module';

const MyPlaylist = ({word}:any) => {
  const myChange:boolean = useAppSelector((state: RootState) => state.myPlaylist.myChange);

  const delList:Array<number> = useAppSelector((state: RootState) => state.myPlaylist.delList);
  const playlists:Array<PlayList> = useAppSelector((state: RootState) => state.myPlaylist.myPlaylist);

  const mode:string = useAppSelector((state: RootState) => state.playlistMode.mode);

  const keyword = word;

  const delPlaylist:Array<PlayList> = useAppSelector((state: RootState) => state.myPlaylist.delPlaylist);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let params = {
      keyword: keyword,
    }
    getMyPlayListData(params).then((res: any) => {
      dispatch(getMyPlaylist(res.data));
    })
  }, [myChange])

  const SortPlayList = () => {
    let newPlaylists:Array<{playlistId: number, seq: number}> = [];

    delList.forEach(x => {
      delPlayList(x);
    });

    dispatch(resetDelList());

    playlists.forEach((x,index) => {
      if(!delList.includes(x.id)){
        newPlaylists.push({
          playlistId: x.id,
          seq: index
        })
      }
    });

    sortPlayList(newPlaylists).then((res: any) => {
      alert('저장 하였습니다.');
      dispatch(ChangePlaylist(!myChange));
      dispatch(setMode(''))
      dispatch(resetDelList());
    });

  }

  const cancel = () =>{
    dispatch(setMode(''));
    dispatch(resetDelList());
    dispatch(ChangePlaylist(!myChange));
  }

  return (
    <>
      <div className="u-container">
        <div className="handler-wrap">
          <h2>대표 플레이리스트</h2>
          <div className="button-wrap">
            {mode === '' && (
              <>
              <button className="btn-add" onClick={() => dispatch(showPopupF(true))}>추가</button>
              <button className="btn-edit" onClick={() => dispatch(setMode('edit'))}>그룹편집</button>
              </>
            )}
            {mode === 'edit' && (
              <>
                <button className="btn-save" onClick={() => SortPlayList()}>저장</button>
                <button className="btn-cancel" onClick={() => cancel()}>취소</button>
              </>
            )}
          </div>
        </div>
      </div>
      <ReactSortable tag="ul"
                     list={playlists.map(x => ({...x, chosen:true}))}
                     setList={newState => dispatch(getMyPlaylist(newState))}
                     className="my-playlist-list"
                     animation={150}
                     delayOnTouchOnly={true}
                     delay={2}
                     handle=".btn-drag">
        {playlists && playlists.map((data, i: number) =>
          i === 5 ?
            (<li key={data.id}>
              <hr/>
              <div className="u-container">
                <PlaylistCard data={data}/>
              </div>
            </li>) :
            (<li key={data.id}>
              <div className="u-container">
                <PlaylistCard data={data}/>
              </div>
            </li>)
        )}
      </ReactSortable>
      {delPlaylist &&
        <ul className="delete-list">
          { delPlaylist.map(data =>
            <li key={data.id}>
              <div className="u-container">
                <PlaylistCard data={data}/>
              </div>
            </li>
          )}
        </ul>
      }
    </>
  )
}
export default MyPlaylist;
