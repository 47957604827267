import {Swiper, SwiperSlide} from 'swiper/react';
import 'views/mobile/main/style/m-main-newest-lecture.scoped.sass'
import 'swiper/css';
import 'swiper/css/grid';
import {Grid} from 'swiper';
import defaultThumbnails from 'assets/images/global/default_lecture_thumbnail.png';
import {Thumbnail} from "utils/type";
import {useEffect, useState} from "react";
import {getMainLecture} from "api/main/lecture";
import {Link} from "react-router-dom";

type MainNewestLectureType = {
  id: number,
  major: string,
  category: string,
  title: string,
  courseThumbnail: Thumbnail,
  thumbnail: Thumbnail,
  user: string,
  videoType: string;
  videoKey: string;
  createdAt: Array<number>,
}

const MMainNewestLecture = () => {
  const [lists, setLists] = useState<Array<MainNewestLectureType>>([]);

  useEffect(() => {
    let params = {
      recommend: false,
      page: 1,
      size: 8,
    }
    getMainLecture(params).then((res:any) => {
      setLists(res.data)
    })
  },[])

  return (
    <article className="main-newest-lecture">
      <div className="m-container">
        <div className="title-wrap">
          <h2>최신 강의</h2>
          {lists.length >= 8 && <Link to="/lecture/list" className="btn-more">더보기 +</Link>}
        </div>
        <div className="content-wrap">
          {lists.length > 0 &&
          <Swiper
            slidesPerView={2.4285}
            grid={{
              fill: "column",
              rows: 2,
            }}
            spaceBetween={20}
            modules={[Grid]}
          >
            {lists.map( (list) => (
            <SwiperSlide key={list.id}>
              <Link to={`/lecture/watch/${list.id}`}>
              <div className="lecture-card">
                {list.videoType === 'VIMEO' ? (
                    <img src={list.courseThumbnail.url} className="lecture-image" alt="강의 썸네일"/>
                  ) : (
                    <img src={list.courseThumbnail?.url ? list.courseThumbnail.url : `https://img.youtube.com/vi/${list.videoKey}/mqdefault.jpg`} className="lecture-image" alt="강의 썸네일"/>  
                  )
                }
                <span className="lecture-badge"  style={
                  {backgroundColor: list?.category === '기초의학교실' ? '#f8cbad'
                      : list?.category === '임상의학교실' ? '#c6e0b4'
                      : list?.category === '인문사회의학교실' ? '#d8c5ff'
                      : list?.category === '연세동곡의학교육원' ? '#00B0F0' : ''
                      
                  }
                }>{list.major}</span>
                <h3 className="lecture-title">{list.title}</h3>
                <div className="lecture-information">
                  <span className="lecture-writer">{list.user}</span>
                  <span className="lecture-date">{list.createdAt[0]}.{list.createdAt[1].toString().padStart(2,'0')}.{list.createdAt[2].toString().padStart(2,'0')}</span>
                </div>
              </div>
              </Link>
            </SwiperSlide>
            ))}
          </Swiper>}
          { lists.length ===0 && <p className="none-list">최신 강의가 없습니다.</p> }
        </div>
      </div>
    </article>
  )
}

export default MMainNewestLecture;
