import {Link} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {inquiryList} from 'api/admin/inquiry';
import {
  CButton,
  CCard,
  CCardBody, CCardFooter,
  CCardHeader,
  CCol, CFormInput, CInputGroup,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import ReactPaginate from "react-paginate";

type Inquiry = {
  id: number,
  title: string,
  email: string,
  answer: boolean,
  createdAt: Array<number>,
}

const AdminInquiryListPage = () => {
  const [lists, setList] = useState<Array<Inquiry>>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [keyword, setKeyword] = useState<string>("");
  const [detectChange, setDetectChange] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const getInquiryList = (page: any) => {
    let params = {
      keyword: keyword,
      page: page.selected + 1,
      size: 10,
      sort: []
    }
    inquiryList(params).then((res: any) => {
      let newItems = res.data.content;
      setList(newItems);
      setTotalPage(res.data.totalPage);
      setCurrentPage(page.selected);
    })
  }

  useEffect(() => {
    getInquiryList({selected: 0});
  }, [detectChange]);

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="inquiry_list">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">문의하기 관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-end">
                <CInputGroup className="w-25">
                  <CFormInput type="text" placeholder="제목 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                  <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                </CInputGroup>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '40%'}}
                                    scope="col">제목</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">이메일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">날짜</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">답변상태</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell><Link to={`/admin/inquiry/${item.id}`}>{item.title}</Link></CTableDataCell>
                    <CTableDataCell>{item.email}</CTableDataCell>
                    <CTableDataCell>{item.createdAt[0]}.{item.createdAt[1]}.{item.createdAt[2]}</CTableDataCell>
                    <CTableDataCell>{item.answer ? '답변완료' : '답변전'}</CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={4}>등록된 문의하기 목록이
                      없습니다.</CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
          </CCardBody>
          {totalPage >= 1 &&
            <ReactPaginate breakLabel="..."
                           containerClassName="user-paginate mb-3"
                           nextLabel="다음"
                           onPageChange={getInquiryList}
                           pageCount={totalPage}
                           pageClassName="user-paginate-item"
                           previousLabel="이전"/>
          }
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminInquiryListPage;
