import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
import logoDg from 'assets/images/global/logo_dg.png'
import 'views/desktop/common/layout/footer.scoped.sass';

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
  }

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleShowButton = () => {
        if (window.scrollY > 200) {
            setShowButton(true)
        } else {
            setShowButton(false)
        }
    }

    window.addEventListener("scroll", handleShowButton)
    return () => {
        window.removeEventListener("scroll", handleShowButton)
    }
}, [])

  return (
    <footer id="footer">
      {showButton &&
      <div className="top-scroll-wrap">
        <button id="top" onClick={scrollToTop} type="button" >Top</button>
      </div>
      }
      <div className="u-container">
        <div className="footer-wrap">
          <div className="footer-menu-wrap">
            <ul className="footer-menu">
              <li>
                <Link to="/terms/service" target="_blank">이용약관</Link>
              </li>
              <li>
                <Link to="/terms/privacy" target="_blank"><b>개인정보처리방침</b></Link>
              </li>
              <li>
                <Link to="/terms/email" target="_blank">이메일무단수집거부</Link>
              </li>
            </ul>
            <Link to="/terms/inquiry" className="menu-item">문의하기</Link>
          </div>
          <div className="footer-bottom-wrap">
            <div className="fotter-info">
              <p className="footer-address">03722 서울특별시 서대문구 연세로 50-1</p>
              <p className="footer-copyright">
                COPYRIGHT(C) YONSEI UNIVERSITY COLLEGE
                OF MEDICINE. ALL RIGHTS RESERVED.
              </p>
            </div>
            <a className="logo-wrap" href='https://donggok.syn.co.kr/' target='_blank'>
              <img className='logo-dg' src={logoDg} alt="연세동국의학교육원"/>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
