import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// style
import 'views/desktop/user/style/find.sass'

// component
import FindEmailComponent from 'views/desktop/user/components/FindEmail';
import FindPasswordComponent from 'views/desktop/user/components/FindPassword';

const FindPage = () => {
  const params = useParams();
  const showType = params.show;
  const navigate = useNavigate();
  const [show, setShow] = useState <string> (showType!);
  const [active, setActive] = useState<number>(show === 'email' ? 0 : 1 );

  const showFind = () => {
    switch (show) {
      case 'email': return <FindEmailComponent />
      case 'password': return <FindPasswordComponent />
    }
  };

  const clickEmail = () => {
    setShow('email');
    navigate('/find/email', {replace: true});
    setActive(0);
  }

  const clickPassword = () => {
    setShow('password');
    navigate('/find/password', {replace: true});
    setActive(1);
  }

  return (
    <div className="find">
      <section className="title-wrap">
        <h1>아이디 찾기/비밀번호 재설정</h1>
      </section>
      <section className="navigation-wrap">
        <ul className="find-navigation">
          <li className={active === 0 ? 'active' : ''}>
            <button onClick={clickEmail}>아이디 찾기</button>
          </li>
          <li className={active === 1 ? 'active' : ''}>
            <button onClick={clickPassword}>비밀번호 재설정</button>
          </li>
        </ul>
      </section>
      <section className="content-wrap">
        {showFind()}
      </section>
    </div>
  );
};

export default FindPage;
