import { useParams } from 'react-router';
import Send from 'utils/send';

export const getMainLecture = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/courses',
    params: params,
  })
}

export const getMainEssentialLecture = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/courses/recommend',
    params: params,
  })
}

export const getWishList = () => {
  return Send({
    method: 'get',
    url: '/api/courses/wishes',
  })
}

export const getWatchedList = (params: any) => {
  return Send({
    method: 'get',
    url: '/api/courses/recent',
    params: params,
  })
}
