// style
import 'views/desktop/main/style/main.sass';

// component
import MainNewestLecture from 'views/desktop/main/components/MainNewestLecture';
import MainWatchLecture from 'views/desktop/main/components/MainWatchLecture';
import MainLikeLecture from 'views/desktop/main/components/MainLikeLecture';
import MainEssentialLecture from 'views/desktop/main/components/MainEssentialLecture';
import MainTagList from 'views/desktop/main/components/MainTagList';
import MainMyList from 'views/desktop/main/components/MainMyList';
import MainNotice from 'views/desktop/main/components/MainNotice';
import MainPublicPlaylist from 'views/desktop/main/components/MainPublicPlaylist';
import PlayListDetailPopup from 'views/desktop/common/popup/PlayListDetailPopup';
import { RootState, useAppDispatch, useAppSelector } from 'module/Module';
import PlayListPopup from 'views/desktop/common/popup/PlayListPopup';
import { showPopupF } from 'module/lecture-add-popup';
import { showDetailPopupF } from 'module/lecture-add-detail-popup';
import { useEffect } from 'react';

const MainPage = () => {
  const showPopup:boolean = useAppSelector((state: RootState) => state.lectureAddPopup.showPopup);
  const showDetailPopup:boolean = useAppSelector((state: RootState) => state.lectureAddDetailPopup.showDetailPopup);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showPopupF(false));
    dispatch(showDetailPopupF({showDetailPopup:false, playlistId: null}))
  },[])
  
  
  
  return (
    <div className="main">
      <div className="u-container">
        <section className="top-content-wrap">
          <MainEssentialLecture />
          <MainNewestLecture />
        </section>
        <section className="middle-content-wrap">
          <MainMyList />
          <MainTagList />
        </section>
        <section className="bottom-content-wrap">
          <MainLikeLecture />
          <MainWatchLecture />
        </section>
        <section className="sub-content-wrap">
          <MainPublicPlaylist />
        </section>
      </div>
      <article className='simple-notice'>
        <MainNotice />
      </article>
      {showPopup && <PlayListPopup type={'add'}/>}
      {showDetailPopup && <PlayListDetailPopup/>}
    </div>
  );
};

export default MainPage;
