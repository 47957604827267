import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {LectureManagementProps} from 'utils/type';

type lectureManagementType = {
  count: number,
  change: boolean,
  list: Array<LectureManagementProps>
}

const initialState = {
  count: 0,
  change: false,
  list: []
}

const lectureManagement = createSlice({
  name: 'lectureManagement',
  initialState: initialState,
  reducers: {
    setManagementCount(state: lectureManagementType, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    setList(state: lectureManagementType, action: PayloadAction<Array<LectureManagementProps>>){
      state.list = action.payload;
    },
    detectChange(state: lectureManagementType, action: PayloadAction<boolean>) {
      state.change = action.payload;
    }
  }
});

export const { setManagementCount, setList, detectChange } = lectureManagement.actions;
export default lectureManagement.reducer;
