import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput, CFormSelect,
  CInputGroup, CInputGroupText,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {acceptUser, getNewUserData, refuseUser} from 'api/admin/user'
import ReactPaginate from "react-paginate";

type NewRegister = {
  id: number,
  role: string,
  email: string,
  name: string,
  studentYear: string,
  studentNumber: string,
}

const AdminNewRegisterPage = () => {
  const [lists, setLists] = useState<Array<NewRegister>>([]);
  const [isMasking, setIsMasking] = useState<boolean>(true);
  const [keyword, setKeyword] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [detectChange, setDetectChange] = useState<boolean> (false);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const getList = (page: any) => {
    let params= {
      masking: isMasking,
      keyword: keyword,
      role: role,
      page: page.selected + 1,
      size: 20,
    }
    getNewUserData(params).then((res: any) => {
      setLists(res.data.content);
      setTotalElements(res.data.totalElements);
      setTotalPage(res.data.totalPages);
      setCurrentPage(page.selected);
    })
  }

  useEffect(() => {
      getList({selected: 0});
  },[isMasking, role, detectChange])

  const accept = (id: number) => {
    acceptUser(id).then((res: any) => {
      alert("승인 되었습니다.");
      getList({selected: currentPage});
    }).catch(() => {
      alert('오류가 발생하였습니다.');
    })
  }

  const refuse = (id: number) => {
    let data = {
      cause: '관리자에 의한 탈퇴입니다.'
    }
    refuseUser(id,data).then((res: any) => {
      alert("거절 되었습니다.");
      getList({selected: currentPage});
    }).catch(() => {
      alert('오류가 발생하였습니다.');
    })
  }

  const getUserRoleInKorea = (data: string) => {
    switch(data) {
      case 'ROLE_ADMIN' : return '관리자'
      case 'ROLE_PROFESSOR' : return('교수')
      case 'ROLE_STUDENT' : return('학생')
      case 'ROLE_INSIDER' : return('내부인')
      case 'ROLE_DISPATCH_OUTSIDER' : return('파견외부인')
      case 'ROLE_OUTSIDER' : return('외부인')
    }
  }

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="new_register">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">신규 가입 접수</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <CButton type="button" color="dark" variant="outline" onClick={()=> setIsMasking(!isMasking)}>마스킹(*) 해제 조회</CButton>
                  <div className="d-flex justify-content-start align-items-center w-50">
                    <CInputGroup className="w-50 me-3">
                      <CInputGroupText>구분</CInputGroupText>
                      <CFormSelect onChange={(e) => setRole(e.target.value)}>
                        <option value="">전체</option>
                        <option value="PROFESSOR">교수</option>
                        <option value="STUDENT">학생</option>
                        <option value="INSIDER">내부인</option>
                        <option value="OUTSIDER">외부인</option>
                        <option value="DISPATCH">파견내부인</option>
                      </CFormSelect>
                    </CInputGroup>
                  <CInputGroup className="w-100">
                    <CFormInput type="text" placeholder="이름 검색" onChange={(e) => setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                    <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                  </CInputGroup>
                  </div>
                </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">구분</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">이메일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">이름</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">학번</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">학년</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '25%'}}
                                    scope="col">관리</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id} style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{getUserRoleInKorea(item.role)}</CTableDataCell>
                    <CTableDataCell>{item.email }</CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>{item.studentNumber}</CTableDataCell>
                    <CTableDataCell>{item.studentYear}</CTableDataCell>
                    <CTableDataCell>
                      <CButton type="button" color="success" className="text-white me-3" onClick={()=>accept(item.id)}>승인</CButton>
                      <CButton type="button" color="danger" className="text-white me-3" onClick={()=>refuse(item.id)}>거절</CButton>
                      <Link to={`/admin/user/${item.role === 'ROLE_PROFESSOR' ?
                        'professor' : item.role === 'ROLE_STUDENT' ?
                          'student' : item.role === 'ROLE_INSIDER' ?
                            'insider' : item.role === 'ROLE_OUTSIDER' ?
                              'outsider' :  'dispatcher'
                      }/edit/${item.id}`} className="btn btn-primary">보기</Link>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={6}>
                      등록된 신규 회원 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminNewRegisterPage;
