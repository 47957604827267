import {useEffect, useState} from 'react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText, CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react';
import {Link} from 'react-router-dom';
import {masking} from "utils/helper";
import {getUsersList} from "api/admin/user";
import ReactPaginate from "react-paginate";

type Student = {
  id: number,
  studentYear: number,
  email: string,
  name: string,
}

const AdminStudentPage = () => {
  const [lists, setLists] = useState<Array<Student>>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [isMasking, setIsMasking] = useState<boolean>(true);

  const [totalPage, setTotalPage] = useState<number>(1);
  const [detectChange, setDetectChange] = useState<boolean>(true);
  const [studentYear, setStudentYear] = useState<number>(0);

  const getList = (page: any) => {
    let params = {
      keyword: keyword,
      role: 'STUDENT',
      masking: isMasking,
      studentYear: studentYear,
      page:  page.selected + 1,
      size: 10
    }
    getUsersList(params).then((res: any) => {
      setLists(res.data.content);
      setTotalPage(res.data.totalPages);
    })
  }

  useEffect(() => {
    getList({selected: 0});
  },[detectChange, isMasking, studentYear]);

  const handleOnKeyPress = (e:any) => {
    if (e.key === 'Enter') {
      setDetectChange(!detectChange)
    }
  };


  const studentYearChange = (studentYear: number) => {
    switch(studentYear) {
      case 1 : return "예과 1학년";
      case 2 : return "예과 2학년";
      case 3 : return "본과 1학년";
      case 4 : return "본과 2학년";
      case 5 : return "본과 3학년";
      case 6 : return "본과 4학년";
      case 7 : return "휴학";
    }
  }

  return (
    <CRow>
      <CCol lg="12">
        <CCard id="student">
          <CCardHeader className="d-flex justify-content-between">
            <h1 className="mb-0 pt-2 pb-2 fs-5">학생 회원관리</h1>
          </CCardHeader>

          <CCardBody>
            <div className="card-search mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <CButton type="button" color="dark" variant="outline" onClick={()=> setIsMasking(!isMasking)}>마스킹(*) 해제 조회</CButton>
                <div className="d-flex justify-content-start align-items-center w-50">
                  <CInputGroup className="w-50 me-3">
                    <CInputGroupText>구분</CInputGroupText>
                    <CFormSelect onChange={(e) => setStudentYear(Number(e.target.value))}>
                      <option value="">선택</option>
                      <option value={1}>예과 1학년</option>
                      <option value={2}>예과 2학년</option>
                      <option value={3}>본과 1학년</option>
                      <option value={4}>본과 2학년</option>
                      <option value={5}>본과 3학년</option>
                      <option value={6}>본과 4학년</option>
                      <option value={7}>휴학</option>
                    </CFormSelect>
                  </CInputGroup>
                  <CInputGroup className="w-100">
                    <CFormInput type="text" placeholder="이름 검색" onChange={(e)=>setKeyword(e.target.value)} onKeyPress={handleOnKeyPress}/>
                    <CButton type="button" color="primary" onClick={() => setDetectChange(!detectChange)}>검색</CButton>
                  </CInputGroup>
                </div>
              </div>
            </div>

            <CTable className="text-center" responsive="sm">
              <CTableHead color="dark">
                <CTableRow>
                  <CTableHeaderCell style={{width: '10%'}}
                                    scope="col">회원번호</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '15%'}}
                                    scope="col">학년</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '30%'}}
                                    scope="col">이메일</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '25%'}}
                                    scope="col">이름</CTableHeaderCell>
                  <CTableHeaderCell style={{width: '20%'}}
                                    scope="col">정보 수정</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              <CTableBody>
                {lists.length ? lists.map((item) => (
                  <CTableRow key={item.id}
                             style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell>{item.id}</CTableDataCell>
                    <CTableDataCell>{studentYearChange(Number(item.studentYear))}</CTableDataCell>
                    <CTableDataCell>{isMasking ?  masking(item.email):item.email }</CTableDataCell>
                    <CTableDataCell>{item.name}</CTableDataCell>
                    <CTableDataCell>
                      <Link to={`/admin/user/student/edit/${item.id}`} className="btn btn-info text-white">수정</Link>
                    </CTableDataCell>
                  </CTableRow>
                )) : (
                  <CTableRow style={{borderBottom: '1px solid #d8d8d8'}}>
                    <CTableDataCell colSpan={4}>
                      등록된 학생 회원 목록이 없습니다.
                    </CTableDataCell>
                  </CTableRow>
                )}
              </CTableBody>
            </CTable>
            {totalPage >= 1 &&
              <ReactPaginate breakLabel="..."
                             containerClassName="user-paginate mb-3"
                             nextLabel="다음"
                             onPageChange={getList}
                             pageCount={totalPage}
                             pageClassName="user-paginate-item"
                             previousLabel="이전"/>
            }
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  )
}

export default AdminStudentPage
