import {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay} from 'swiper';
import {Link} from 'react-router-dom';
import {noticeList} from 'api/notice/notice';
import 'views/mobile/main/style/m-main-notice.scoped.sass';

type Notice = {
  id: number,
  title: string,
  createdAt: Array<number>,
}

const MMainNotice = () => {
  const [lists, setList] = useState<Array<Notice>>([]);

  useEffect(() => {
    let params = {
      page: 0,
      size: 3,
    }
    noticeList(params).then((res: any) => {
      let newItems = res.data.content;
      setList(newItems);
    })
  }, [])

  return (
    <div className="simple-notice">
      <div className="m-container">
        <div className="notice-wrap">
          <b>공지사항</b>
          <Swiper
            centeredSlides={true}
            modules={[Autoplay]}
            loop={true}
            direction={"vertical"}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}>
            {lists && lists.map(list => <SwiperSlide key={list.id}><Link to={`/notice/${list.id}`}>{list.title}</Link></SwiperSlide>)}
          </Swiper>
        </div>
        {lists && <Link to="/notice" className="btn-more">더보기 <b>+</b></Link>}
      </div>
    </div>
  );
};

export default MMainNotice;
